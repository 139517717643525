import React from 'react';
import moment from 'moment-timezone';
import { FormControl } from 'react-bootstrap';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import config from '../../../../config/config'
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete'
// import Select from './dropDownfield';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown'
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages';
import PresetButtons from '../../../Cruds/CommonModals/PresetButtons';

// config file
export default class RFIView extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.applyCallback = this.applyCallback.bind(this);
    // this.closeMultiSelectDropdown = React.createRef();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    this.state = {
      type: 'RFI View',
      businessName: "",
      selectedAnalyst: "",
      start: start,
      end: end,
      dueStartDate: dueStartDate,
      dueEndDate: dueEndDate,
      selectedDepartment: "",
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterRange: "",
      dateQuery: "",
      filterRangedueDate: "",
      unassignedOnly: false,
      taskRFIReasons: [],
      selectedreason: "",
      selectedDealType: "",
      selectedlastActiondate: "",
      selectedReceiveDate: "",
      columnObj: false
    };
    this.getDepartments()
    this.getRFIReasons()
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getDepartments = () => {
    let loginData = localStorage.getItem("loginCredentials");
    let filter = { criteria: [] }
    if (loginData) {
      loginData = JSON.parse(loginData);
      let result = loginData.roleName.toLowerCase();

      if (result.includes("analyst") && result !== "recovery analyst") {
        filter.criteria = [{ key: "id", value: loginData.departmentId, type: "eq" }]
      }
      filter.criteria.push({ key: 'activeIndicator', value: true, type: 'eq' })
    }
    let apiUrl;
    return fetchMethodRequest('GET', `departments?filter=${JSON.stringify(filter)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,
                departmentId: label._id
              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })
            let user = JSON.parse(localStorage.getItem('loginCredentials'));

            // let DepartmentArray = [], departMentIdArray = []
            let DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []

            // let user = JSON.parse(localStorage.getItem('loginCredentials'));
            if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
              DepartmentArray.push(user.department)
              departMentIdArray.push(user.departmentId)
              await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray })
              await this.datatableref.setDepartement(DepartmentArray, departMentIdArray)
            }
          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  getRFIReasons = () => {
    let apiUrl;
    return fetchMethodRequest('GET', apiCalls.RfiReasons)
      .then(async (response) => {
        if (response && response.rfiReasons) {
          if (response && response.rfiReasons) {
            let labels = response.rfiReasons;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.reason,
                value: label.reason,
              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskRFIReasons: sorted
            })

          }
        } else {
          this.setState({
            taskRFIReasons: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  componentDidMount = () => {
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    let userName = loginCredentials.displayName
    if (userName.includes("FINAC".toLowerCase()) || userName.includes("Integrity".toLowerCase())) {
      this.setState({
        isShowunAssign: true
      })
    }
  }

  /**
 * 
 * @param {Object} e 
 * @param {String} key 
 * @param {String} selectedtype 
 * setting the 
 */
  async ontaskFilterChange(e, key, selectedtype) {
    var val
    var criteriaData
    criteriaData = this.datatableref.getFilterCriteria()
    if (e && e.persist) {
      e.persist();
    }
    if (e && e.target) {
      val = e.target.value
      if (key === "company") {
        await this.setState({
          businessName: val.EstateName ? val.EstateName : val,
          estateID: val.estateID ? val.estateID : ""
        })
      } else if (key === "assignTo") {
        await this.setState({
          selectedAnalyst: val,
        })
      } else if (key === "status") {
        val = e.value
        await this.setState({
          selectedStatus: val,
        })
      }
      let filtercriteria = {
        "key": key,
        "value": key === "status" || key === "department" ? val : encodeURIComponent(val),
        "type": selectedtype,
        "add_Filter": true
      }
      if (criteriaData.criteria &&
        criteriaData.criteria.length > 0) {
        for (let i = 0; i < criteriaData.criteria.length; i++) {
          let crit = criteriaData.criteria
          if ((crit[i].key === filtercriteria.key)) {
            crit.splice(i, 1)
          }
        }
      }
      if (val && val.length >= 1) {
        criteriaData["criteria"].push(filtercriteria)
      }
      await this.setState({
        criteriaData: criteriaData
      })
    }
    if (e.key === "Enter" || key === 'status' || key === 'company' || key === "department") {
      this.datatableref.getDataFromServer(criteriaData);
    }
  }




  resettaskFilters = async () => {

    let tablefieldsToShow = await this.datatableref.tablefieldsforGrid()
    let criteriaData = await this.datatableref.getFilterCriteria()
    await this.setState({
      businessName: "",
      selectedAnalyst: "",
      selectedDepartment: "",
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterRange: "",
      dateQuery: "",
      filterRangedueDate: "",
      selectedlastActiondate: "",
      selectedReceiveDate: "",
      selectedDealType: "", selectedreason: "",
      estateID: ""


    })
    // criteriaData["criteria"] = []
    var removedObj = ["company", "assignTo", "department", "status"]
    for (var item of removedObj) {
      let obj = criteriaData.criteria.find(x => x.key === item && x.add_Filter === true);
      let index = criteriaData.criteria.indexOf(obj);
      criteriaData.criteria.splice(index, 1);
    }
    // await this.datatableref.onChangeDepartment(null, 'selectedDepartment')
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : []
      departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      this.setState({ selectedDepartment: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })
      await this.datatableref.onChangeDepartment(DepartmentArray, 'selectedDepartment', departMentIdArray)
    } else {
      this.setState({ selectedDepartment: DepartmentArray.length > 0 ? DepartmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })
      await this.datatableref.onChangeDepartment(DepartmentArray, 'selectedDepartment', departMentIdArray)

    }
    this.datatableref.getDataFromServer(criteriaData, "resetDateQuery")
  }

  handleunassigned = async (e) => {
    let criteriaData = await this.datatableref.getFilterCriteria()
    await this.setState({
      unassignedOnly: !e
    })
    await this.datatableref.onChangeDepartment(!e, 'unassignedOnly')
    setTimeout(async () =>
      await this.datatableref.getDataFromServer(criteriaData)
    )

  }
  onChangeDepartment = async (e) => {
    let criteriaData = await this.datatableref.getFilterCriteria()
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ departMentIdArray: departmentIdArray, selectedDepartment: e.value, })
    // await this.setState({
    //   selectedDepartment: e.value,
    // })
    await this.datatableref.onChangeDepartment(e.value, 'selectedDepartment', departmentIdArray)
    await this.datatableref.getDataFromServer(criteriaData)

  }
  applyDealCriteriaOrder = (e) => {
    this.datatableref.getDataFromServer(this.state.criteriaData)
  }

  applyCallbackfordueDate = async (startDate, endDate) => {
    var d1 = moment(startDate).format(config.dbOnlyDateFormat);
    var d2 = moment(endDate).format(config.dbDateFormat);
    await this.setState({
      dueStartDate: startDate,
      dueEndDate: endDate,
      filterRangedueDate: this.state.range === "Overdue" ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `
    })
    let criteria = await this.datatableref.getFilterCriteria()

    let filtercriteria = criteria.criteria.filter(function (el) {
      return el.key !== "dueDate"
    });
    criteria.criteria = filtercriteria
    if (this.state.range !== "Overdue") {
      criteria.criteria.push({
        key: "dueDate",
        value: d1,
        type: "gte",
        add_Filter: true
      })
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt",
        add_Filter: true
      })
    } else {
      criteria.criteria.push({
        key: "dueDate",
        value: d2,
        type: "lt",
        add_Filter: true
      })
    }
    this.datatableref.getDataFromServer(criteria);
  }
  selectionDeal = async (e) => {
    if (e.value && e.value.SellerNickName) {
      await this.setState({ businessName: `${e.value.DealType} - ${e.value.SellerNickName} (${e.value.DealYear})` })
    } else {
      await this.setState({ businessName: e.value })
    }
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  gettaskFilters = (handleSubmit) => {
    let now = new Date();
    let month = now.getMonth()
    let year = now.getFullYear()
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    //number of days in last month
    let y = new Date(year, month - 1, 0).getDate();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    let t = now.getDate() - 1 + y + 2;
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
      "This Week(sun-Today)": [moment(start).subtract(now.getDay(), "days"), moment(end)],
      "Last 7 days": [moment(start).subtract(7, "days"), moment(end).subtract(1, "days")],
      "Last Week(Sun-Sat)": [moment(start).subtract(now.getDay() + 7, "days"), moment(start).subtract(now.getDay() + 1, "days")],
      "Last 14 days": [moment(start).subtract(14, "days"), moment(end).subtract(1, "days")],
      "This month": [moment(start).subtract((now.getDate() - 1), "days"), moment(end)],
      "Last 30 days": [moment(start).subtract(30, "days"), moment(end).subtract(1, "days")],
      "Last month": [moment(start).subtract(t, "days"), moment(start).subtract(now.getDate(), "days")],
      "last 3 Days": [moment(start).subtract(3, "days"), moment(end).subtract(1, "days")],
      "30 Days upto today": [moment(start).subtract(29, "days"), moment(end)],
      "All Time": [moment(start), moment(end)]
    }
    var next = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()));
    var getDate = new Date(next.setDate(next.getDate() + 6));

    var firstDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    var lastDay = new Date(now.getFullYear(), now.getMonth() + 2, 0);

    let dueRanges = {
      "Overdue": [moment(dueStartDate), moment(dueEndDate)],
      "Due in the Next Day": [moment(dueStartDate).add(1, "days"), moment(dueEndDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Week": [moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()))), moment(getDate).add(1, "days").subtract(1, "seconds")],
      "Due in the Next Month": [moment(firstDay), moment(lastDay).add(1, "days").subtract(1, "seconds")],
      // "Due in the Next Month": [moment(firstDay), moment()],
    }
    let local = {
      "format": "MM-DD-YYYY HH:mm",
    }
    let maxDate = moment(end);
    return (

      <div className="form kanbanfilterDiv ml-0 mr-0">
        <div style={{ width: "97%" }}>
          <div className="row filterKanbandiv" style={{ width: "100%" }}>
            <div className=" align-items-center pl-0" style={{ display: "inline-flex", width: "100%" }}>
              <div className="kanban_filter_field col-sm-3" >
                <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                  Deal:
                </label>
                <div className="" >
                  <AutoComplete
                    value={this.state.businessName}
                    suggestions={this.state.filteredSuggestions}
                    completeMethod={this.searchsellerItems}
                    // onSelect={this.searchnameItems}
                    minLength={1}
                    id="Deal"
                    style={{ width: "108%", marginBottom: 6, height: 32 }}
                    field={"EstateName"}
                    dropdown={true}
                    itemTemplate={this.editorTemplateForDeal}
                    onSelect={(e) => this.ontaskFilter2Change(e.value.EstateName, 'businessName', 'regexOr', 'company', e.value)}
                    // onChange={(e) => this.setState({ businessName: e.target.value })}
                    onChange={(e) => this.selectionDeal(e)}
                    appendTo={document.body} />

                </div>
              </div>
              <div className="kanban_filter_field col-sm-3" id='department'>
                <label className="mr-1 kanban_filter_label" id='department' style={{ marginTop: 4 }}>
                  Team:
                </label>
                <div className="" style={{ width: "100%" }} >
                  <MultiSelect
                    name="department"
                    id='department'
                    filter={true}
                    style={{ height: 33, width: "100%" }}
                    maxSelectedLabels={1}
                    options={this.state.taskDepartmentItems}
                    value={this.state.selectedDepartment}
                    onChange={(e) => this.onChangeDepartment(e, 'department', 'eq')}
                    size={"20"} />
                </div>
              </div>
              <div className="kanban_filter_field col-sm-3">
                <div className="d-flex">
                  <label className="mr-auto kanban_filter_label" style={{ marginTop: 4 }}>
                    Analyst:
                  </label>
                  <div id='Unassigned Only'
                    style={!this.state.isShowunAssign ? { display: "block" } : { display: "none" }}
                  >
                    <div className="">
                      <Checkbox
                        label='Unassigned Only'
                        type='checkbox'
                        id='Unassigned Only'
                        // component={renderCheckBoxField}
                        name={'unassigned'}
                        className="unassignCheckbox mb-0"
                        checked={this.state.unassignedOnly}
                        onChange={(e) => this.handleunassigned(this.state.unassignedOnly)}
                      />
                      <label htmlFor="cb3" className="p-checkbox-label">Unassigned Only</label>
                    </div>
                  </div>
                </div>
                <div className="" id='assignTo'>
                  <InputText
                    value={this.state.selectedAnalyst}
                    name='assignTo'
                    id='assignTo'
                    style={{ height: 33 }}
                    onKeyPress={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                    onChange={(e) => this.ontaskFilterChange(e, 'assignTo', 'regexOr')}
                    size={"20"} />
                </div>
              </div>

              <div className="kanban_filter_field col-sm-3" id="task_status">
                <label className="mr-1 kanban_filter_label" id='task_status' style={{ marginTop: 1 }}>
                  Task Status:
                </label>
                <div className="form__form-group-input-wrap">
                  <MultiSelect
                    id='task_status'
                    className={"form__form-group-field "}
                    filter={true}
                    style={{ width: "100%", height: 32 }}
                    value={this.state.selectedStatus}
                    options={config.taskFilterItems}
                    onChange={(e) => this.ontaskFilterChange(e, 'status', 'in')}
                    placeholder=''
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row filterKanbandiv" style={{ width: "100%" }}>
            <div className=" align-items-center pl-0" style={{ display: "inline-flex", width: "100%" }}>
              <div className="kanban_filter_field col-sm-3" id="task_status">
                <label className="mr-1 kanban_filter_label" id='task_status' style={{ marginTop: 1 }}>
                  RFI Reason Codes:
                </label>
                <div className="form__form-group-input-wrap">
                  <MultiSelect
                    id='task_status'
                    className={"form__form-group-field "}
                    style={{ width: "100%", height: 32 }}
                    filter={true}
                    value={this.state.selectedreason}
                    options={this.state.taskRFIReasons}
                    onChange={(e) => this.ontaskFilter2Change(e.value, 'selectedreason', 'in', 'reasons')}
                    placeholder=''
                  />
                </div>
              </div>
              <div className="kanban_filter_field col-sm-3" >
                <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                  Deal Type:
                </label>
                <div className="W-100" >
                  <MultiSelect
                    value={this.state.selectedDealType}
                    options={config.DealTypes}
                    name='company'
                    id="bussiness"
                    filter={true}
                    style={{ width: "100%" }}
                    onChange={(e) => this.ontaskFilter2Change(e.value, 'selectedDealType', 'in', 'dealType')}
                  />

                </div>
              </div>
              <div className="kanban_filter_field col-sm-3" id='department'>
                <label className="mr-1 kanban_filter_label" id='department' style={{ marginTop: 4 }}>
                  RFI Last Action Date
                </label>
                <div className="" style={{ width: "100%" }} >
                  <Calendar
                    name="department"
                    id='department'
                    style={{ height: 33, width: "100%" }}
                    value={this.state.selectedlastActiondate}
                    onChange={(e) => this.ontaskFilterdateChange(e.value, 'selectedlastActiondate', 'eq', 'rfiLastActionDate')}
                    size={"20"} />
                </div>
              </div>
              <div className="kanban_filter_field col-sm-3">
                <div className="d-flex">
                  <label className="mr-auto kanban_filter_label" style={{ marginTop: 4 }}>
                    RFI Received Date
                  </label>
                </div>
                <div className="W-100" id='assignTo'>
                  <Calendar
                    value={this.state.selectedReceiveDate}
                    name='assignTo'
                    id='assignTo'
                    style={{ width: "100%" }}
                    onChange={(e) => this.ontaskFilterdateChange(e.value, 'selectedReceiveDate', 'eq', 'rfiReceivedDate')}
                    size={"20"} />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='reset_Filters'>
          <div className="reset_Filters" id="clear">
            <span className=" " id="clear" style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faTimes}
                id="clear"
                className='clear_icon'
                color="red"
                onClick={(e) => this.resettaskFilters()}
              />
            </span>
          </div>
        </div>
      </div >
    )
  }


  getPresetsFunctionality = () => {
    return (
      <div>

        {this.gettaskFilters()}
        <PresetButtons
          activeTab="RFIViewer"
          type="RFIViewer"
          storedKey={"RFI View"}
          tablefieldsToShow={[]}
          getTableFieldsOrder={this.getTableFieldsOrder}
          changeOrder={this.getTableFieldsOrder}
          getisLoading={this.isLoadingscreen}
        />
      </div>
    )
  }

  isLoadingscreen = (type) => {
    if (this.datatableref)
      this.datatableref.getisLoading(type ? "false" : "")
  }

  getTableFieldsOrder = (columnObj) => {
    this.setState({
      columnObj: Object.keys(columnObj).length > 0 ? true : false
    })
    this.datatableref.tablefieldsforGrid(columnObj)
    return this.state.columnObj
  }


  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event) {
      if (event.query) {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event.query)}`
      } else if (typeof event === "string" && event.length > 0) {
        if (criteria && criteria.selectedSeller) {
          apiUrl = `${apiCalls.propertysellers}?EstateID=${encodeURIComponent(event)}`
        } else {
          apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event)}`
        }
      } else {
        apiUrl = `${apiCalls.propertysellers}`
      }
    } else {
      apiUrl = `${apiCalls.Seller} `
    }
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownData = response["sellers"]
          }
          else if (response && response.errorMessage) {
            this.datatableref.getErrorResponseFromServer(response)
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            let dropDopdownValues = dropdownData;
            dropdownData = dropDopdownValues
          }

          await this.setState({
            filteredSuggestions: dropdownData
          });
        }
      }).catch((err) => {
        return err;
      });
  }


  ontaskFilterdateChange = async (value, key, type, dbkey) => {

    this.setState({
      [key]: value
    })
    // value = moment(value).format("MM-DD-YYYY");
    let criteriaData = await this.datatableref.getFilterCriteria()
    let index = criteriaData["criteria"].findIndex(rel => rel.key === dbkey && rel.add_Filter === true)
    if (index !== -1) {
      criteriaData["criteria"].splice(index, 1)
    }
    if (value) {
      criteriaData.criteria.push({ key: dbkey, value: moment(value).format("MM-DD-YYYY"), type: "dateeq", add_Filter: true })
      await this.datatableref.getDataFromServer(criteriaData)

    }
  }

  ontaskFilter2Change = async (value, key, type, dbkey, e) => {
    if (key === "businessName") {
      this.setState({
        [key]: `${e.DealType} - ${e.SellerNickName} (${e && e.DealYear ? e.DealYear : ""})`
      })
    } else {
      this.setState({
        [key]: value
      })
    }
    if (dbkey === "company") {
      value = encodeURIComponent(`${e.DealType} - ${e.SellerNickName} (${e.DealYear})`)
    }
    let criteriaData = await this.datatableref.getFilterCriteria()
    let index = criteriaData["criteria"].findIndex(obj => obj.key === dbkey)
    if (index !== -1) {
      criteriaData["criteria"].splice(index, 1)
    }
    if (value && value.length > 0) {
      criteriaData.criteria.push({ key: dbkey, value: value, type: type, "add_Filter": true })
    }

    await this.datatableref.getDataFromServer(criteriaData)
  }

  /**
   * 
   * @param {Object} startDate 
   * @param {Object} endDate 
   * applying the call back for dates to get data from server
   */

  applyCallback(startDate, endDate) {
    let dateStartRange = startDate.toISOString()
    let dateEndRange = endDate.toISOString()
    var d1 = new Date(dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY'))
    var d2 = new Date(dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY'))
    var startDate1 = moment(d1, "MM-DD-YYYY");
    var endDate2 = moment(d2, "MM-DD-YYYY");
    var result = endDate2.diff(startDate1, 'days');
    let dateQuery = {
      'startDate': dateStartRange, 'endDate': dateEndRange, type: this.state.range
    }
    this.setState({
      start: startDate,
      end: endDate,
      filterRange: result === 0 ? `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')}` : `${dateFormats.formatDate(new Date(startDate), 'MM-DD-YYYY')} to ${dateFormats.formatDate(new Date(endDate), 'MM-DD-YYYY')} `,
      dateQuery: dateQuery,
      first: 0
    }
    )
    // this.state.filterCriteria.page = 1;
    this.datatableref.getDataFromServer(dateQuery, 'dateQuery');
  }

  getTableFields = () => {


    let data =
      [
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "fieldType": "Actions",
          "field": "Actions",
          "header": "Actions",
          "filter": true,
          "sortable": false,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "dealType",
          "mobile": false,
          "header": "Deal Type",
          "filter": true,
          "sortable": true,
          "frozen": false
        }, {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "team",
          "mobile": false,
          "header": "Team",
          "filter": false,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 200,
          "field": "company",
          "mobile": false,
          "header": "Deal Name",
          "filter": true,
          "fieldType": "Badge",
          "options": [{ "label": "New", "value": "New", "color": "danger" }, { "label": "Not Started", "value": "Not Started", "color": "danger" }, { "label": "Paused", "value": "Paused", "color": "danger" }, { "label": "In Progress", "value": "In Progress", "color": "success" }, { "label": "Pending", "value": "Pending", "color": "danger", }],
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 60,
          "field": "states",
          "mobile": false,
          "header": "RFI Source States",
          "filter": true,
          "fieldType": "slicedArray",
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "oppClaimID",
          "mobile": false,
          "header": "OPP Claim Id",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "stateClaimID",
          "mobile": false,
          "header": "State Claim Id",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "relevantSellerName",
          "mobile": false,
          "header": "Relevant Seller Name",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "title",
          "mobile": false,
          "header": "Label",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "priority",
          "mobile": false,
          "header": "Priority",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "researchRequestType",
          "mobile": false,
          "header": "RFI Request Type",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "taskID",
          "mobile": false,
          "header": "RFI Task Number",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "claimID",
          "mobile": false,
          "header": "Claim ID",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 150,
          "field": "purpose",
          "mobile": false,
          "header": "RFI Task Purpose",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "assignTo",
          "mobile": false,
          "header": "RFI Assigned To",
          "filter": true,
          "fieldType": "number",
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "startDate",
          "mobile": false,
          "header": "Start Date(CT)",
          "filterType": "num",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "dueDate",
          "mobile": false,
          "header": "RFI Task Due Date",
          "filterType": "num",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "valueRFI",
          "filterType": "num",
          "mobile": false,
          "header": "RFI Value",
          "filter": true,
          "fieldType": "formatCurrencywithdec",
          "sortable": true,
          "frozen": false
        }, {
          "show": true,
          "textAlign": "left",
          "width": 80,
          "field": "dateOnRFI",
          "filterType": "num",
          "mobile": false,
          "header": "Date on RFI",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "propertyCountRFI",
          "filterType": "num",
          "mobile": false,
          "header": "RFI Prop. Count",
          "filter": true,
          "fieldType": "number",
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "propertyCount",
          "filterType": "num",
          "mobile": false,
          "header": "Prop. Count",
          "filter": true,
          "fieldType": "number",
          "sortable": true,
          "frozen": false
        }, {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiTextFromUpSource",
          "mobile": false,
          "header": "RFI Response Text",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        }, {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "notes",
          "fieldType": "Array",
          "mobile": false,
          "header": "RFI OPP Note",
          "filter": false,
          "displayInSettings": true,
          "sortable": true
        }, {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiLastActionDate",
          "mobile": false,
          "header": "RFI Last Action Date",
          "filterType": "num",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        }, {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiTaskTemplate",
          "mobile": false,
          "header": "RFI Task Template",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "status",
          "mobile": false,
          "header": "RFI Task Status",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiReceivedDate",
          "mobile": false,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "header": "RFI Received Date",
          "filterType": "num",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "created",
          "mobile": false,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "header": "Request Date(CT)",
          "filterType": "num",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "createdByUser",
          "mobile": false,
          "header": "Requestor",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "priorRFIResponseDate",
          "mobile": false,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "header": "Prior RFI Response Date",
          "filterType": "num",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "",
          "mobile": false,
          "header": "RFI Response Text",
          "filterType": "num",
          "filter": true,
          "fieldType": "number",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiRespondedBy",
          "mobile": false,
          "header": "RFI Responded By",
          "filter": true,
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "reasons",
          "mobile": false,
          "header": "RFI Reason Codes",
          "filter": true,
          "fieldType": 'StrArray',
          "sortable": true,
          "frozen": false
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "finalFileName",
          "mobile": false,
          "header": "Final File Name",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "affirmativeApprovalProvidedBySeller",
          "mobile": false,
          "header": "Affirmative Approval Provided by Seller",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "sellerObjections",
          "mobile": false,
          "header": "Seller Objections",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "negativeNoticeExpirationDate",
          "mobile": false,
          "header": "Negative Notice Expiration Date",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "filterType": "num",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiResponseDate",
          "mobile": false,
          "header": "RFI Response Date",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "filterType": "num",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "paymentApproveEmailReceivedDate",
          "mobile": false,
          "header": "Payment Approve Email Received Date",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "filterType": "num",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "sellerNegativeNoticeNotes",
          "mobile": false,
          "header": "Seller Negative Notice Notes",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiResponseDueDate",
          "filterType": "num",
          "mobile": false,
          "header": "RFI Response Due Date",
          "filter": true,
          "fieldType": "Date",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 200,
          "field": "taskTitle",
          "mobile": false,
          "header": "Task Title",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 60,
          "field": "round",
          "mobile": false,
          "header": "Round",
          "filterType": "num",
          "filter": true,
          "fieldType": "number",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 130,
          "field": "propertyCountToFile",
          "mobile": false,
          "header": "No of Properties To File",
          "filter": true,
          "filterType": "num",
          "allowInt": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 130,
          "field": "numberOfPropertiesInClaim",
          "mobile": false,
          "header": "No of Properties To Claim",
          "filter": true,
          "filterType": "num",
          "allowInt": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 130,
          "field": "researchNotes",
          "mobile": false,
          "header": "Research Notes",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "completionDate",
          "mobile": false,
          "header": "Completion Date",
          "filterType": "num",
          "filter": true,
          "dateFormat": "MM/DD/YYYY",
          "fieldType": "Date",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "sellerInputNeeded",
          "mobile": false,
          "header": "Seller Input Needed",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "legalAction",
          "mobile": false,
          "header": "Legal Action",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 160,
          "field": "companyNameToResearch",
          "mobile": false,
          "header": "Company Names to Research",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 140,
          "field": "holdReason",
          "mobile": false,
          "header": "Hold Reason",
          "filter": true,
          "fieldType": "Array",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 180,
          "field": "companyAddressToResearch",
          "mobile": false,
          "header": "Company Addresses to Research",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 200,
          "field": "exhibit",
          "mobile": false,
          "header": "Exhibit (A, B, or Not Exhibited)",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 130,
          "field": "propertyCount",
          "mobile": false,
          "header": "Property Count",
          "filter": true,
          "filterType": "num",
          "allowInt": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 130,
          "field": "unknownPropertyCount",
          "mobile": false,
          "header": "# of unknown properties",
          "filter": true,
          "fieldType": "number",
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "right",
          "width": 100,
          "field": "totalMinPropertyValue",
          "fieldType": "formatCurrencywithdec",
          "mobile": false,
          "filterType": "num",
          "allowInt": true,
          "header": "Total Min Property Value",
          "filter": true,
          "displayInSettings": true,
          "sortable": true
        },
        {
          "show": true,
          "textAlign": "left",
          "width": 110,
          "field": "rfiRequestDescription",
          "mobile": false,
          "header": "Request Description",
          "filter": true,
          "sortable": true,
          "frozen": false
        }
      ]

    return data;
  };

  getFormFields = () => {

    return
  }

  getSelectedStatus = () => {
    return this.state.selectedStatus
  }



  submit = async (item) => {

  }

  render() {
    const { columnObj } = this.state
    return (
      <span>

        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          isSql={"yes"}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          isPresets={true}
          getSelectedStatus={this.getSelectedStatus}
          sample={false}
          gettaskFilters={this.getPresetsFunctionality}
          // globalSearchFieldName='user'
          // globalSearch={'Search'}
          type='RFI View'
          isClearFilters={true}
          taskDepartmentItems={this.state.taskDepartmentItems}
          displayright={true}
          icon='paperclip'
          routeTo='SellerNegativeNoticeViewers'
          displayViewOfForm='modal'
          apiResponseKey='tasks'
          className={true}
          apiUrl={apiCalls.Tasks}
          entityType='employee'
        />

      </span>
    );
  }
}