import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlusSquare, faFilePdf, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import * as documentsAPIs from '../../../../shared/documentsApis';

export const DocTable = (props) => {
    return (<div className='mt-3'>
        {
            props.stateAttributes.length > 0 &&
            <div class="table-responsive doc_upload_table">
                <table class="table table-sm table-bordered">
                    <thead>
                        <th scope="col" style={{ width: '150px' }}>Category</th>
                        <th scope="col" style={{ width: '120px' }}>Relevance</th>
                        <th scope="col" style={{ width: '220px' }}><div>Document</div>Type</th>
                        <th scope="col" style={{ width: '250px' }}><div>Document</div>Description</th>                        
                        <th scope="col" style={{ width: '85px' }}>Document Source Type</th>
                        <th scope="col" style={{ width: '85px' }}>Document Source</th>
                        <th scope="col" style={{ width: '85px' }}>Document Tag</th>
                        <th scope="col">Doc Req</th>
                        {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && <>                       
                            <th scope="col" style={{ width: '45px' }}>Doc Seq</th>
                            <th scope="col" style={{ width: '650px' }}>File</th>
                            <th scope="col" style={{ width: '70px' }}>Action</th>
                        </>
                        }
                        {props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && <>
                            <th scope="col" style={{ width: '650px' }}>File</th>
                            <th scope="col" style={{ width: '70px' }}>Action</th>
                        </>
                        }

                        <th style={{ width: '150px' }}>Notes</th>
                    </thead>
                    <tbody>
                        {
                            props.stateAttributes.map((row, i) => {
                                return <tr className={`${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disabledRow' : ''}`}>
                                    <td style={{ width: '150px' }}>{row.SubCategory}</td>
                                    <td style={{ width: '120px' }}>{row.RelevanceTypeName}</td>
                                    <td style={{ width: '220px' }}>{row.DocumentTypeName}</td>
                                    <td style={{ width: '250px' }}>{row.DocumentDescription}</td>                                    
                                    <td style={{ width: '85px' }}>{row.documentSourceTypeName && row.documentSourceTypeName.length > 0 && row.documentSourceTypeName.map((e) => <div>{e}</div>)}</td>
                                    <td style={{ width: '85px' }}>{row.documentSourceName && row.documentSourceName.length > 0 && row.documentSourceName.map((e) => <div>{e}</div>)}</td>
                                    <td>{row.documentTagName && row.documentTagName.length > 0 && row.documentTagName.map((e) => <div>{e}</div>)}</td>
                                    <td >{row.AttributeValue}</td>
                                    {props.tab === documentsAPIs.DOC_UPLOAD_TAB_NAME && <>
                                       
                                        
                                        <td style={{ width: '45px' }}>{row.ValueSequence}</td>
                                        <td  style={{ width: '650px' }}> {row.documentNames && row.documentNames.length > 0 && row.documentNames.map((name,i) => 
                                            
                                            <div className={`cursor-pointer ${row.multiDocument[i] ? 'multiRepFile' : 'text-info'}`} onClick={() => { props.redirectToPDFEditor(row, i) }}>{name}</div>
                                            )}
                                        </td>
                                        <td style={{ width: '70px' }}>
                                            {/* <FontAwesomeIcon
                                                className={`ml-2 ${(row.SubCategory === 'OPP CLMNT INFO' || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                fontSize={13}
                                                icon={faUpload}
                                                onClick={() => { props.onClickUpload(row) }}

                                            /> */}
                                            {props.preSalePermission === "Edit"  ? <div className="d-flex justify-content-end">
                                                <div className='mr-2' >{row.docId && row.docId.length > 0 &&
                                                    <>
                                                        {row.docId.map(((id, index) => <div><FontAwesomeIcon
                                                            className={`ml-2  ${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                            fontSize={'16px'}
                                                            icon={faPencilAlt}
                                                            onClick={() => { props.onClickEdit(id,row) }}
                                                            style={{ fontSize: '16px', cursor:'pointer' }}
                                                        /></div>))}

                                                    </>
                                                }
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        className={`ml-2  ${(row.SubCategory === 'OPP CLMNT INFO' || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                        fontSize={'16px'}
                                                        icon={faPlusSquare}
                                                        onClick={() => { props.onClickUpload(row,'add') }}
                                                        style={{ fontSize: '16px' }}
                                                    />

                                                </div>
                                            </div> : null}
                                        </td>
                                    </>}
                                    {props.tab === documentsAPIs.AVAIL_DOCS_TAB_NAME && <>
                                        <td  style={{ width: '650px' }}>
                                            {row.documentNames && row.documentNames.length > 0 && row.documentNames.map((name, i) => 
                                            <div className={`cursor-pointer ${row.multiDocument[i] ? 'multiRepFile' : 'text-info'}`} onClick={() => { row.DocumentTypeName === documentsAPIs.CLAIM_PKT ? props.onClickClaimPkt(row.docId[i]) : props.redirectToPDFEditor(row, i) }}>{name}</div>
                                           
                                            )}

                                        </td>
                                        <td style={{ width: '70px' }} className='text-right'>
                                            { props.preSalePermission === "Edit" ? <div className="d-flex justify-content-end">
                                                <div className='mr-2'>{row.docId && row.docId.length > 0 &&
                                                    <>
                                                        {row.docId.map(((id, index) => <div><FontAwesomeIcon
                                                            className={`ml-2  ${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                            fontSize={'16px'}
                                                            icon={faPencilAlt}
                                                            onClick={() => { props.redirectToUpload(row, 'edit', index) }}
                                                            style={{ fontSize: '16px' , cursor:'pointer'}}
                                                        /></div>))}

                                                    </>
                                                }
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        className={`ml-2  ${(row.SubCategory === documentsAPIs.OPP_CLMNT_INFO || row.SubCategory === documentsAPIs.CLAIM_PKT || !row.RelevanceTypeName || !row.DocumentTypeName) ? 'disableClick' : 'cursor-pointer'}`}
                                                        fontSize={'16px'}
                                                        icon={faPlusSquare}
                                                        onClick={() => { props.redirectToUpload(row) }}
                                                        style={{ fontSize: '16px' , cursor:'pointer'}}
                                                    />

                                                </div>
                                            </div> : null}


                                        </td>
                                    </>}
                                    <td style={{ width: '150px' }} >{row.documentNotes && row.documentNotes.length > 0 && row.documentNotes.map((note) => <div className='text-ellipsis' style={{ width: '150px' }} data-toggle="tooltip" data-placement="left" title={note}>{note}</div>)}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>

        }
    </div>)
} 