
const configMessages = {
    invalidEmail: 'Please enter valid email address',
    email: 'Please enter valid email address',
    userName: 'Please enter valid name',
    roomId: 'Please enter min 4 digits and maximum 10 digits valid  RoomId',
    name: 'Please enter valid name',
    fillField: 'Please fill above field',
    phoneNumber: 'please enter valid phone number',
    phone: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    noRecords: 'No Records Found',
    toDateBeforeFromDate: 'To date must be after from Date',
    fromAndToRangeMustBeDifferent: 'From range and To range must be different',
    noOfDaysValidation: 'No of days between From range and To range must be same',
    toRangeDateValidation: 'To Range must be after From Range dates',
    shiftErrMsg: 'Please Select Location First',
    shiftAddOnErrMsg: 'Please Select Primary Shift First',
    toDoctorErrMsg: 'Please Select From User First',
    noOptionsMessage: 'No Options Available',
    passwordMatchValidation: 'Password does not match',
    toTimeFromTimeValidation: 'To Time must be after From Time ',
    fillRadio: 'Please select any one',
    propertySearchV2Message: "Below are top 200 records for preview. The Export to Excel will export all the records up to 10,000 max records.",
    warningMessage: 'Session is about to expire in 5 minutes.',
    tokenMessage: 'Token not provided',
    sessionExpired: 'Session expired please login again.',
    ExclusionMessage: "Prospective Status='Likely Duplicate', 'Possibly Paid', 'Possible Duplicate- Retire', Property ID=Null, (Property State='TX' and Property Source='State File' and St. Prop. ID = NULL), Prop Source='IPv4, Name Issue Code or New Name Issue Code= 2, 3.* , 5, 6 ,9.9.",
    SESSION_EXPIRATION_WARNING_MESSAGE: 'Session is about to expire in 5 minutes.',
    SESSION_EXPIRED_MESSAGE: 'Session expired please login again.',
    NOTE_WHEN_REDIRECTING: "Green and Blue bucket counts exclude the properties that are shown under SP-Group1, SP-Group2, and SP-Group3.",
    splitTaskbychangeStatus: "Are you sure you want to split the card by changing the status from Not Started to In Progress?",
    taskchangetoUnassigned: "We can't make it as Unassigned while the status is in In Progress and the indicator is on",
    createUnassignedtask: "Are you sure you want to continue without assign the task?",
    updateUnassignedTask: "Cannot update an Unassigned Task Status ",
    checkListsValidation: "You have not checked all of the required checklist items for this purpose, please complete all required items to move forward",
    taskpurposeValidation: "Please Select the purpose for the respective label",
    NO_RFI_TO_CLAIM: "NO RFI is associated with this claim",
    NoActivityMessage: "No Activity for Current Task",
    analystWarning: "You are not allowed to see this screen. Please contact Administator",
    isMappedMandatory: "Please select the records with the same Mapped Name, Relevant Seller Name and Source State before saving.",
    mappedNameRelavantSellerNameMandatory: "Please select the Mapped Name and Relevent Seller Name before saving",
    statusReasonMandatory: "Please select Status Reason to update",
    selectAnyone: "Please select the Status and Status Reason to update or add note or claim id",
    norecordsSelected: "Please select the records to update",
    sameFileforSelectedRecords: "Please select the records of same  filefor and source state  for status Filed.",
    reasonWhenstatusSelected: "Please select Status Reason to update or add note or claim id",
    emptyNamesError: "Mapped Name and Relevant Seller Name for all selected properties must not be blank.",
    somethingWentWrong: "Something went wrong",
    noViewMessage:"You do not have permission to view this page. Please contact the Administrator for assistance.",
    bulkUpdateSelectRecord:"Please select saved records for bulk update."
};
export default configMessages;
