import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import store from '../../../App/store';
import TableModal from '../../CommonDataTable/TableModal';
import configMessages from '../../../../config/configMessages';
import config from '../../../../config/config'

// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class HighValueProperties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'HighValueProperties',
      defaultstatusArray: []
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    await this.getStatusOptions()
    await this.getNewStatusReasonsForEditor()

    // await this.getNewStatusReasonsForEditor()

    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }

  /**
 * 
 * @returns get reasons from propertyStatusandReasonField 
 */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = [], defaultReasonArray = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
    { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              // if (reason.status.startsWith("MATCHING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
              if (reason.status === config.highValuePropReport.defaultStatus) {
                defaultArray.push(config.highValuePropReport.defaultStatus
                )
              }
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          defaultReasonArray = await this.getReasonOptions(defaultArray)

          await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray, defaultstatusArray: defaultArray, defaultReasonArray: defaultReasonArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  openTableModel = async (item, seller) => {
    await this.setState({
      connectedClaimID: item.connectedClaimID,
      selectedItemforTableShow: item,
      seller: seller,
      isOpenTable: true,
    })
  }



  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.datatableref.getFilterCriteria()
    await this.datatableref.getDataFromServer(criteria)
  }

  getTableModal() {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        seller={this.state.seller}
        type={"Property Editor"}
        getClaimsTabDetails={this.getClaimsTabDetails}
        openFormModal={this.datatableref.openFormModal}
      />
    )
  }

  getReasonOptions = (val, type) => {
    let filterCriteria = {}

    if (val) {

      filterCriteria['criteria'] = [{ key: 'status', value: val, type: 'in', add_Filter: true }, { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
      ];
    } else {
      filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
      ];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    let data = []
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            if (val) {
              data = [config.highValuePropReport.defaultReason]
            } else {
              data.push(reason.reason)
            }
          }

          return data
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ reasons: [], propertyReasonArray: [] })
          return []
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  getDefaultArray = async () => {
    await this.getNewStatusReasonsForEditor()
    let obj = {
      statuses: this.state.defaultstatusArray,
      reasons: this.state.defaultReasonArray
    }
    return obj
  }

  getStatusOptions = () => {
    fetch('GET', apiCalls.PropertyStatuses)
      .then(async (response) => {
        if (response) {
          let statuses = response.propertyStatuses;
          let modifiedStatuses = [];
          for (let status of statuses) {
            modifiedStatuses.push({
              label: status.status,
              value: {
                status: status.status,
                id: status._id,
              },
            })
          }
          await this.setState({
            Statuses: modifiedStatuses
          })
          // this.setUnitName()

        }
      }).catch((err) => {
        return err;
      });
  }

  getDataAfterCustomizations = (modifiedData, response) => {
    const updatedArray = modifiedData.map(item => {
      const match = response.createdByUserList.find(ref => ref.id === item.createdBy);
      if (match) {
        item.createdByUser = match.name
      }
      return item; // Return unchanged object if no match is found
    });
    return updatedArray
  }

  getTableFields = () => {
    let data = [{
      textAlign: "center",
      width: 47,
      field: "",
      // fieldType: "multiple",
      header: "",
      selectionMode: "multiple",
      frozen: true,
      show: true,
      mobile: true,
      displayInSettings: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'estateName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Deal Name',
      // "fieldType": 'redirect',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 100,
      field: 'propertyDataSource',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Data Source',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 80,
      field: 'sourceState',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Source State',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'statePropertyId',
      //fieldType: 'BoolText',
      "mobile": true,
      "fieldType": 'redirect',
      header: 'State Property ID',
      "filterType": "num",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'propertyID',
      //fieldType: 'BoolText',
      "mobile": true,
      "fieldType": 'redirect',
      "filterType": "num",
      header: 'OPP Property ID',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'claimID',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'St Claim ID',
      // "fieldType": 'redirect',
      filter: true,
      // "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'connectedClaimID',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'OPP Claim ID',
      // "fieldType": 'redirect',
      filter: true,
      // "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'nameMatchScore',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Name Score',
      // "fieldType": 'redirect',
      filter: true,
      "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'businessName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Scored Name',
      // "fieldType": 'redirect',
      filter: true,
      // "filterType": "num",
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Reported Owner Name',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyDescription',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Description',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'propertyAddressLine1',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Property Address Line1',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },
    {
      "show": true,
      textAlign: 'left',
      width: 150,
      field: 'propertyState',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'State',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 100,
      "filterType": "num",
      "field": "propertyMinValueAmount",
      "fieldType": "formatCurrency",
      "mobile": true,
      header: 'Property Min Value',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },
    {
      "show": true,
      "textAlign": "right",
      "width": 100,
      "field": "propertyMaxValueAmount",
      "mobile": true,
      "filterType": "num",
      header: "Property Max Value",
      "fieldType": "formatCurrency",
      "displayInSettings": true,
      "filter": true,
      "sortable": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 100,
      "filterType": "num",
      "field": "escheatmentDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/yyyy',
      "header": "Date Escheated",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 80,
      "filterType": "num",
      "field": "createdDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Dt Created",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "field": "createdByUser",
      "mobile": true,
      "header": "Created By User",
      "filter": false,
      "sortable": false,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "filterType": "num",
      "field": "opraAddedDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Dt OPRA Added",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 115,
      "filterType": "dropdown",
      // "allowInt": true,
      "field": "confirmedOnsite",
      "fieldType": "confirmedOnSite",
      "mobile": true,
      "header": "Confirmed Onsite",
      "displayInSettings": true,
      "filter": true,
      "sortable": true,
    }, {
      "show": true,
      "textAlign": "left",
      "width": 150,
      "filterType": "num",
      "field": "confirmedOnsiteDate",
      "mobile": true,
      "fieldType": "Date",
      "dateFormat": 'MM/DD/YYYY',
      "header": "Confirmed Onsite Date",
      "filter": false,
      "sortable": true,
      "displayInSettings": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'confirmedOnsiteBy',

      "filterType": "dropdown",
      "mobile": true,
      header: 'Confirmed On Site By',
      filter: false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: false,
    }, {
      "show": true,
      textAlign: 'left',
      width: 300,
      field: 'holderName',
      // //fieldType: 'BoolText',
      "mobile": true,
      header: 'Holder Name',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      "filterType": "dropdown",
      field: 'hasPropertyCoOwner',
      "fieldType": "hasPropertyCoOwner",
      "mobile": true,
      header: 'Co owner',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'propertyStatus',
      "filterType": "multiSelect",
      "mobile": true,
      header: "Prop. Status",
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'propertyStatusReason',
      "filterType": "multiSelect",
      "mobile": true,
      header: 'Prop. Reason',
      filter: true,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    },

    {
      "show": true,
      "textAlign": "left",
      "width": 300,
      "field": "matchStatusNotes",
      "mobile": true,
      "fieldType": "Array",
      "filter": true,
      "openNotes": true,
      // "isFuzzy": true,
      "header": "Property Notes",
      "displayInSettings": true,
      // "filter": false,
      "sortable": true,
    }, {
      "show": true,
      textAlign: 'left',
      width: 200,
      field: 'team',
      "mobile": true,
      header: 'Team',
      filter: false,
      "displayInSettings": true,
      "displayInFilter": true,
      sortable: true,
    }

    ]
    return data;
  };

  getFormFields = () => {
    return ([
      // {
      //   //'show': false,
      //   // 'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screen",
      //   "label": "Screen",
      //   "options": [{ label: "Ready To File", value: "Ready To File" }, { label: "On Hold", value: "On Hold" }],
      //   "id": "Screen",
      //   "placeholder": "Screen",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "dealStageID",
        "label": "Stage ID",
        "id": "name",
        "placeholder": "Stage ID",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "dealStageName",
        "label": "Stage Name",
        "id": "name",
        "placeholder": "Stage Name",
        "required": true
      },
      // {
      //   //'show': false,
      //   // 'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "dealType",
      //   "label": "Deal Type",
      //   "options": [{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }],
      //   "id": "DealType",
      //   "placeholder": "Deal Type",
      //   "required": true
      // },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "status",
        "placeholder": "status",
        // "required": true
      },
      // {
      //   //'show': false,
      //   'isMultiSelect': true,
      //   "value": "",
      //   "type": "dropDown",
      //   "name": "screenType",
      //   "label": "Screen",
      //   "id": "name",
      //   "options": [{ label: "Property Editor", value: "Property Editor" }, { label: "Claims", value: "Claims" }],
      //   "placeholder": "Screen",
      //   "required": true
      // },
    ]);
  }

  //settingRedirectedUrl
  setRedirectUrl = async (item, clickedField, postBodyFile, screen) => {
    let url
    let filtercriteria = await this.datatableref.getFilterCriteria()
    let maxValueAmount = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount") : null
    let nameMatchScoreIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore") : null
    let propertyStatusReasonIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason") : null
    let propertyStatusIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus") : null
    let dataSourceIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource") : null
    let data = await this.getDefaultArray()
    this.state.postBodyFile = {}
    url = `${window.location.protocol}//${window.location.host}/${screen}?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item.companyID}${clickedField && clickedField === "propertyID" ? `&propertyId=` + encodeURIComponent(item.propertyID) : clickedField && clickedField === "statePropertyId" ? `&statePropertyId=` + encodeURIComponent(item.statePropertyId) : ""}${clickedField && clickedField === "Group1Count" ? `&bucket=` + "SP" : clickedField && clickedField === "Group3Count" ? `&bucket=` + "SP" : "&bucket=Green"}&reportType=HighValueProperty${`&selectedPropertyStatus=` + encodeURIComponent(propertyStatusIndex !== null && propertyStatusIndex !== -1 ? filtercriteria["criteria"][propertyStatusIndex].value : data.statuses)}&maxValueAmount=${maxValueAmount !== null && maxValueAmount !== -1 ? parseInt(filtercriteria["criteria"][maxValueAmount].value) : 50000}&nameMatchScore=${nameMatchScoreIndex !== null && nameMatchScoreIndex !== -1 ? parseInt(filtercriteria["criteria"][nameMatchScoreIndex].value) : 90}${`&selectedPropertyReasons=${encodeURIComponent(propertyStatusReasonIndex !== null && propertyStatusReasonIndex !== -1 ? filtercriteria["criteria"][propertyStatusReasonIndex].value : data.reasons)}`}`
    return url
  }
  submit = async (item) => {

  }
  getColorbasedonProp = (item) => {
    if (item.connectedClaimID && item.connectedClaimID.length > 0) {
      return "#72261a"
    } else if (item.connectedTaskID && item.connectedTaskID.length > 0) {
      return "#354f6e"
    }
  }
  render() {

    return (
      <>
        <span>
          {this.state.isOpenTable ? this.getTableModal() : null}
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            sortField={"PropertyMaxValueAmount"}
            exportRequried={false}
            excelDownload={true}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            isClearFilters={true}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={true}
            filterRequired={true}
            redirecteddUrl={true}
            onlyCoreopsTeam={true}
            paginatorPosition={"top"}
            getColorbasedonProp={this.getColorbasedonProp}
            // criteria={[{ key: "propertyStatus", value: this.state.defaultArray, type: "in" }]}
            gridRequried={false}
            sample={false}
            openTableModel={this.openTableModel}
            globalSearchFieldName='user'
            // globalSearch={'Search'}
            type='HighValueProperties'
            screenPermissionsRoute={"High Value Properties"}
            propertyStatusArray={this.state.propertyStatusArray}
            propertyStatusReasonArray={this.state.propertyStatusReasonArray}
            getDefaultStatusArray={this.getDefaultArray}
            displayright={true}
            setRedirectUrl={this.setRedirectUrl}
            icon='tag'
            routeTo='reports'
            displayViewOfForm='modal'
            getDataAfterCustomizations={this.getDataAfterCustomizations}
            apiResponseKey={"ns_scored_propertys"}
            apiUrl={apiCalls.HighValueProperties}
            entityType='employee'
          />

        </span>
      </>

    );
  }
}