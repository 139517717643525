import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetch from '../../../../config/service';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import store from '../../../App/store';
// import RolePermissions from '../../CommonModals/Permissions';

// config file
export default class ScheduledJobLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }



    componentDidMount = async () => {
        //Screen permisions value can be edit,view, no view
        // let screenPermissions = RolePermissions.screenPermissions('Employees');
    }

    getTableFields = () => {
        let data = [
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'affectedPage',
                // fieldType: 'Text',
                // "allowInt": true,
                "mobile": true,
                // "filterType": "num",
                headerBackgroundColor: "#DCEAF7",
                header: 'Affected Page',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'jobId',
                // fieldType: 'Text',
                "filterType": "num",
                "mobile": true,
                headerBackgroundColor: "#DCEAF7",
                header: 'Job ID',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'jobName',
                // fieldType: 'Text',
                "mobile": true,
                headerBackgroundColor: "#DCEAF7",
                header: 'Job Name',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                headerBackgroundColor: "#DCEAF7",
                width: 60,
                field: 'frequency',
                // fieldType: 'Text',
                "mobile": true,
                header: 'Frequency',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'jobStartedAtUtc',
                fieldType: 'Time',
                "filterType": "num",
                headerBackgroundColor: "#DCEAF7",
                "mobile": true,
                header: 'Process Start Date & Time (CST)',
                filter: true,
                sortable: true,
                "dateFormat": 'MM/DD/YYYY',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'jobEndedAtUtc',
                fieldType: 'Time',
                "filterType": "num",
                headerBackgroundColor: "#DCEAF7",
                "mobile": true,
                header: 'Process End Date & Time (CST)',
                filter: true,
                sortable: true,
                "dateFormat": 'MM/DD/YYYY',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'totalJobRunTime',
                // fieldType: 'totalJobRunTime',
                "mobile": true,
                headerBackgroundColor: "#DCEAF7",
                header: 'Total Processing Time',
                filter: true,
                sortable: true,
            },

            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'status',
                headerBackgroundColor: "#DCEAF7",
                fieldType: 'TextColor',
                "mobile": true,
                header: 'Status',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'progress',
                headerBackgroundColor: "#DCEAF7",
                // fieldType: 'TextColor',
                "mobile": true,
                header: 'Progress',
                isNotSortable:true,
                filter: false,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'lastTimeJobRunAtUtc',
                fieldType: 'Time',
                headerBackgroundColor: "#DCEAF7",
                "filterType": "num",
                "mobile": true,
                header: 'Last Successful Date',
                filter: true,
                sortable: true,
                "dateFormat": 'MM/DD/YYYY',
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                field: 'error',
                // fieldType: 'Text',
                "mobile": true,
                headerBackgroundColor: "#DCEAF7",
                header: 'Errors',
                filter: true,
                sortable: true,
            },
            {
                "show": true,
                textAlign: 'left',
                width: 60,
                headerBackgroundColor: "#DCEAF7",
                field: 'primaryContact',
                // fieldType: 'Text',
                "mobile": true,
                header: 'Primary Contact',
                filter: true,
                sortable: true,
            },
        ]
        return data;
    };

    getFormFields = () => {
        return ([
        ]);
    }

    submit = async (item) => {

    }

    render() {
        return (
            <span>
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sortField={"updatedAtUtc"}
                    exportRequried={false}
                    printRequried={false}
                    addRequried={false}
                    editRequired={false}
                    isClearFilters={true}
                    deleteRequired={false}
                    viewRequired={true}
                    showJobInfo={true}
                    settingsRequired={false}
                    filterRequired={false}
                    getTabforCronInfo={this.getTabforCronInfo}
                    gridRequried={false}
                    sample={false}
                    // globalSearchFieldName='DealName'
                    // globalSearch={'Search'}
                    type='Scheduled Job Log'
                    displayright={true}
                    icon='tag'
                    routeTo='scheduledJobLog'
                    displayViewOfForm='modal'
                    apiResponseKey={"schedulerJobs"}
                    apiUrl={apiCalls.SchedulerJob}
                    entityType='employee'
                />

            </span>
        );
    }
}