//download packages xlsx
import React from "react";


import * as XLSX from 'xlsx/xlsx.js'
import { saveAs } from 'file-saver';

import * as ExcelDoc from '../../UI/FormatExcel/formatExcel';


var currencyFormatWithDecimal = '$#,##0.00';
var numberFormatWithComma = '#,##0';
// Define custom date format
var dateFormat = 'mm-dd-yyyy';
var colorColumnOldName = "Claim Color By Claim Value Type";
var colorColumnNewName = "Claim Value Type";


const inferTypeAndFormatForExcel = (value, header, IsReport) => {
  if (value === "") {
    if (header === "nameIssueCode") {
      return { value: "N/A" };
    } else {
      return { value: value };
    }

  } else {
    if (typeof value === 'boolean' || ExcelDoc.isBooleanField(header)) {
      if (ExcelDoc.isBooleanField(header)) {
        return { value: value ? ExcelDoc.formatBooleanField(value, ExcelDoc.isBooleanField(header)) : "No" };
      } else {
        return { value: value ? value : false };
      }
    } else if (ExcelDoc.isInt(Number(value))) {
      if (!IsReport && header && (ExcelDoc.isCurrencyField(header))) {
        return ExcelDoc.formatDecimal(value)
      } else {
        if (IsReport && header && (ExcelDoc.isCurrencyField(header))) {
          return ExcelDoc.formatNumber(value, header, (ExcelDoc.isCurrencyField(header)));
        } else {
          return ExcelDoc.formatNumber(value, header);
        }

      }
    } else if (ExcelDoc.isFloat(Number(value))) {
      if (!IsReport) {
        return ExcelDoc.formatDecimal(value)
      } else {
        if (IsReport && header && (ExcelDoc.isCurrencyField(header))) {
          return ExcelDoc.formatNumber(value, header, (ExcelDoc.isCurrencyField(header)));
        } else {
          return ExcelDoc.formatNumber(value, header);
        }
      }
    } else {
      return { value: value };
    }
  }
}

function getParentMenuTextName(params) {
  // The target name of the parent <li> element
  const targetName = 'Reports'; // Replace with the desired name

  // Find all <li> elements that contain the target name
  const parentLis = Array.from(document.querySelectorAll('li.p-menuitem')).filter(li => {
    const span = li.querySelector('span.p-menuitem-text');
    return span && span.textContent.trim() === targetName;
  });

  // Collect all <ul li> elements from the matching parent <li> elements
  const allChildLis = parentLis.flatMap(li => Array.from(li.querySelectorAll('ul li.p-menuitem')));

  // Extract the href attributes from the child <li> elements' <a> tags
  const hrefs = allChildLis.map(childLi => {
    const anchorTag = childLi.querySelector('a.p-menuitem-link');
    return anchorTag ? anchorTag.getAttribute('href') : null;
  }).filter(href => href !== null);

  // Get the current route (window.location.pathname gives the path without the domain)
  const currentRoute = window.location.pathname;

  // Check if the current route exists in the hrefs array
  const routeExists = hrefs.includes(currentRoute);

  return routeExists;
}



export default class ExcelReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      IsReport: false
    };
  }

  getColumnName = (cellAddress) => {
    return cellAddress.replace(/\d+/, '');
  }

  getDefaultValueForExcel = (value, filterType, fieldType, header, IsReport) => {
    if (fieldType || filterType) {
      if (fieldType == "Date" || filterType == "num" || fieldType == "number" || fieldType == "Boolean") {
        if (filterType == "num" || fieldType == "number") {
          if (fieldType == "formatCurrency") {
            value = (!value || value == "") ? "0.0" : value
          } else if (fieldType == "Date") {
            value = value ? value : "";
          } else {
            value = ExcelDoc.getDefaultCurrencyValue(header, value, IsReport)
          }
        } else if (fieldType === 'Boolean' || ExcelDoc.isBooleanField(header)) {
          if (ExcelDoc.isBooleanField(header)) {
            value = value ? ExcelDoc.formatBooleanField(value, ExcelDoc.isBooleanField(header)) : "No";
          } else {
            value = value ? value : false;
          }
        } else {
          value = (value || value === 0) ? value : ""
        }
      } else if (header && (ExcelDoc.isCurrencyField(header) || ExcelDoc.isNumberField(header))) {
        value = ExcelDoc.getDefaultCurrencyValue(header, value, IsReport)
      } else if (header && header === "nameIssueCode") {
        value = value ? value : "N/A";
      } else if (typeof value === 'boolean' || ExcelDoc.isBooleanField(header)) {
        if (ExcelDoc.isBooleanField(header)) {
          value = value ? ExcelDoc.formatBooleanField(value, ExcelDoc.isBooleanField(header)) : "No";
        } else {
          value = value ? value : false;
        }
      } else {
        value = (value || value === 0) ? value : ""
      }
    } else {
      if (header && (ExcelDoc.isCurrencyField(header) || ExcelDoc.isNumberField(header))) {
        value = ExcelDoc.getDefaultCurrencyValue(header, value, IsReport)
      } else if (typeof value === 'boolean' || ExcelDoc.isBooleanField(header)) {
        if (ExcelDoc.isBooleanField(header)) {
          value = value ? ExcelDoc.formatBooleanField(value, ExcelDoc.isBooleanField(header)) : "No";
        } else {
          value = value ? value : false;
        }
      } else {
        value = (value || value === 0) ? value : ""
      }
    }
    return value;
  }
  exportToExcel = (data, fileName, headersdata, sheetname, IsReport) => {
    var filteredHeadersdata = headersdata.filter(header => header&&header.label&&header.label.length>0 ? header.label.toLowerCase() !== 'edit' : header ? header.toLowerCase() !== 'edit' : null);    // Create a header row with alias names
    var headerRow = filteredHeadersdata.map(header => header.label&&header.label.length>0 ? header.label : header ? header : "");

    // Filter data based on headers
    var filteredData = data.map(item => {
      var filteredItem = {};
      filteredHeadersdata.forEach(header => {
        if (header.label) {
          if (header.label === colorColumnOldName) {
            filteredItem[colorColumnNewName] = this.getDefaultValueForExcel(item[header.field], header.filterType, header.fieldType, header.field, IsReport);
          } else {
            filteredItem[header.label] = this.getDefaultValueForExcel(item[header.field], header.filterType, header.fieldType, header.field, IsReport);
          }
        } else if (header) {
          if (header === colorColumnOldName) {
            filteredItem[colorColumnNewName] = this.getDefaultValueForExcel(item[header], "", "", header, IsReport);
          } else {
            filteredItem[header] = this.getDefaultValueForExcel(item[header], "", "", header, IsReport);
          }
        }

      });
      return filteredItem;
    });
    // Find the index of the element you want to change
    const index = headerRow.indexOf(colorColumnOldName);

    // Check if the element exists in the array
    if (index !== -1) {
      // Replace the element at the found index with the new value
      headerRow[index] = colorColumnNewName;
    }
    // Create a worksheet from the formatted data
    var ws = XLSX.utils.json_to_sheet(filteredData, { header: headerRow, cellText: false, cellDates: true, dateNF: dateFormat });

    // Add headers explicitly using sheet_add_aoa
    XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

    var dateColumns = [];
    var numberColumns = [];
    var decimalColumns = [];
    // Format dates
    Object.keys(ws).forEach(cell => {
      var columnName = this.getColumnName(cell)
      if (ws[cell].t === 's' && ws[cell].v && ws[cell].v.trim(' ').match(/^\d{2,4}\/\d{1,2}\/\d{1,2}$/)) { // Format Dates
        ws[cell].v = ws[cell].v.trim(' ')
        ws[cell].z = 'mm-dd-yyyy';
        ws[cell].t = 'd';
        if (!dateColumns.includes(columnName + "1")) {
          dateColumns.push(columnName.concat("1"));
        }
      } else if (ws[cell].t === 's' && ws[cell].v && ws[cell].v.trim(' ').match(/^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2} [ap]m$/)) {
        ws[cell].v = ws[cell].v.trim(' ')
        ws[cell].z = 'mm-dd-yyyy hh:mm AM/PM';
        ws[cell].t = 'd';
        if (!dateColumns.includes(columnName + "1")) {
          dateColumns.push(columnName.concat("1"));
        }
      } else if ((ws[cell].v || ws[cell].v === 0) && (typeof ws[cell].v === 'number' || (ws[cell].t === 's' && (ws[cell].v.trim(' ').match(/^\d+$/) || ws[cell].v == "0.0")))) { // Format numbers
        if (ws[cell].t === 's') {
          var formattedObject = inferTypeAndFormatForExcel(ws[cell].v.trim(' '), ws[columnName + "1"].v, IsReport);
        } else {
          var formattedObject = inferTypeAndFormatForExcel(ws[cell].v, ws[columnName + "1"].v, IsReport);
        }
        ws[cell].v = formattedObject.value;
        ws[cell].t = 'n';
        if (formattedObject.numFmt) {
          ws[cell].z = formattedObject.numFmt
          if (formattedObject.numFmt === currencyFormatWithDecimal) {
            if (!decimalColumns.includes(columnName + "1")) {
              decimalColumns.push(columnName.concat("1"));
            }
          } else {
            if (!numberColumns.includes(columnName + "1")) {
              numberColumns.push(columnName.concat("1"));
            }
          }
        }
      } else if (typeof ws[cell].v === 'boolean') { // Format booleans
        ws[cell].v = ws[cell].v ? true : false;
        ws[cell].z = "@"
      }
    });
    dateColumns.forEach(colName => {
      ws[colName].z = dateFormat;
    })
    numberColumns.forEach(colName => {
      ws[colName].z = numberFormatWithComma;
    })
    decimalColumns.forEach(colName => {
      ws[colName].z = currencyFormatWithDecimal;
    })
    // Calculate column widths
    var range = XLSX.utils.decode_range(ws['!ref']);
    var colWidths = Array(range.e.c - range.s.c + 1).fill(0);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
        var cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
        var cell = ws[cellAddress];

        if (!cell) continue;
        // Calculate column width
        var cellLength = 0
        if (cell.v && cell.v.toString()) {
          cellLength = cell.v.toString().length + 2;
        }
        if (colWidths[colNum] < cellLength) {
          colWidths[colNum] = Math.min(cellLength, 50);
        }
      }
    }

    // Set column widths
    ws['!cols'] = colWidths.map(width => ({ wch: width }))
    // Add autoFilter to the worksheet
    ws['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

    return ws;

  };

  // Helper function to convert a string to an ArrayBuffer
  s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };

  componentDidMount() {
    this.handlePropsChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sheets !== this.props.sheets) {
      this.handlePropsChange(prevProps);
    }
  }

  async handlePropsChange() {
    // Your function logic here
    var { sheets, type } = this.props;
    var routeExists = getParentMenuTextName();
    await this.setState({
      IsReport: routeExists
    })
    if (sheets && sheets.length > 0) {
      // Create a new workbook
      var wb = XLSX.utils.book_new();
      var worksheet = "";
      sheets.map((sheet, i) => {
        if (sheet && sheet.data && sheet.name && sheet.headers && sheet.name.length > 0 && sheet.data.length > 0 && sheet.headers.length > 0) {
          worksheet = this.exportToExcel(sheet.data, this.props.EstateName, sheet.headers, sheet.name, routeExists)
        } else if (sheet && sheet.data && sheet.data.length > 0) {
          if (sheet.name && sheet.name.length > 0) {
            worksheet = this.exportToExcel(sheet.data, this.props.EstateName, [], sheet.name, routeExists)
          } else if (sheet.headers && sheet.headers.length > 0) {
            worksheet = this.exportToExcel(sheet.data, this.props.EstateName, sheet.headers, "Test", routeExists)
          } else {
            worksheet = this.exportToExcel(sheet.data, this.props.EstateName, [], "Test", routeExists)
          }
        } else if (sheet && sheet.headers && sheet.headers.length > 0) {
          worksheet = this.exportToExcel([], this.props.EstateName, sheet.headers, sheet.name ? sheet.name : "Test", routeExists)
        } else {
          worksheet = this.exportToExcel([], this.props.EstateName, [], "Test", routeExists)
        }
        // Append the worksheet to the workbook        
        XLSX.utils.book_append_sheet(wb, worksheet, sheet.name ? sheet.name : "Test");
      })
      // Generate a binary string representation of the workbook
      var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Create a Blob object from the binary string
      var blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

      // Save the Blob object as a file
      saveAs(blob, `${this.props.customFileName ? this.props.customFileName : this.props.EstateName}.xlsx`);
    }
  }


  render() {

    return (

      <div></div>
    );
  }
}