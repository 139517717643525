import React, { useEffect, useState, useMemo } from "react";
import DataTables from '../CommonDataTable/DataTable';
import TableFields from './MailForwardsTableFields.json';
import { InputText } from "primereact/inputtext";
import { Button } from 'reactstrap';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import states from '../../../shared/states.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import fetchMethodRequest from '../../../config/service';
import apiCalls from "../../../config/apiCalls";
import { Dropdown } from 'primereact/dropdown';
import Alerts from '../DocumentUpload/components/Alerts';
import Loader from '../../App/Loader';
import DealDropdown from "../../../shared/commonFields/DealDropdown";
import * as MailFwdService from '../../../shared/mailForwardsService';

const MailForwards = () => {
    const [topFilters, setTopFilters] = useState<any>({ KeyAddressFlag: [1, 0, null] });
    const [disableGoButton, setDisableGoButton] = useState<boolean>(true);
    const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
    const [formattedFilters, setFormattedFilters] = useState<Array<any>>([{ key: 'KeyAddressFlag', value: [1, 0, null], type: 'in', add_Filter: true }]);
    const [mftStatuses, setMftStatuses] = useState<Array<any>>([]);
    const [mftReasons, setMftReasons] = useState<Array<any>>([]);
    const [tableFilterMftReasons, setTableFilterMftReasons] = useState<Array<any>>([]);
    const [tableFilterMftStatus, setTableFilterMftStatus] = useState<Array<any>>([]);
    const [actionMftReasons, setActionMftReasons] = useState<Array<any>>([]);
    const [bulkUpdateData, setBulkUpdateData] = useState<any>({});
    const [respMessage, setRespMessage] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [doRefresh, setDoRefresh] = useState<boolean>(false);
    const [loadDataTable, setLoadDataTable] = useState<boolean>(false);

    const keyAddressOptions = [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 },
        { label: 'Blank', value: null }
    ]


    useEffect(() => {
        let disableButton = true;
        for (let prop in topFilters) {
            if (topFilters[prop]) {
                disableButton = false;
            }
        }
        setDisableGoButton(disableButton);

        return () => {
            setDisableGoButton(true);
        }
    }, [topFilters]);

    useEffect(() => {
        getMailForwardStatusandReasons();
    }, [])

    const redirectToMailForwardDetails = (col: any) => {
        let reqData = {
            MailFwdTrackingID: col.MailFwdTrackingID,
            CompanyID: col.CompanyID,
            CompanyName: col.CompanyName,
            Line1: col.Line1,
            City: col.City,
            Zip: col.Zip,
            BusinessLocationID: col.BusinessLocationID,
            LocationNameLabel: col.LocationNameLabel,
            StateProvince: col.StateProvince
        }
        return <div className="cursor-pointer text-info" onClick={() => window.open(`/mailForwardsDetails?mailFwdtrackDetails=${JSON.stringify(reqData)}`, '_blank', 'noopener=true')} >
            {col.MailFwdTrackingID}
        </div>
    }

    const keyAddressColumnDetails = (col: any) => {
        return <div className="text-center">
            {col.KeyAddressFlag === true ? 'Yes' : col.KeyAddressFlag === false ? 'No' : ''}
        </div>
    }

    const getMailForwardStatusandReasons = async () => {
        setIsLoading(true);
        const [mailFwdStatusesResult, mailFwdStatusesFilteresResult]: any = await MailFwdService.getMailFwdStatusandReasons(MailFwdService.MAIL_FORWARD);
        if (mailFwdStatusesResult && mailFwdStatusesResult.length > 0) {
            const [statuses, reasons] = await MailFwdService.getMailFwdStatusandReasonsForTableFilters(MailFwdService.MAIL_FORWARD, mailFwdStatusesResult);
            setTableFilterMftStatus(statuses);
            setTableFilterMftReasons(reasons);
        }
        if (mailFwdStatusesFilteresResult && mailFwdStatusesFilteresResult.length > 0) {
            setMftStatuses(mailFwdStatusesFilteresResult);
        }
        setIsLoading(false);
        setLoadDataTable(true);
    }


    const getTableFields = () => {
        let tableFields = JSON.parse(JSON.stringify(TableFields));
        tableFields.splice(1, 0, {
            "show": true,
            "textAlign": "left",
            "width": 70,
            "field": "MailFwdTrackingID",
            "mobile": true,
            "header": "Mail Fwd ID",
            "displayInSettings": true,
            "filter": true,
            "sortable": true,
            "fieldType": "customActions",
            "getCustomActions": redirectToMailForwardDetails
        });
        for (let i = 0; i < tableFields.length; i++) {
            if (tableFields[i].field === 'MailForwardStatus') {
                tableFields[i].filterOptions = tableFilterMftStatus;
            } else if (tableFields[i].field === 'MailForwardReason') {
                tableFields[i].filterOptions = tableFilterMftReasons;
            } else if (tableFields[i].field === 'KeyAddressFlag') {
                tableFields[i].filterOptions = keyAddressOptions;
                tableFields[i].fieldType = "customActions";
                tableFields[i].getCustomActions = keyAddressColumnDetails;
            }
        }
        return tableFields;
    }

    /**
     * On user enter top filters updating values in state.
     */
    const onChangeTopFilters = (field: string, value: any) => {
        setTopFilters((prevFilter: any) => ({ ...prevFilter, [field]: value }));
        if (field === 'mailFwdStatus') {
            if (value && value.length > 0) {
                const mailFwdReasons = value.flatMap((e: any) => (e.mailFwdReasons).sort((a: any, b: any) => a.mailFwdReasonSequence - b.mailFwdReasonSequence));
                if (topFilters.mailFwdReason && topFilters.mailFwdReason.length > 0) {
                    const selReasons = mailFwdReasons.map((e: any) => e.mailFwdReasonID);
                    const reasonsInSelStatuses = topFilters.mailFwdReason.filter((e: any) => selReasons.includes(e.mailFwdReasonID));
                    onChangeTopFilters('mailFwdReason', reasonsInSelStatuses);
                }
                setMftReasons(mailFwdReasons);
                // setMftReasons(mailFwdReasons.filter((e: any) => e.screenTypeForReason.includes(MailFwdService.MAIL_FORWARD)));
            } else {
                setMftReasons([]);
                onChangeTopFilters('mailFwdReason', '');
            }
        }
    }

    const onClickClearAllFilters = () => {
        setMftReasons([]);
        setTopFilters({ KeyAddressFlag: [1, 0, null], City: '', Zip: '', Line1: '' });
        setFormattedFilters([{ key: 'KeyAddressFlag', value: [1, 0, null], type: 'in', add_Filter: true }]);
    }

    const getCloseIcon = (field: string) => {
        return <FontAwesomeIcon icon={faTimes}
            className='pl-1 mr-2'
            color={topFilters[field] ? 'red' : 'lightgrey'}
            data-toggle="tool-tip"
            title="Clear Filter"
            id="clear stClaimId"
            onClick={() => onChangeTopFilters(field, '')}
            style={{ width: 'unset', cursor: "pointer", float: "right", pointerEvents: `${topFilters[field] ? 'auto' : 'none'}` }}
        />
    }

    const getLabel = (label: string, field: string) => {
        return <label className='d-flex justify-content-between mb-0'>
            <span>{label}</span>
            {getCloseIcon(field)}
        </label>
    }

    const onSelectRows = (selectedRows: Array<any>) => {
        setSelectedRows(selectedRows);
        setBulkUpdateData({ notes: '', mailFwdStatus: '', mailFwdReason: '' });
    }

    /**
     * Update functionality
     */
    const doBulkUpdate = async () => {
        setIsLoading(true);
        setDoRefresh(false);
        let body = {
            mftIDs: selectedRows.map((e: any) => e.MailFwdTrackingID),
            mftStatusID: bulkUpdateData.mailFwdStatus && bulkUpdateData.mailFwdStatus.mailFwdStatusID ? bulkUpdateData.mailFwdStatus.mailFwdStatusID : null,
            mftReasonID: bulkUpdateData.mailFwdReason && bulkUpdateData.mailFwdReason.mailFwdReasonID ? bulkUpdateData.mailFwdReason.mailFwdReasonID : null,
            mftNote: bulkUpdateData.notes,
            userid:localStorage.getItem("loginCredentials") ? JSON.parse((localStorage.getItem("loginCredentials") || '{}' ))?.azureAdUserId : undefined
        }
        const res = await fetchMethodRequest('POST', `mailForwardsDetails/bulkUpdateMFTs`, body, '', '', '');
        setIsLoading(false);
        setRespMessage({ type: res.success ? 'success' : 'danger', message: res.message });
        if (res.success) {
            setBulkUpdateData({ notes: '' });
            setDoRefresh(true);
        }
        setTimeout(() => {
            setRespMessage({ type: '', message: '' });
        }, 1000 * 5);
    }


    /**
     * On user click GO, Formatting filters & triggering api call.
     */
    const onClickGo = () => {
        let topFiltersCriteria: Array<any> = [];
        for (let prop in topFilters) {
            if (topFilters[prop]) {
                if (prop === 'deal') {
                    topFiltersCriteria.push({ key: 'CompanyID', value: topFilters[prop].DealID.toString(), type: 'eq', add_Filter: true });
                } else if (prop === 'mailFwdStatus') {
                    topFiltersCriteria.push({ key: 'MailForwardStatusID', value: topFilters[prop].map((e: any) => e.mailFwdStatusID), type: 'in', add_Filter: true });
                } else if (prop === 'mailFwdReason') {
                    topFiltersCriteria.push({ key: 'MailForwardReasonID', value: topFilters[prop].map((e: any) => e.mailFwdReasonID), type: 'in', add_Filter: true });
                } else if (prop === 'StateProvince') {
                    topFiltersCriteria.push({ key: 'StateProvince', value: topFilters[prop].map((e: any) => e.shortCode), type: 'in', add_Filter: true });
                } else if (prop === 'KeyAddressFlag') {
                    topFiltersCriteria.push({ key: 'KeyAddressFlag', value: topFilters[prop], type: 'in', add_Filter: true });
                } else {
                    topFiltersCriteria.push({ key: prop, value: topFilters[prop], type: 'regexOr', add_Filter: true });
                }
            }
        }
        setFormattedFilters(topFiltersCriteria);
    }

    /**
     * On user change input values in update functionality
     */
    const onChangeInputValues = (field: string, value: any) => {
        setBulkUpdateData((prevDetails: any) => ({ ...prevDetails, [field]: value }));
        if (field === 'mailFwdStatus' && value && value.mailFwdReasons) {
            setActionMftReasons(MailFwdService.getMailForwardReasons(value.mailFwdReasons, MailFwdService.MAIL_FORWARD));
            if (bulkUpdateData.mailFwdReason) {
                onChangeInputValues('mailFwdReason', '');
            }
        }
    }

    /**
     * Setting user selected deal into state.     
     */
    const onSelectDeal = (deal: any) => {
        onChangeTopFilters('deal', deal);
    }

    const getDataTable = useMemo(() => {
        return <DataTables
            getTableFields={getTableFields}
            formFields={getTableFields}
            exportRequried={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            sortField={"LastFwdRequestDate"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            filterRequired={true}
            gridRequried={false}
            isSelectMode={""}
            // isShownnFieldType={true}
            sample={false}
            scrollHeight={"580px"}
            dataKey={"MailFwdTrackingID"}
            globalSearch={'Search'}
            type={"Mail Forwards"}
            displayright={true}
            icon='faCity'
            // settingsData={this.state.settingsData}
            fontAwesome={true}
            // routeTo='propertyEditor'
            displayViewOfForm='modal'
            hideHeader={true}
            // apiResponseKey='details'
            className={true}
            apiUrl={"mailForwardsDetails/getDetailsWithDealFiltered"}
            entityType='employee'
            // selDealId={dealId}
            // docLibraryTopFilters={docLibraryTopFilters}
            hideProgress={true}
            // removeTopFilter={removeTopFilter}
            // doSearch={doSearch}
            paginatorPosition={'top'}
            style={{ marginTop: 10 }}
            donotIncludeParamsInFilter={true}
            showTopActions={true}
            onSelectRows={onSelectRows}
            topSectionFilters={formattedFilters}
            doRefresh={doRefresh}
            removePadding={true}
            dateFilterAsIs={true}
            showFilterElements={false}
        />
    }, [formattedFilters, doRefresh, tableFilterMftStatus, tableFilterMftReasons])


    return <>
        <div className="top-filters mail-forwards-filters card-body mt-1 py-2 px-3">
            <div className="row">
                <div className="col-md-11">
                    <div className="row">
                        <div className="col-md-3 mb-1">
                            {getLabel('Deal', 'deal')}
                            <DealDropdown onSelectDeal={onSelectDeal} selectedDeal={topFilters.deal} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('State', 'StateProvince')}
                            <MultiSelect value={topFilters.StateProvince} options={states} optionLabel="label" onChange={(e) => { onChangeTopFilters('StateProvince', e.value) }}
                                style={{ minWidth: '100%' }} filter={true} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('Address Line 1', 'Line1')}
                            <InputText className="w-100" value={topFilters.Line1} onChange={(e) => { onChangeTopFilters('Line1', (e.target as HTMLInputElement).value) }} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('City', 'City')}
                            <InputText className="w-100" value={topFilters.City} onChange={(e) => { onChangeTopFilters('City', (e.target as HTMLInputElement).value) }} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('Zip', 'Zip')}
                            <InputText className="w-100" value={topFilters.Zip} onChange={(e) => { onChangeTopFilters('Zip', (e.target as HTMLInputElement).value) }} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('Mail Fwd Status', 'mailFwdStatus')}
                            <MultiSelect value={topFilters.mailFwdStatus} options={mftStatuses} optionLabel="mailFwdStatus" dataKey="_id" onChange={(e) => { onChangeTopFilters('mailFwdStatus', e.value) }}
                                style={{ minWidth: '100%' }} filter={true} />
                        </div>
                        <div className="col-md-3 mb-1">
                            {getLabel('Mail Fwd Reason', 'mailFwdReason')}
                            <MultiSelect value={topFilters.mailFwdReason} options={mftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeTopFilters('mailFwdReason', e.value) }}
                                style={{ minWidth: '100%' }} filter={true} />
                        </div>
                        <div className="col-md-3 mb-1 text-center">
                            {getLabel('Key Address', 'KeyAddressFlag')}
                            <MultiSelect value={topFilters.KeyAddressFlag} options={keyAddressOptions} onChange={(e) => { onChangeTopFilters('KeyAddressFlag', e.value) }}
                                style={{ minWidth: '100%' }} filter={true} />

                        </div>
                    </div>
                </div>
                <div className="col-md-1 d-flex justify-content-center align-items-center flex-column ">
                    <Button color="success" type='submit' className='go-button mr-0' style={{ width: '60%' }} disabled={disableGoButton} onClick={onClickGo}>
                        GO
                    </Button>
                    <Button color="primary" outline style={{ width: '60%' }} onClick={onClickClearAllFilters}>
                        X
                    </Button>
                </div>
            </div>
        </div>

        <Accordion className="mt-2">
            <AccordionTab header="Action">
                <div className="card-body mt-2 p-1">
                    <div className="row">
                        <div className="col-md-11">
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    <div className="d-flex flex-column justify-content-between h-100">
                                        <div>
                                            <label className="mb-0">Mail Fwd Status</label>
                                            <Dropdown value={bulkUpdateData.mailFwdStatus} disabled={selectedRows.length === 0} options={mftStatuses} optionLabel="mailFwdStatus" onChange={(e) => { onChangeInputValues('mailFwdStatus', e.value) }}
                                                style={{ minWidth: '100%' }} filter={true} />
                                        </div>
                                        <div className="">
                                            <label className="mb-0">Mail Fwd Reason</label>
                                            <Dropdown value={bulkUpdateData.mailFwdReason} disabled={selectedRows.length === 0 || !bulkUpdateData.mailFwdStatus} options={actionMftReasons} optionLabel="mailFwdReason" dataKey="_id" onChange={(e) => { onChangeInputValues('mailFwdReason', e.value) }}
                                                style={{ minWidth: '100%' }} filter={true} />
                                        </div>

                                    </div>

                                </div>

                                <div className="col-md-3 ">
                                    <label className="mb-0">Note</label>
                                    <InputTextarea disabled={selectedRows.length === 0} value={bulkUpdateData.notes} className="w-100" rows={5} onChange={(e) => onChangeInputValues('notes', (e.target as HTMLInputElement).value)} />
                                </div>
                                <div className="col-md-3 mb-2">
                                    <div className="d-flex flex-column justify-content-center h-100">
                                        <div>
                                            <Button disabled={selectedRows.length === 0 || (!bulkUpdateData.mailFwdStatus && !bulkUpdateData.notes)} color="primary" type='submit' onClick={doBulkUpdate} >
                                                Save
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    {respMessage.message && <Alerts type={respMessage.type} message={respMessage.message} changeStatus={() => { setRespMessage({ type: '', message: '' }) }} />}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </AccordionTab>

        </Accordion>

        {loadDataTable &&
            <>{getDataTable}</>
        }
        {/* <Loader loader={isLoading} /> */}
    </>
}

export default MailForwards;