import React from "react";
import DataTables from "../../CommonDataTable/DataTable";
import { Button, Row } from "reactstrap";
import apiCalls from "../../../../config/apiCalls";
import moment from "moment-timezone";
import store from "../../../App/store";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import fetch from "../../../../config/service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnGroup } from "primereact/columngroup";
import { Column } from "primereact/column";

// config file
export default class NamesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Summary Sheet",
      tabType: "Summary Sheet",
      apiUrl: apiCalls.summarySheet,
      responseKey: "results",
      toDate: new Date(),
      sortField: "DealName",
      fromDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions["Deal Mgmt"] === "Edit") {
      await this.setState({
        readOnly: true,
        additionalQuery: `&startTime=${moment(this.state.fromDate).format(
          "MM-DD-YYYY"
        )}&stopTime=${moment(this.state.toDate).format("MM-DD-YYYY")}`,
      });
    }
  };

  getSummarySheetScreen = () => {
    let data = [
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "DealName",
        mobile: true,
        header: "Deal Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Deal ID",
        filterType: "num",
        mobile: true,
        field: "DealID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "NewNamesCount",
        mobile: true,
        filterType: "num",
        header: "Number of New Names",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        filterType: "num",
        field: "IssueNameChangesCount",
        mobile: true,
        header: "Number of Issue Names",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        filterType: "num",
        // allowInt: true,
        header: "Number of Legacy Issue Names",
        field: "LegacyIssueNameChangesCount",
        filter: true,
        displayInSettings: true,
        displayInFilter: false,
        sortable: true,
      },
    ];
    return data;
  };

  isTabChanged = async (val) => {
    if (val === true) {
      this.setState({
        selectedSeller: null,
      });
    }
  };

  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let sortField, footer;
      let responseKey = this.state.responseKey;
      if (tabType === "Summary Sheet") {
        apiUrl = apiCalls.summarySheet;
        responseKey = "results";
        sortField = "DealName";
        footer = this.getSummaryFooter();
      } else if (tabType === "New Names") {
        apiUrl = apiCalls.reportsnewNames;
        responseKey = "newNames";
        sortField = "DWDateInserted";
      } else if (tabType === "Issue Name Updates") {
        apiUrl = apiCalls.BusinessIssueChangesHistory;
        responseKey = "businessIssueChangesHistories";
        sortField = "TimestampUTC";
      } else if (tabType === "Legacy Issue Name Updates") {
        apiUrl = apiCalls.BusinessIssueChangesHistory;
        responseKey = "businessIssueChangesHistories";
        sortField = "TimestampUTC";
      } else {
        apiUrl = apiCalls.summarySheet;
        responseKey = "results";
        sortField = "DealName";
        footer = this.getSummaryFooter();
      }
      if (this.dataTableRef && this.state.tabType !== tabType) {
        this.dataTableRef.getremoveFilters("removeglobal");
      }
      var fromDate =
        tabType === this.state.tabType && this.state.fromDate
          ? this.state.fromDate
          : tabType !== "Issue Name Updates" &&
            tabType !== "Legacy Issue Name Updates"
          ? new Date(new Date().setDate(new Date().getDate() - 7))
          : null;
      var toDate =
        tabType === this.state.tabType && this.state.toDate
          ? this.state.toDate
          : tabType !== "Issue Name Updates" &&
            tabType !== "Legacy Issue Name Updates"
          ? new Date()
          : null;
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey,
        sortField: sortField,
        toDate:
          tabType === "Issue Name Updates" ||
          tabType === "Legacy Issue Name Updates"
            ? toDate
              ? toDate
              : null
            : toDate,
        fromDate:
          tabType === "Issue Name Updates" ||
          tabType === "Legacy Issue Name Updates"
            ? fromDate
              ? fromDate
              : null
            : fromDate,
        additionalQuery: `&startTime=${moment(
          new Date(new Date().setDate(new Date().getDate() - 7))
        ).format("MM-DD-YYYY")}&stopTime=${moment(new Date()).format(
          "MM-DD-YYYY"
        )}`,
      });
    }

    let data = [];
    if (tabType === "Summary Sheet") {
      data = this.getSummarySheetScreen();
    } else if (tabType === "New Names") {
      data = this.getNewNamesTableFields();
    } else if (tabType === "Issue Name Updates") {
      data = this.getIssueNamesTableFields();
    } else if (tabType === "Legacy Issue Name Updates") {
      data = this.getIssueNamesTableFields();
    } else {
      data = this.getSummarySheetScreen();
    }
    return data;
  };

  getFooter = (response) => {
    let tabType = this.state.tabType,
      data;
    if (tabType === "Summary Sheet") {
      data = this.getSummaryFooter(response);
    }
    if (data) {
      return data;
    } else {
      return "";
    }
  };
  getNewNamesTableFields = () => {
    let data = [
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "DealName",
        mobile: true,
        header: "Deal Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Deal ID",
        mobile: true,
        filterType: "num",
        field: "CompanyID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "BusinessName",
        mobile: true,
        header: "Business Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "IssueName",
        mobile: true,
        // "allowInt": true,
        header: "Issue Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        header: "Legacy Issue Name",
        field: "LegacyIssues",
        filter: true,
        displayInSettings: true,
        displayInFilter: false,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "DWDateInserted",
        mobile: true,
        fieldType: "Date",
        filterType: "num",
        dateFormat: "MM/DD/yyyy",
        header: "Created Date",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Created By user name",
        mobile: true,
        field: "CreatedUser",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Notes",
        mobile: true,
        field: "IssueNote",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
    ];
    return data;
  };

  getIssueNamesTableFields = () => {
    let data = [
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "DealName",
        mobile: true,
        header: "Deal Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "BusinessName",
        mobile: true,
        header: "Bussiness Name",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Deal ID",
        mobile: true,
        filterType: "num",
        field: "DealID",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "BusinessNameID",
        mobile: true,
        filterType: "num",
        header: "Business Name ID",
        // "allowInt": true,
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "IssueNamesFrom",
        mobile: true,
        header: "Issue Name From",
       displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "IssueNamesTo",
        mobile: true,
        header: "Issue Name To",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        header: "Legacy Issues From ",
        field: "LegacyIssuesFrom",
        filter: true,
        displayInSettings: true,
        displayInFilter: false,
        sortable: true,
      },

      {
        show: true,
        textAlign: "left",
        width: 150,
        mobile: true,
        header: "Legacy Issues To ",
        field: "LegacyIssuesTo",
        filter: true,
        displayInSettings: true,
        displayInFilter: false,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        field: "TimestampUTC",
        mobile: true,
        fieldType: "Date",
        filterType: "num",
        dateFormat: "MM/DD/yyyy",
        header: "Created Date",
        displayInSettings: true,
        filter: true,
        sortable: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 150,
        header: "Created By user name",
        mobile: true,
        field: "CreatedUser",
        displayInSettings: true,
        filter: true,
        sortable: true,
      }
    ];
    return data;
  };

  getFormFields = () => {
    return;
  };

  selectedOption = (key, val, type) => {
    let filterCriteria = this.datatableref.getFilterCriteria();
    let filtercriteria = filterCriteria;

    let selectedSellerDealId = filtercriteria["criteria"].findIndex(
      (obj) => obj.key === "CompanyID" || obj.key === "DealID"
    );
    if (selectedSellerDealId !== -1) {
      filtercriteria["criteria"].splice(selectedSellerDealId, 1);
    }
    if (this.state.selectedSeller) {
      filtercriteria["criteria"].push({
        key: this.state.tabType === "New Names" ? "CompanyID" : "DealID",
        value: this.state.selectedSeller.EstateID,
        type: "eq",
        add_Filter: true,
      });
    }
    let selectedSellerFromDate = filtercriteria["criteria"].findIndex(
      (obj) =>
        (obj.key === "DWDateInserted" || obj.key === "TimestampUTC") &&
        obj.type === "dategte"
    );
    if (selectedSellerFromDate !== -1) {
      filtercriteria["criteria"].splice(selectedSellerFromDate, 1);
    }
    if (this.state.fromDate && this.state.tabType !== "Summary Sheet") {
      filtercriteria["criteria"].push({
        key:
          this.state.tabType === "New Names"
            ? "DWDateInserted"
            : "TimestampUTC",
        value: moment(this.state.fromDate).format("MM-DD-YYYY"),
        type: "dategte",
        add_Filter: true,
      });
    }

    let selectedSellerToDate = filtercriteria["criteria"].findIndex(
      (obj) =>
        (obj.key === "DWDateInserted" || obj.key === "TimestampUTC") &&
        obj.type === "datelte"
    );
    if (selectedSellerToDate !== -1) {
      filtercriteria["criteria"].splice(selectedSellerToDate, 1);
    }
    if (this.state.toDate && this.state.tabType !== "Summary Sheet") {
      filtercriteria["criteria"].push({
        key:
          this.state.tabType === "New Names"
            ? "DWDateInserted"
            : "TimestampUTC",
        value: moment(this.state.toDate).format("MM-DD-YYYY"),
        type: "datelte",
        add_Filter: true,
      });
    }
    let data;
    if (
      this.state.tabType === "Summary Sheet" &&
      this.state.fromDate &&
      this.state.toDate
    ) {
      data = `&startTime=${moment(this.state.fromDate).format(
        "MM-DD-YYYY"
      )}&stopTime=${moment(this.state.toDate).format("MM-DD-YYYY")}`;
    } else if (this.state.tabType === "Summary Sheet" && this.state.fromDate) {
      data = `&startTime=${moment(this.state.fromDate).format("MM-DD-YYYY")}`;
    } else if (this.state.tabType === "Summary Sheet" && this.state.toDate) {
      data = `&stopTime=${moment(this.state.toDate).format("MM-DD-YYYY")}`;
    }
    this.setState({ filterCriteria: filtercriteria, additionalQuery: data });
    this.dataTableRef.getDataFromServer(filterCriteria);
  };
  editorTemplateForDeal(option) {
    return `${option.DealType} - ${option.SellerNickName} (${
      option.DealYear ? option.DealYear : ""
    })`;
  }
  searchsellerItems = async (event, criteria) => {
    let apiUrl;
    if (event) {
      if (event.query) {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(
          event.query
        )}`;
      } else if (typeof event === "string" && event.length > 0) {
        apiUrl = `${apiCalls.propertysellers}?str=${encodeURIComponent(event)}`;
      } else {
        apiUrl = `${apiCalls.propertysellers}`;
      }
    } else {
      apiUrl = `${apiCalls.propertysellers}?str = ${encodeURIComponent(
        event.query ? event.query : event
      )} `;
    }
    return fetch("GET", apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [];

          if (
            response["sellers"] &&
            response["sellers"].length &&
            response["sellers"].length > 0
          ) {
            dropdownData = response["sellers"];
          } else if (response && response.errorMessage) {
            this.props.getErrorResponseFromServer(response);
          }
          if (dropdownData && dropdownData.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true,
            });
          }
          await this.setState({
            filteredSuggestions: dropdownData,
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0],
            });
          }
        }
      })
      .catch((err) => {
        return err;
      });
  };

  getHeaderforSchedule = () => {
    return (
      <div className="col-sm-12 d-flex justify-content-flex-start mb-2">
        <div className="col-sm-3">
          <div>
            {" "}
            <div className="d-flex justify-content-between">
              <label>Deal Name </label>
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="pl-1"
                  color="red"
                  onClick={() => this.setState({ selectedSeller: null })}
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                />
              </span>
            </div>
            <AutoComplete
              itemTemplate={this.editorTemplateForDeal}
              value={
                this.state.selectedSeller &&
                this.state.selectedSeller.EstateName
                  ? `${this.state.selectedSeller.DealType} - ${
                      this.state.selectedSeller.SellerNickName
                    } (${
                      this.state.selectedSeller &&
                      this.state.selectedSeller.DealYear
                        ? this.state.selectedSeller.DealYear
                        : ""
                    })`
                  : this.state.selectedSeller
              }
              suggestions={
                this.state.filteredSuggestions
                  ? this.state.filteredSuggestions
                  : []
              }
              completeMethod={this.searchsellerItems}
              minLength={1}
              id="EstateName"
              field={"EstateName"}
              style={{ width: "100%" }}
              dropdown={true}
              onChange={(e) => this.setState({ selectedSeller: e.value })}
              appendTo={document.body}
            />
          </div>
        </div>

        <div className="col-sm-3">
          <div>
            <div className="d-flex justify-content-between">
              <label>From Date</label>
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="pl-1"
                  color="red"
                  onClick={() => this.setState({ fromDate: null })}
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                />
              </span>
            </div>

            <Calendar
              style={{
                zindex: 1004,
                width: "100%",
                lineHeight: 1.15,
                height: "25px",
              }}
              appendTo={document.body}
              monthNavigator={true}
              yearNavigator={true}
              yearRange="1940:2530"
              name={"calendar1"}
              // placeholder='mm/dd/yyyy'
              dateFormat="mm/dd/yy"
              value={this.state.fromDate}
              onChange={(e) => this.setState({ fromDate: e.value })}
            />
          </div>{" "}
        </div>
        <div className="col-sm-3">
          <div>
            {" "}
            <div className="d-flex justify-content-between">
              <label>To Date</label>{" "}
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="pl-1"
                  color="red"
                  onClick={() => this.setState({ toDate: null })}
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                />
              </span>
            </div>
            <Calendar
              style={{
                zindex: 1004,
                width: "100%",
                lineHeight: 1.15,
                height: "25px",
              }}
              appendTo={document.body}
              monthNavigator={true}
              yearNavigator={true}
              yearRange="1940:2530"
              name={"calendar1"}
              dateFormat="mm/dd/yy"
              value={this.state.toDate}
              onChange={(e) => this.setState({ toDate: e.value })}
            />
          </div>
        </div>
        <Button
          className="mb-0 mt-auto"
          style={{ height: "34px" }}
          color="primary"
          onClick={() => this.selectedOption()}
        >
          GO
        </Button>
      </div>
    );
  };

  submit = async (item) => {};

  getSummaryFooter = (data) => {
    if (data) {
      var totalIssueName = Object.values(data).reduce(
        (t, { IssueNameChangesCount }) =>
          t +
          parseInt(IssueNameChangesCount === null ? 0 : IssueNameChangesCount),
        0
      );
      var totalNewName = Object.values(data).reduce(
        (t, { NewNamesCount }) =>
          t + parseInt(NewNamesCount === null ? 0 : NewNamesCount),
        0
      );
      var totalLegacyIssues = Object.values(data).reduce(
        (t, { LegacyIssueNameChangesCount }) =>
          t +
          parseInt(
            LegacyIssueNameChangesCount === null
              ? 0
              : LegacyIssueNameChangesCount
          ),
        0
      );
      return (
        <ColumnGroup>
          <Row>
            <Column className="text-left" footer="Total:" colSpan={2} />
            <Column
              className="text-left"
              footer={totalNewName ? totalNewName.toFixed(1) : null}
            />
            <Column
              className="text-left"
              footer={totalIssueName ? totalIssueName.toFixed(1) : null}
            />
            <Column
              className="text-left"
              footer={totalLegacyIssues ? totalLegacyIssues.toFixed(1) : null}
            />
          </Row>
        </ColumnGroup>
      );
    } else {
      return null;
    }
  };

  getNamesFooter = (data) => {
    var totalIssueName, totalLegacyIssues;
    if (data) {
      var totalIssueName = Object.values(data).reduce(
        (t, { IssueName }) => t + parseInt(IssueName),
        0
      );
      var totalLegacyIssues = Object.values(data).reduce(
        (t, { LegacyIssues }) =>
          t + parseInt(LegacyIssues === null ? 0 : LegacyIssues),
        0
      );
      return (
        <ColumnGroup>
          <Row>
            <Column className="text-left" footer="Total:" colSpan={3} />
            <Column
              className="text-left"
              footer={totalIssueName ? totalIssueName : ""}
            />
            <Column
              className="text-left"
              footer={totalLegacyIssues ? totalLegacyIssues : ""}
            />
            <Column footer={""} colSpan={3} />
          </Row>
        </ColumnGroup>
      );
    } else {
      return null;
    }
  };

  getLegacyIssuesFooter = (data) => {
    if (data) {
      var totalIssueNamesFrom = Object.values(data).reduce(
        (t, { IssueNamesFrom }) =>
          t + parseInt(IssueNamesFrom === null ? 0 : IssueNamesFrom),
        0
      );
      var totalIssueNamesTo = Object.values(data).reduce(
        (t, { IssueNamesTo }) =>
          t + parseInt(IssueNamesTo === null ? 0 : IssueNamesTo),
        0
      );
      var totalLegacyIssuesFrom = Object.values(data).reduce(
        (t, { LegacyIssuesFrom }) =>
          t + parseInt(LegacyIssuesFrom === null ? 0 : LegacyIssuesFrom),
        0
      );
      var totalLegacyIssuesTo = Object.values(data).reduce(
        (t, { LegacyIssuesTo }) =>
          t + parseInt(LegacyIssuesTo === null ? 0 : LegacyIssuesTo),
        0
      );

      return (
        <ColumnGroup>
          <Row>
            <Column className="text-left" footer="Total:" colSpan={4} />
            <Column
              className="text-left"
              footer={
                totalIssueNamesFrom ? totalIssueNamesFrom.toFixed(1) : null
              }
            />
            <Column
              className="text-left"
              footer={totalIssueNamesTo ? totalIssueNamesTo.toFixed(1) : null}
            />
            <Column
              className="text-left"
              footer={
                totalLegacyIssuesFrom ? totalLegacyIssuesFrom.toFixed(1) : null
              }
            />
            <Column
              className="text-left"
              footer={
                totalLegacyIssuesTo ? totalLegacyIssuesTo.toFixed(1) : null
              }
            />
            <Column className="text-left" footer="" colSpan={2} />
          </Row>
        </ColumnGroup>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.dataTableRef = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={this.state.tabType === "Deal Mgmt" ? true : false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          screenPermissionsRoute={"Change Report"}
          gridRequried={false}
          criteria={
            this.state.tabType === "Issue Name Updates"
              ? [{ key: "IssuesNamesUpdatedFlag", value: "1", type: "eq" }]
              : this.state.tabType === "Legacy Issue Name Updates"
              ? [{ key: "LegacyIssuesUpdatedFlag", value: "1", type: "eq" }]
              : this.state.tabType === "New Names"
              ? [
                  {
                    key: "DWDateInserted",
                    value: moment(this.state.fromDate).format("MM-DD-YYYY"),
                    type: "dategte",
                  },
                  {
                    key: "DWDateInserted",
                    value: moment(this.state.toDate).format("MM-DD-YYYY"),
                    type: "datelte",
                  },
                ]
              : []
          }
          fontAwesome={this.state.tabType === "Deal Mgmt" ? true : false}
          sample={false}
          isClearFilters={true}
          tableFooter={this.getFooter}
          exportWithoutCriteria={
            this.state.tabType === "Issue Name Updates"
              ? [{ key: "IssuesNamesUpdatedFlag", value: "1", type: "eq" }]
              : this.state.tabType === "Legacy Issue Name Updates"
              ? [{ key: "LegacyIssuesUpdatedFlag", value: "1", type: "eq" }]
              : []
          }
          dontShowTitle={true}
          globalSearchFieldName="user"
          globalSearch={"Search"}
          additionalFlag={
            this.state.tabType === "Summary Sheet"
              ? this.state.additionalQuery
              : ""
          }
          type={"Names Report"}
          isCustomHeader={true}
          getHeaderforSchedule={this.getHeaderforSchedule}
          isTabChanged={this.isTabChanged}
          ApaTypeArray={this.state.ApaTypeArray ? this.state.ApaTypeArray : []}
          displayright={true}
          activeTab={"Summary Sheet"}
          isSelectMode={this.state.tabType === "Deal Mgmt" ? "multiple" : ""}
          dataKey={this.state.tabType === "Deal Mgmt" ? "DealID" : "id"} //for data key on selection
          isSql={this.state.tabType === "Deal Mgmt" ? "yes" : "no"}
          tabType={this.state.readOnly ? true : false}
          tabOptions={[
            "Summary Sheet",
            "New Names",
            "Issue Name Updates",
            "Legacy Issue Name Updates",
          ]}
          sortField={this.state.sortField}
          routeTo="sellers"
          displayViewOfForm="modal"
          apiResponseKey={this.state.responseKey}
          className={true}
          apiUrl={this.state.apiUrl}
          entityType="employee"
        />
      </span>
    );
  }
}
