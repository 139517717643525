import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import TableModal from '../../CommonDataTable/TableModal';

import store from '../../../App/store';
//Loader
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';
import NewClaim from '../../../Cruds/CommonModals/ClaimCreationfromPE/NewClaim'
import ExistingClaim from '../../../Cruds/CommonModals/ClaimCreationfromPE/ExistingClaim'
import MatchPropertyModal from '../../../Cruds/CommonModals/MatchPropertyModal';
import configMessages from '../../../../config/configMessages';
import BifurcationPopUpModal from '../../../Cruds/PropertyEditorV2/Bifurcation/BifurcationPopUpModal';
import BifurcationInputModal from '../../../Cruds/PropertyEditorV2/Bifurcation/BifurcationInputModal'

export default class PropertyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property Editor',
      ReasonsArray: [],
      activeTab: "1",
      propertyFilterSortOptions: [
        { label: 'Property Name', value: "propertyName" },
        { label: 'Prop.Address', value: "propertyAddressLine1" },
        { label: 'Business Name', value: "businessName" },
        { label: 'Property Zip Code', value: "propertyZipCode" },
        { label: 'Property City', value: "propertyCity" },
        { label: 'Property State', value: "propertyState" },
      ],
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    this.getDataFromServer()
    await this.getNewStatusForReMap()
    await this.getNewStatusReasonsForEditor()
    await this.getNewStatusForEditor()
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let permissions = localStorage.getItem('rolePermissions')
		permissions=permissions?JSON.parse(permissions)["Property Editor"] :"NoView"
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ screenPermissions: permissions })
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }
  getNewStatusForReMap = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      // { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'availableToReMap', value: true, type: 'eq', add_Filter: true },
      // { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }

    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {

        if (response && response["propertyStatusReasons"].length > 0) {

          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push(reason.status)
          }
          await this.setState({ propertyStatusArrayReMap: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArrayReMap: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
   * get SPStatus based on selectedSeller
   */
  getSPData = () => {
    if (this.state.selectedSeller) {
      let filterCriteria = {}
      filterCriteria['criteria'] = [{ key: 'sellerID', value: this.state.selectedSeller.EstateID, type: 'eq', add_Filter: true }, { key: 'scoringType', value: "RegularScoring", type: 'eq', add_Filter: true }];
      fetchMethodRequest('GET', `NSScoredSeller?filter=${JSON.stringify(filterCriteria)}`)
        .then(async (response) => {
          if (response && response["nsScoredSellers"] && response["nsScoredSellers"].length > 0) {
            let SPStatus = response["nsScoredSellers"][0].status
            this.setState({ SPStatus: SPStatus })
          }
        })
    }
  }

  

  /**
   * 
   * @returns get reasons from propertyStatusandReasonField 
   */
  getNewStatusForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ]

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            let statusIndex = propertyStatusArray.findIndex(obj => obj.label === reason.status)
            if (statusIndex === -1) {
              if (reason.status.startsWith("MATCHING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
                defaultArray.push(reason.status)
              }
              propertyStatusArray.push({ label: reason.status, value: reason.status })
            }
            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusArray: propertyStatusArray, propertyStatusReasonArray: propertyStatusReasonArray })

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  /**
  * 
  * @returns get reasons from propertyStatusandReasonField 
  */
  getNewStatusReasonsForEditor = async (val) => {
    var propertyStatusArray = [], filterCriteria = {}
    var propertyStatusReasonArray = [], defaultArray = []
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ]

    return fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {

            let reasonIndex = propertyStatusReasonArray.findIndex(obj => obj.label === reason.reason)
            if (reasonIndex === -1) {
              propertyStatusReasonArray.push({ label: reason.reason, value: reason.reason })
            }
          }
          await this.setState({ propertyStatusReasonArray: propertyStatusReasonArray, propertyStatusesandReasonsResponse: response["propertyStatusReasons"] })
        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ propertyStatusArray: [], propertyStatusReasonArray: [], propertyStatusesandReasonsResponse: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            propertyStatusesandReasonsResponse: []
          })
        }
      }).catch((err) => {
        return err;
      })
  }
  /**
   * 
   * @param refresh SP Call
   */
  getSPUpdate = async => {
    this.setState({
      isLoading: true,
      blockSP: true
    })
    let url = `seller/updateSimilarProperties?ESTATEID=${this.state.selectedSeller.EstateID}&EstateName='${encodeURIComponent(this.state.selectedSeller.EstateName)}'&ScoringType=RS`
    this.setState({
      flag: true
    })
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        if (response) {
          if (response.respCode && response.respMessage) {
            this.setState({
              flag: false
            })
            // this.datatableref.getErrorMessage(response.respMessage, "", 'success')
            await this.setState({
              data: response.details,
              isLoading: false,
              blockSP: false
            })
          } else if (response.errorMessage) {
            this.datatableref.getErrorMessage(response.errorMessage, "", 'error')
            this.setState({
              isLoading: false,
              blockSP: false
            })
          }
        }
      });

    setTimeout(() => {
      if (this.state.flag) {
        this.datatableref.getErrorMessage("Process Taking Too long, please click GO button after 5 minutes.", 'success')
        this.setState({
          isLoading: false
        })
      }
    }
      , 60000);
  }

  isOpenMatchModel = async () => {
    await this.setState({
      isOpenMatchModel: true
    })

  }
  // }
  isOpenMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: true
    })

  }
  isCloseMatchModel = async (response) => {
    await this.setState({
      isOpenMatchModel: false
    })
    this.datatableref.removeSelectedRows()
    let criteria = await this.datatableref.getFilterCriteria()
    // this.datatableref.getDataFromServer(criteria)
  }
  isCloseMatchCreateModel = async () => {
    await this.setState({
      isOpenMatchCreateModel: false
    })
    this.datatableref.removeSelectedRows()

  }
  getSellerName = async (seller) => {
    await this.setState({
      selectedSeller: seller
    })
    this.getSPData();
  }

  getUpdatedResponseforParent = async (response, groupFlag) => {
    let modifiedData = [];
    if (groupFlag && !groupFlag.includes("ReMap")) {
      let modifiedData = [];
      let field = groupFlag.substring(0, 6).toLowerCase();
      field = field + "ParentPropertyID"
      response.forEach((item, index) => {
        if (item && item[field] === item.propertyID && !item.propertyStatus.includes("MATCHING")) {
          let parentItem = item.propertyID
          let childLength = []
          childLength = response.filter((obj => parentItem === obj[field] && obj.propertyStatus.includes("MATCHING")))
          if (childLength.length >= 1) {
            modifiedData.push(item);
            modifiedData = [...modifiedData, ...childLength]
          }
        }
      });
      return modifiedData;
    } else {
      await this.getNewStatusForReMap()
      var field = ""
      if (groupFlag === "ReMapSPGroup3Flag") {
        field = "reMapGroup3ParentPropertyID";
      } else if (groupFlag === "ReMapSPGroup2Flag") {
        field = "reMapGroup2ParentPropertyID";
      } else if (groupFlag === "ReMapSPGroup1Flag") {
        field = "reMapGroup1ParentPropertyID";
      }
      // field = field + "ParentPropertyID"
      let reMap = this.state.propertyStatusArrayReMap
      response.forEach((item, index) => {
        if (item && item[field] === item.propertyID && !reMap.includes(item.propertyStatus)) {
          let parentItem = item.propertyID
          let childLength = []

          // childLength = response.filter((obj => parentItem === obj[field] && reMap.includes(item.propertyStatus)))
          for (let obj of response) {
            if (parentItem === obj[field] && parentItem != obj.propertyID) {
              childLength.push(obj)
            }
          }
          if (childLength.length >= 1) {
            modifiedData.push(item);
            modifiedData = [...modifiedData, ...childLength]
          }
        }
      });

    }
    return modifiedData;
  }

  /**
   * 
   * @param {String} getErrorMessage 
   */
  getErrorMessage = (getErrorMessage) => {
    if (getErrorMessage.errorMessage || getErrorMessage.title) {
      if (getErrorMessage && getErrorMessage.title) {
        this.datatableref.getErrorMessage(getErrorMessage.title, "", "error")
      }
      if (getErrorMessage && getErrorMessage.errorMessage) {
        this.datatableref.getErrorMessage(getErrorMessage.errorMessage, "", "error")
      }
    } else {
      this.datatableref.getErrorMessage(getErrorMessage.respMessage, "", "success")

    }
  }

  /**
   * 
   * @returns Match Property Modal
   */
  getOpenPropertyMatchModel() {
    return (
      <MatchPropertyModal
        isOpenMatchmodel={this.state.isOpenMatchModel}
        isCloseModal={this.isCloseMatchModel}
        isShowErrorMessage={this.getErrorMessage}
        getTableFields={this.getTableFields}
        selectedOpra={this.state.selectedOpra}
        seller={this.state.selectedSeller}
        businessNames={this.state.businessNames}
        screen={"nsproperties"}
        screenPermissions={"Property Editor"}
      />
    )
  }

  getFileforOptions = async (data) => {
    await this.setState({
      businessNames: data
    })
  }
  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
  }
  getModaltoCreateProperty() {
    return (
      <div className='ml-2'>
        <a className='btn btn-outline-primary p-1 ml-1 mr-1 mt-2 mb-0' title="Click to refresh" style={this.state.SPStatus == "In Progress" || this.state.blockSP ? { backgroundColor: "lightgrey", pointerEvents: "none" } : {}} onClick={() => this.getSPUpdate()} href="#"> Refresh SP</a>
        <a className='btn btn-outline-primary p-1 ml-1 mr-1 mt-2 mb-0' onClick={() => this.isOpenMatchModel()} href="#"> Manually Match</a>
        <a className=' btn btn-outline-primary p-1 ml-1 mr-1 mt-2 mb-0' href={config.opraPropertyCreationUrl} target="blank">Create Property</a>

      </div>
    )
  }
  /**
   * 
   * @returns table fields to show in grid
   */
  getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      {
        "show": false,
        "textAlign": "left",
        "width": 110,
        "field": "bucket",
        "mobile": true,
        "header": "Bucket",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "group1ParentPropertyID",
        "mobile": true,
        "header": "Group1 Parent Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "group2ParentPropertyID",
        "mobile": true,
        // "filterType": "num",
        "header": "Group2 Parent Prop.ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "group3ParentPropertyID",
        "mobile": true,
        // "filterType": "text",
        "header": "Group3 Parent Prop.ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "reMapGroup1ParentPropertyID",
        "mobile": true,
        "header": "ReMap Group1 Parent Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "reMapGroup2ParentPropertyID",
        "mobile": true,
        // "filterType": "num",
        "header": "ReMap Group2 Parent Prop.ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "reMapGroup3ParentPropertyID",
        "mobile": true,
        // "filterType": "text",
        "header": "ReMap Group3 Parent Prop.ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedTaskID",
      //   "mobile": true,
      //   fieldType: "Array",
      //   "header": "Connected TaskID",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // }, {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 110,
      //   "field": "connectedClaimID",
      //   "mobile": true,
      //   "header": "Connected ClaimId",
      //   "displayInSettings": true,
      //   "filter": true,
      //   "sortable": true,
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "businessName",
        isFuzzy: true,
        "mobile": true,
        "header": "Scored Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "statePropertyId",
        "allowInt": true,
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "St. Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "connectedClaimID",
        "mobile": true,
        // "filterType": "text",
        // "fieldType": "ShowData",
        "header": "OPP Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyID",
        "mobile": true,
        "fieldType": "ShowData",
        "header": "OPP Prop. ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "alternateID",
        // "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Alternate ID",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "addressId",
        "mobile": true,
        "filterType": "num",
        // "fieldType": "ShowData",
        "header": "Address ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameId",
        "filterType": "num",
        "mobile": true,
        // "fieldType": "ShowData",
        "header": "Name ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyName",
        "isFuzzy": true,
        "mobile": true,
        "header": "Reported Owner Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "holderName",
        isFuzzy: true,
        "mobile": true,
        "header": "Holder Name",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyDescription",
        "mobile": true,
        "header": "Property Description",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "businessAddressLine1",
        "mobile": true,
        "header": "Business Add Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        isFuzzy: true,
        "field": "propertyAddressLine1",
        "mobile": true,
        "header": "Prop. Address Line1",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessCity",
        "mobile": true,
        // "fieldType": "Link",
        "header": "Biz City",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "businessState",
        "mobile": true,
        "header": "Biz St",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      }, {
        "show": true,
        "textAlign": "right",
        "width": 60,
        "field": "sourceState",
        "mobile": true,
        "header": "Src. St",
        "filter": true,
        "isFuzzy": true,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "prospectiveStatus",
        "mobile": true,
        "header": "Prospective Status",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
        "isFuzzy": true
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "businessZipCode",
        "mobile": true,
        "header": "Biz Zip",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "createdByUser",
        "mobile": true,
        sorted: true,
        "header": "Created By User",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
        // "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "field": "propertyState",
        "mobile": true,
        "header": "Prop. St",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "propertyCity",
        "mobile": true,
        "isFuzzy": true,

        "header": "Prop. City",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "propertyZipCode",
        "mobile": true,
        "header": "Prop. Zip",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "filterType": "num",
        "field": "scoreDate",
        "mobile": true,
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "header": "Score Dt",
        "filter": false,
        "sortable": true,
        "displayInSettings": true,
      },

      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMinValueAmount",
        "allowInt": true,
        "mobile": true,
        "header": "Min Amt",
        "filterType": "num",
        "fieldType": "formatCurrencywithdec",
        "filter": true,
        "sortable": true,
        "displayInSettings": true,
      },
      {
        "show": true,
        "textAlign": "right",
        "width": 80,
        "field": "propertyMaxValueAmount",
        "allowInt": true,
        "fieldType": "formatCurrencywithdec",
        "filterType": "num",
        "mobile": true,
        "header": "Max Amt",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "propertyDataSource",
        isFuzzy: true,
        "mobile": true,
        // "fieldType": "Percentage",
        "header": " Prop. Source",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameIssueCode",
        "mobile": true,
        // "filterType": "num",
        "fieldType": "nameIssue",
        "header": "Legacy Name Iss",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "newNameIssueCode",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "New Name Iss",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "nameSource",
        "mobile": true,
        // "filterType": "num",
        // "fieldType": "Percentage",
        "header": "Name Src",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
     
      {
        "show": true,
        "textAlign": "left",
        "width": 220,
        "field": "propertyStatus",
        "filterType": "multiSelect",
        isFuzzy: true,
        "mobile": true,
        "header": "Prop. Status",
        "filterOptions": this.state.propertyStatusArray ? this.state.propertyStatusArray : [],
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 215,
        "field": "propertyStatusReason",
        "mobile": true,
        "filterType": "multiSelect",
        "filterOptions": this.state.propertyStatusReasonArray ? this.state.propertyStatusReasonArray : [],
        isFuzzy: true,
        // "fieldType": "Link",
        "header": "Prop. Reason",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "fileFor",
        "mobile": true,
        "header": "Mapped Name",
        // "isFuzzy": true,
        "filter": true,
        "displayInSettings": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "relevantSellerName",
        "mobile": true,
        "header": "Relevant Seller Name",
        // "isFuzzy": true,
        "filter": true,
        "displayInSettings": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 360,
        "field": "matchStatusNotes",
        "mobile": true,
        "fieldType": "Array",
        "filter": true,
        "openNotes": true,
        // "isFuzzy": true,
        "header": "Mapping Note",
        "displayInSettings": true,
        // "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "claimID",
        "mobile": true,
        // "fieldType": "Link",
        isFuzzy: true,
        "header": "St.Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyAlternateId",
        "filterType": "num",
        "mobile": true,
        "header": "Prop. Alt Id",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "lastUpdatedBy",
        "mobile": true,
        "isFuzzy": true,
        "header": "Last Updated By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedBy",
        "mobile": true,
        "header": "Validated By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "filedBy",
        "mobile": true,
        "header": "Filed By",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "validatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Validated",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "createdDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Created",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateIndexedInElastic",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Indexed In Elastic",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "updatedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Updated in OPRA2",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "dateOfLastContact",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Of Last Contact",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "nameMatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Name Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "opraAddedDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Opra Added",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "escheatmentDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Dt Escheatment",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "addressLine1MatchScore",
        "allowInt": true,
        "filterType": "num",
        "mobile": true,
        "header": "Line1 Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "allowInt": true,
        "filterType": "num",
        "field": "propertyResidentialFlag",
        "mobile": true,
        "header": "Prop. Residential Flag",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "num",
        "allowInt": true,
        "field": "cityStateZipScore",
        "mobile": true,
        "header": "CSZ Score",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "propertyCoOwnerNames",
        // "allowInt": true,
        // "filterType": "num",
        sorted: true,
        "mobile": true,
        "header": "Co Owner Names",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "hasPropertyCoOwner",
        "fieldType": "hasPropertyCoOwner",
        "mobile": true,
        "header": "Co-Owner",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "confirmedOnsite",
        "fieldType": "confirmedOnSite",
        "mobile": true,
        "header": "Confirmed Onsite",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Confirmed Onsite Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "confirmedOnsiteBy",
        // "allowInt": true,
        // "filterType": "num",
        "mobile": true,
        "header": "Confirmed Onsite By",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "nameProof",
        "mobile": true,
        "header": "Name Proof",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "addressProof",
        "mobile": true,
        "header": "Address Proof",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "feinProof",
        "mobile": true,
        "header": "Fein Proof",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,

        "field": "holderProof",
        "mobile": true,
        "header": "Holder Proof",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "sellerProof",
        "mobile": true,
        "header": "Seller Proof",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "pendingConfirmation",
        "editField": "dropDown",
        options: [{ label: "Confirmed", value: "Confirmed" }, { label: "Missing", value: "Missing" }],
        "mobile": true,
        "header": "Pending Confirmation",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        // "allowInt": true,
        "field": "reportedMissing",
        "fieldType": "reportedMissing",
        "mobile": true,
        "header": "Reported Missing",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingBy",
        "mobile": true,
        "header": "reported Missing By",
        "displayInSettings": true,
        // "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "reportedMissingDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Reported Missing Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "field": "scoreUpdateDate",
        "filterType": "num",
        "fieldType": "Date",
        "dateFormat": 'MM-DD-yyyy',
        "mobile": true,
        "header": "Score Update Date",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        "fieldType": "Boolean",
        "field": "reducedThresholdProdValidatedPropertyFoundFlag",
        "mobile": true,
        "header": "Layer 1",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        "fieldType": "Boolean",
        "field": "addressNamesLayerFlag",
        "mobile": true,
        "header": "Layer 2",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        "fieldType": "Boolean",
        "field": "addressLayer3PositivelyMappedAddresses",
        "mobile": true,
        "header": "Layer 3",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      }, {
        "show": true,
        "textAlign": "left",
        "width": 115,
        "filterType": "dropdown",
        "fieldType": "Boolean",
        "field": "addressLayer4PositivelyMappedAddresses",
        "mobile": true,
        "header": "Layer 4",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "previousOppClaimID",
        "mobile": true,

        "header": "previous OPP Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
    },{
        "show": true,
        "textAlign": "left",
        "width": 110,
        "field": "previousStateClaimID",
        "mobile": true,

        "header": "Previous St. Claim ID",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
    },
    ]
    return data;
  };

  getFormFields = () => {

    return
  }

  submit = async (item) => {

  }

  //Get THreshold Values from SQL
  getDataFromServer = async () => {
    fetch('GET', `ruleConfiguration`)
      .then(async (response) => {
        if (response && response.ruleConfigurations) {
          await this.setState({
            RulesListArray: response.ruleConfigurations,
          });
          let labels = response.ruleConfigurations, modifiedLabels = []
          for (let i = 0; i < labels.length; i++) {
            modifiedLabels.push({
              label: labels[i].name,
              value: { flagName: labels[i].flagName, name: labels[i].name, description: labels[i].description },
            })
          }
          localStorage.setItem('ruleGroups', JSON.stringify(modifiedLabels))

        } else if (response && response.errorMessage && (response.errorMessage === config.tokenMessage || response.errorMessage === config.sessionExpired)) {
          await this.setState({
            sessionExpiryModal: true
          })
          localStorage.setItem('ruleGroups', JSON.stringify([]))
        } else if (response && response.errorMessage && response.errorMessage === config.warningMessage) {
          await this.setState({
            sessionWarningModal: true
          })
        }
        this.isShowLoader(false)
      }).catch((err) => {
        return err;
      });
  }
  /**
   * 
   * @returns Existing Claim Component calling
   */
  getExistingClaimfromPE = () => {
    return (
      <ExistingClaim
        isOpenMatchmodel={this.state.isExistingMatchClaim}
        isCloseModal={this.isCloseExistingMatchClaim}
        getTableFields={this.getTableFields}
        screenPermissions={"Property Editor"}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }


  getColorbasedonProp = (item) => {
    if (item.connectedClaimID && item.connectedClaimID.length > 0) {
      return "#72261a"
    } else if (item.connectedTaskID && item.connectedTaskID.length > 0) {
      return "#354f6e"
    }
  }

  getDefaultArray = async () => {
    await this.getNewStatusReasonsForEditor()
    return this.state.defaultstatusArray
  }

  /**
   * 
   * @returns New Claim Component calling
   */
  getNewClaimfromPE = () => {
    return (
      <NewClaim
        isOpenMatchmodel={this.state.isNewClaimCreation}
        isCloseModal={this.isCloseExistingMatchClaim}
        saveClaim={this.saveClaim}
        screenPermissions={this.state.screenPermissions}
        getTableFields={this.getTableFields}
        seller={this.state.selectedSeller}
        selectedRows={this.state.selectedRows}
      />
    )
  }

 /**
   * close the components 
   */
 isCloseExistingMatchClaim = (response) => {
  this.setState({
    isExistingMatchClaim: false,
    isOpenBifurcationPopModal: false,
    isNewClaimCreation: false,
    isDisableActions: false,
    createTaskAdd: "",
    createTask: "",
    isOpenBifurcationInputModal: false
  })
  if (response) {
    this.getErrorMessage(response)
  }
  let criteria = this.datatableref.getFilterCriteria()
  this.datatableref.isCloseClaimfromPE()
  this.datatableref.getreturnSelectedRows("remove")
  this.datatableref.clearEditorValue()
  this.datatableref.getDataFromServer(criteria)

}


bifurcationConfirmation = async (value, selectedProp) => {
  const ids = selectedProp.map(obj => obj._id);
  const selectedStids = selectedProp.map(obj => obj.statePropertyId);
  if (value.includes("OPP Initiated Claim")) {
    await this.getClaimBifurcation(value, selectedProp, "Opp", "true")
  } else {
    await this.setState({
      bifurcationResponse: [], selectedStids: selectedStids, ids: ids,
      isOpenBifurcationInputModal: true, selectedValue: value,
      selectedProp: selectedProp,
      tableHeader: value.includes("OPP Initiated Claim") ? "OPP Initiated" : "State UP Initiated",
      selectedBgColor: value === "State UP Initiated Claim Merge Bifurcation" ? "#def5c7" : value === "State UP Initiated Claim Bifurcation" ? "#dceaf7" : "#fbe3d6"

    })
  }
}

getClaimBifurcation = async (value, selectedProp, newStateClaimID, isPreview) => {
  await this.datatableref.getisLoading()
  console.log("41796252", value, selectedProp, newStateClaimID, isPreview)
  const ids = selectedProp.map(obj => obj._id);
  const selectedStids = selectedProp.map(obj => obj.statePropertyId);
  console.log(selectedProp[0])
  let body = {
    "selectedIds": ids,
    oppClaimID: selectedProp[0].connectedClaimID,
    "preview": isPreview === "true" ? true : false,
    "stateClaimID": selectedProp[0].claimID,//existing Properties StateClaimID
    "newStateClaimID": newStateClaimID !== "Opp" ? newStateClaimID : ""//User entered State ClaimID
  }
  body.stateUPClaimBifurcation = value === "State UP Initiated Claim Bifurcation" ? true : false
  body.oppClaimBifurcation = value.includes("OPP Initiated Claim") ? true : false
  body.stateInitiatedClaimMerge = value === "State UP Initiated Claim Merge Bifurcation" ? true : false

  await this.setState({ selectedStids: selectedStids })
  return fetchMethodRequest('POST', `${apiCalls.bifurcationModal}`, body)
    .then(async (response) => {
      if (response && response.respCode === 200) {
        await this.setState({
          bifurcationResponse: response, selectedStids: selectedStids, ids: ids,
          newStateClaimID: newStateClaimID,
          isOpenBifurcationPopModal: isPreview === "true" ? true : false, selectedValue: value,
          selectedProp: selectedProp,
          tableHeader: value.includes("OPP Initiated Claim") ? "OPP Initiated" : "State UP Initiated",
          selectedBgColor: value === "State UP Initiated Claim Merge Bifurcation" ? "#def5c7" : value === "State UP Initiated Claim Bifurcation" ? "#dceaf7" : "#fbe3d6"
        })
        await this.datatableref.getisLoading("false")
        this.datatableref.getErrorMessage(response.respMessage, "", 'success')
        if (isPreview !== "true") { this.isCloseExistingMatchClaim() }
        return "yes"
      } else if (response && response.errorMessage) {
        await this.datatableref.getisLoading("false")
        this.datatableref.getErrorMessage(response.errorMessage, "", 'error')
        this.datatableref.isCloseClaimfromPE()
        await this.isOpenBifurcationInputModalRef.isShowErrorMessage(response.errorMessage)

        await this.setState({ bifurcationResponse: [] })
        return "no"

      }
    }).catch((err) => {
      return err;
    })
}

getErrorMessage = async (message, color) => {
  await this.datatableref.getErrorMessage(message, "", color)

}

cancelBifurcationPopModal = async (ID, isPreview) => {
  const { selectedValue, selectedProp } = this.state
  if (ID) {
    let isgetError = await this.getClaimBifurcation(selectedValue, selectedProp, ID, isPreview)
    console.log("isgetError", isgetError)
    if (isgetError !== "no") {
      this.setState({ isOpenBifurcationInputModal: false })
    }
  } else {
    // this.setState({ isOpenBifurcationPopModal: false,isOpenBifurcationInputModal:false })
    this.isCloseExistingMatchClaim()

  }
}

getBifurcationModal = () => {
  return (
    <BifurcationPopUpModal
      onRef={(ref) => this.bifurcationPopModalRef = ref}
      isOpenBifurcationPopModal={this.state.isOpenBifurcationPopModal}
      cancelBifurcationPopModal={this.cancelBifurcationPopModal}
      selectedValue={this.state.selectedValue}
      selectedBgColor={this.state.selectedBgColor}
      selectedProp={this.state.selectedProp}
      tableHeader={this.state.tableHeader}
      ids={this.state.ids}
      newStateClaimID={this.state.newStateClaimID}
      bifurcationResponse={this.state.bifurcationResponse}
      selectedStids={this.state.selectedStids}
      getErrorMessage={this.datatableref.getisLoading}
    />
  )
}

getBifurcationInputModal = () => {
  return (
    <BifurcationInputModal
      onRef={(ref) => this.isOpenBifurcationInputModalRef = ref}
      isOpenBifurcationPopModal={this.state.isOpenBifurcationInputModal}
      cancelBifurcationPopModal={this.cancelBifurcationPopModal}
      selectedValue={this.state.selectedValue}
      selectedBgColor={this.state.selectedBgColor}
      selectedProp={this.state.selectedProp}
      tableHeader={this.state.tableHeader}
      selectedStids={this.state.selectedStids}
      bifurcationResponse={this.state.bifurcationResponse}

    />
  )
}


  /**
   * 
   * @param {Object} seller 
   * @param {Array||Object} selectedRows 
   * @param {String} Claimtype 
   * Get the values from the parent component
   */
  getClaimsTabDetails = (seller, selectedRows, Claimtype) => {
    if (Claimtype === "addToTask") {
      this.setState({
        isExistingMatchClaim: true,
        selectedRows: selectedRows,
        seller: seller
      })
    } else {
      this.setState({
        isNewClaimCreation: true,
        selectedRows: selectedRows,
        seller: seller
      })
    }
  }

  openTableModel = async (item, seller) => {
    await this.setState({
      connectedClaimID: item.connectedClaimID,
      selectedItemforTableShow: item,
      seller: seller,
      isOpenTable: true,
    })
  }



  cancelTable = async () => {
    await this.setState({
      isOpenTable: false
    })
    let criteria = await this.datatableref.getFilterCriteria()
    this.datatableref.clearEditorValue()
    await this.datatableref.getDataFromServer(criteria)
  }

  getTableModal = () => {
    return (
      <TableModal
        onRef={(ref) => this.tableModalRef = ref}
        openTableModal={this.state.isOpenTable}
        cancelReset={this.cancelTable}
        rowData={this.state.selectedItemforTableShow}
        seller={this.state.seller}
        type={"Property Editor"}
        getClaimsTabDetails={this.getClaimsTabDetails}
        openFormModal={this.datatableref.openFormModal}
      />
    )
  }

  //Call for row color change
  custumisedRowHighlights = (item) => {
    let isChildData = this.state.propertyStatusesandReasonsResponse.length>0?this.state.propertyStatusesandReasonsResponse.filter((obj) => obj.availableToReMap === true):[]
    let ispositivelyValidatedStatusReasons = this.state.propertyStatusesandReasonsResponse.length>0?this.state.propertyStatusesandReasonsResponse.filter((obj) => obj.positiveValidation === true):[]
    let isParentProp = ispositivelyValidatedStatusReasons.length>0?ispositivelyValidatedStatusReasons.some(rec => rec.status === item.propertyStatus && rec.reason === item.propertyStatusReason):false;
    let isChildprop = isChildData.length>0?isChildData.some(rec => rec.status === item.propertyStatus && rec.reason === item.propertyStatusReason):false;

    
    if (item) {
      if (item && item.duplicateStateProperty === true) {
        return ({
          'p-isHighlightedDuplicateRow': item.duplicateStateProperty === true
        })
      } else if (item.propertyStatus) {
        return (
          {
            'p-highlighteddarkRow': (isParentProp === true),
            'p-highlightRow': (isChildprop === true),
            'p-disabledRow': (((item.propertyStatus === 'FILING-CLM FILED' || item.propertyStatus === "RESULT-APPROVED")) && this.state.activeTab === "SP")//Done changes
          }
        )
      }
    }
  }


  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        {this.state.isNewClaimCreation ? this.getNewClaimfromPE() : null
        }
        {this.state.isOpenMatchModel ? this.getOpenPropertyMatchModel() : null
        }
        {this.state.isExistingMatchClaim ? this.getExistingClaimfromPE() : null
        }

        {this.state.isOpenTable ? this.getTableModal() : null}
        {this.state.isOpenBifurcationPopModal ? this.getBifurcationModal() : null}
        {this.state.isOpenBifurcationInputModal ? this.getBifurcationInputModal() : null}


        <span>
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            dontShowTitle={true}
            exportRequried={false}
            excelDownload={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            getClaimsDetails={this.getClaimsTabDetails}
            bifurcationConfirmation={this.bifurcationConfirmation}
            frozen={true}
            sortField={"BusinessName, PropertyState, NameMatchScore"}
            deleteRequired={false}
            viewRequired={true}
            settingsRequired={false}
            getFileOptionsforMatching={this.getFileforOptions}
            filterRequired={true}
            gridRequried={false}
            sample={false}
            getModaltoCreateProperty={this.getModaltoCreateProperty()}
            propertyFilterSortOptions={this.state.propertyFilterSortOptions}
            globalSearchFieldName='user'
            globalSearch={''}
            type={"Property Editor"}
            isSelectMode={"multiple"}
            tabDescriptionArray={tabData && tabData.length > 0 ? tabData : []}
            dataKey={"_id"}//for data key on selection
            displayright={true}
            selectedOpra={this.selectedOpra}
            sortDirection={"desc"}
            customisedExport={true}
            icon='faCity'
            resize={true}
            getDefaultStatusArray={this.getDefaultArray}
            scrollHeight={"580px"}
            propertyStatusArray={this.state.propertyStatusArray}
            propertyStatusReasonArray={this.state.propertyStatusReasonArray}
            shownreasons={this.state.shownreasons}
            fontAwesome={true}
            getUpdatedResponseforParent={this.getUpdatedResponseforParent}
            routeTo='nsproperties'
            getSellerName={this.getSellerName}
            displayViewOfForm='modal'
            activeTab={"Green"}
            style={{ marginTop: 5 }}
            apiResponseKey='ns_scored_propertys'
            className={true}
            getColorbasedonProp={this.getColorbasedonProp}
            openTableModel={this.openTableModel}
            // isShownnFieldType={true}
            apiUrl={"nsScoredProperties/PostList"}
            entityType='employee'
            custumisedRowHighlights={this.custumisedRowHighlights}
          />

        </span>
      </div>
    );
  }
}