import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import fetchRequest from '../../../../config/service';
import ReadyToReviewFilters from '../../../ReadyToReviewFilters';
import apiCalls from '../../../../config/apiCalls';
import { customisedRowHighlights } from '../../CommonDataTable/CustomStyling';

// config file
export default class ReadyToReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabType: "",
      type: 'Ready To Review',
      apiUrl: ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    // let DepartmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let DepartmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      DepartmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      await this.setState({ Department: DepartmentArray, departMentIdArray: departMentIdArray })
    }
  }


  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
          // excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          // excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
        });
      }
    })
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }



  //get call with API 
  getBasicCall = (criteria, obj, clear) => {
    if (this.datatableref) {
      this.datatableref.setDataCriteriaforOpportunity(criteria, obj, clear)
    }
  }

  //get criteria from datatable
  getFiltercriteria = () => {
    if (this.datatableref) {
      return this.datatableref.getFilterCriteria()
    }
  }

  onChangeDepartment = (e, type, departmentIdArray) => {
    if (this.datatableref) {
      return this.datatableref.onChangeDepartment(e, 'selectedDepartment', departmentIdArray)

    }
  }


  getTaskFilters = () => {
    return (
      <ReadyToReviewFilters
        getFiltercriteria={this.getFiltercriteria}
        getBasicCall={this.getBasicCall}
        onChangeDepartment={this.onChangeDepartment}

      />
    )
  }

  getFormFields = () => {
    return ([
    ]);
  }

  //settingRedirectedUrl
  setRedirectUrl = async (item, clickedField, postBodyFile, screen) => {
    let url = `${window.location.protocol}//${window.location.host}/${screen}?sellerName=${item["EstateName"] ? item["EstateName"] : item["_id"]}&sellerId=${item.CompanyID}&FileFor=${encodeURIComponent(item.FileFor)}&state=${item.SourceState}&selectedPropertyStatus=MAPPING-READY FOR REVIEW&bucket=All&reportType=ReadyToReview`
    return url
  }


  getTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        //"fieldType": 'text',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 280,
        "field": "FileFor",
        "mobile": false,
        "header": "Mapped Name",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        // "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Prop. In $10K - $50K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "Properties_50k",
        "mobile": false,
        "header": "Prop. > $50K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 130,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      }
    ]
    return data;
  };

  submit = async (item) => {
  }

  render() {
    let tabData = JSON.parse(localStorage.getItem("ruleGroups"))
    return (
      <>

        <span>
          {/* <Loader loader={this.state.isLoading} /> */}
          <DataTables
            onRef={(ref) => (this.datatableref = ref)}
            getTableFields={this.getTableFields}
            formFields={this.getFormFields}
            exportRequried={false}
            excelDownload={false}
            printRequried={false}
            addRequried={false}
            editRequired={true}
            deleteRequired={false}
            viewRequired={true}
            dontShowTitle={true}
            customisedExport={false}
            settingsRequired={false}
            filterRequired={true}
            custumisedRowHighlights={customisedRowHighlights}
            gridRequried={false}
            sample={false}
            gettaskFilters={this.getTaskFilters}
            isClearFilters={true}
            setRedirectUrl={this.setRedirectUrl}
            globalSearchFieldName='user'
            globalSearch={""}
            redirecteddUrl={true}
            type={"Ready To Review"}
            displayright={true}
            onlyCoreopsTeam={true}
            icon='menu-circle'
            sortField={"EstimatedPropertyValue"}
            routeTo='readytoreview'
            displayViewOfForm='modal'
            apiResponseKey={"reportReadyToReview"}
            className={true}
            apiUrl={apiCalls.readyToReviewReport}
            entityType='employee'
          />
        </span>
      </>
    );
  }
}