import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import fetchMethodRequest from '../../../../config/service';
import configMessages from '../../../../config/configMessages';
// config file
export default class RecoveryPlannerV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Recovery Planner V2',
      readOnly: false
    };
    this.getDepartments()
  }

  componentDidMount = async () => {
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions["Recovery Planner V2"] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }

  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()

    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getDepartments = () => {
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          if (response && response.departments) {
            let labels = response.departments;
            let modifiedLabels = [];
            for (let label of labels) {
              modifiedLabels.push({
                label: label.departmentName,
                value: label.departmentName,

              })
            } const sorted = modifiedLabels.sort((a, b) => {
              let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
            await this.setState({
              taskDepartmentItems: sorted
            })
          }
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  getTableFields = () => {

    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 50,
        "field": "dealType",
        "mobile": false,
        "header": "Deal Type",
        // "filterType": "num",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "company",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "taskID",
        "mobile": false,
        "header": "Task Id",

        "filterType": "num",
        "filter": true,
        "fieldType": 'openWindow',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "title",
        "mobile": false,
        "header": "Label",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "purpose",
        "mobile": false,
        "header": "Purpose",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "status",
        "mobile": false,
        "header": "Status",
        "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 70,
        "field": "department",
        "mobile": false,
        "header": "Team",
        "filter": false,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "assignTo",
        "editField": "dropDown",
        // options: [
        "mobile": false,
        "header": "Assign To",
        "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "priority",
        "mobile": false,
        "editField": "dropDown",
        options: [
          { label: "Critical", value: "Critical" },
          { label: "High", value: "High" },
          { label: "Medium", value: "Medium" },
          { label: "Low", value: "Low" }],
        "header": "Priority",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "startDate",
        // "filterType": "StrArray",
        "mobile": false,
        "editField": "date",
        "header": "Start Date",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',

        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "editField": "date",
        "field": "dueDate",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "mobile": false,
        "header": "Due Date",
        "filter": true,
        // "fieldType": 'StrArray',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "plannedHours",
        "editField": "text",
        keyfilter: "pint",
        "filterType": "num",
        "mobile": false,
        "header": "Plan Hrs",
        "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 65,
        "field": "effort",
        "mobile": false,
        "filterType": "num",
        "header": "Actual Hrs",
        "filter": true,
        "fieldType": 'hrsField',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "notes",
        "mobile": false,
        "header": "Notes History",
        openNotes: true,
        "filter": true,
        "fieldType": 'Array',
        "displayInSettings": true,
        // "sortable": true
      },
    ]
    return data;
  };

  getFormFields = () => {

    return
  }



  submit = async (item) => {

  }

  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      data.unshift(
        {
          "show": true,
          "textAlign": "left",
          "width": 43,
          "field": "edit",
          "fieldType": "rowEditor",
          "header": "Edit",
          "filter": false,
          "sortable": false
        },

      )
    }
    return data;
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFieldsbasedonRole}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={true}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          groupField="priority"
          sortField={"priority"}
          settingsRequired={false}
          sortDirection="asc"
          filterRequired={true}
          gridRequried={false}
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Identifier/Label/Purpose/Priority/Claim Id'}
          type='Recovery Planner V2'
          isEdit={this.state.readOnly ? true : false}
          FilterSortOptions={this.state.FilterSortOptions}
          multiApiKey={"allRecords"}
          multiMethod="POST"
          multiApi={`${apiCalls.Tasks}/multi`}
          isClearFilters={true}
          taskDepartmentItems={this.state.taskDepartmentItems}
          displayright={true}
          icon='arrow-right-circle'
          isOnlyEditted={true}
          onlyCoreopsTeam={true}
          routeTo='recoveryPlanningv2'
          displayViewOfForm='modal'
          apiResponseKey='recoveryResults'
          className={true}
          apiUrl={apiCalls.RecoveryPlanningV2}
          entityType='employee'
        />

      </span>
    );
  }
}