import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service';
import store from '../../../App/store';

// config file
export default class AvailableToFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Ready To File',
      ReadyToFileStatus: [],
      OnHoldStatus: [],
      ResourceNeededStatus: [],
      tabType: 'Ready To File',
      apiUrl: `${apiCalls.AvailableFile}`,
      responseKey: "filingPlanningSummary"
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions[this.state.type] === "Edit") {
      await this.setState({
        readOnly: true
      })
    }
    this.getReadyToFile()
    this.onHold()
    this.getSettings()
    this.resourceNeeded()
    this.sellerNegativeNotice()
    this.getCAFields()
  }
  getReadyToFile = async () => {
    let statusReasonArrayReadyFile = []
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "Ready To File", type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
          if (status.reasons && status.reasons.length > 0) {
            statusReasonArrayReadyFile.push(status.reasons)
          }
        }
        this.setState({ ReadyToFileStatus: StatusArray, statusReasonArrayReadyFile: statusReasonArrayReadyFile })
      }
    })
    return this.state.settingsData;
  }

  resourceNeeded = () => {
    let StatusArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "Resource Needed", type: "eq" }];
    fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
        }
        this.setState({ ResourceNeededStatus: StatusArray })
      }
    })
    return this.state.settingsData;
  }
  sellerNegativeNotice = () => {
    let StatusArray = [], statusReasonArraySellerNegative = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "Seller Negative Notice", type: "eq" }];
    fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
          if (status.reasons && status.reasons.length > 0) {
            statusReasonArraySellerNegative.push(status.reasons)
          }
        }
        this.setState({ SellerNegativeNoticeStatus: StatusArray, statusReasonArraySellerNegative: statusReasonArraySellerNegative })
      }
    })
    return this.state.settingsData;
  }
  getCAFields = () => {
    let StatusArray = [], statusReasonArrayCAN = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "CA-N Properties", type: "eq" }];
    fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
          if (status.reasons && status.reasons.length > 0) {
            statusReasonArrayCAN.push(status.reasons)
          }
        }
        this.setState({ CANProperties: StatusArray, statusReasonArrayCAN: statusReasonArrayCAN })
      }
    })
    return this.state.settingsData;
  }
  onHold = () => {
    let StatusArray = [], statusReasonArrayOnHold = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: 'screen', value: "On Hold", type: "eq" }];
    fetchMethodRequest('GET', `${apiCalls.AvailableToFile}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["statusConfigurations"] && response["statusConfigurations"].length > 0) {
        let propertyStatuses = response["statusConfigurations"]
        for (let status of propertyStatuses) {
          StatusArray.push(status.status)
          if (status.reasons && status.reasons.length > 0) {
            statusReasonArrayOnHold.push(status.reasons)
          }
        }

        this.setState({ OnHoldStatus: StatusArray, statusReasonArrayOnHold: statusReasonArrayOnHold })
      }
    })
    return this.state.settingsData;
  }
  getReadyToFileTableFields = () => {
    let data = [
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "FileFor",
        "mobile": false,
        "header": "Mapped Name",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        "filterType": "text",
        "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Properties > $10K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_50k",
        "mobile": false,
        "header": "Properties > $50K",
        // "filter": true,
        "fieldType": 'number',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  getTableFields = (tabType) => {
    if (tabType) {
      let apiUrl = this.state.apiUrl;
      let responseKey = this.state.responseKey;
      if (tabType === 'Ready To File' || tabType === "Seller Negative Notice" || tabType === "CA-N Properties") {
        apiUrl = apiCalls.AvailableFile;
        responseKey = "filingPlanningSummary"
      } else if (tabType === 'On Hold') {
        apiUrl = apiCalls.AvailableFile;
        responseKey = "filingPlanningSummary"
      } else if (tabType === 'Resource Needed') {
        apiUrl = apiCalls.ResourceNeeded;
        responseKey = "results"
      } else {
        apiUrl = apiCalls.AvailableFile;
        responseKey = "filingPlanningSummary"
      }
      this.setState({
        tabType: tabType,
        apiUrl: apiUrl,
        type: tabType,
        responseKey: responseKey
      })
    }

    let data = [];
    if (tabType === 'Ready To File' || tabType === "Seller Negative Notice") {
      data = this.getReadyToFileTableFields();
    } else if (tabType === 'On Hold') {
      data = this.getOnholdTableFields();
    } else if (tabType === "CA-N Properties") {
      data = this.getCANFields();
    }
    else if (tabType === 'Resource Needed') {
      data = this.getResourceNeededTableFields();
    } else {
      data = this.getReadyToFileTableFields();
    }
    return data;
  };
  getCANFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": false,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "HolderName",
        "mobile": false,
        "header": "Holder Name",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        "filter": false,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Properties > $10K",
        "filter": false,
        "fieldType": 'number',
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_50k",
        "mobile": false,
        "fieldType": 'number',
        "header": "Properties > $50K",
        "filter": false,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        "filter": false,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        "filter": false,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        "filter": false,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };


  getOnholdTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": false,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "FileFor",
        "mobile": false,
        "header": "Mapped Name",
        "filterType": "text",
        "filter": false,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "Properties",
        "mobile": false,
        "header": "Properties",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_10k",
        "mobile": false,
        "header": "Properties > $10K",
        // "filter": true,
        "fieldType": 'number',
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Properties_50k",
        "mobile": false,
        "fieldType": 'number',
        "header": "Properties > $50K",
        "filter": false,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KnownValue",
        "mobile": false,
        "header": "Known Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstimatedPropertyValue",
        "mobile": false,
        "header": "Est. Property Value",
        // "filter": true,
        "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };
  getResourceNeededTableFields = () => {
    let data = [

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "EstateName",
        "filterType": "text",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "fieldType": 'redirect',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "field": "SourceState",
        "mobile": false,
        "header": "State",
        // "filter": true,
        // "fieldType": 'Badge',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "ADDR EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "ADDR EVID",
        // "filter": true,
        // "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "APA EXCLSN",
        "mobile": false,
        "header": "APA EXCLSN",
        // "filter": true,
        "fieldType": 'number',
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "APA EXHIBIT REVSN",
        "mobile": false,
        "fieldType": 'number',
        "header": "APA EXHIBIT REVSN",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CASHIER'S CHK",
        "mobile": false,
        "fieldType": 'number',
        "header": "CASHIER'S CHK",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },

      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CHAIN OF TITLE",
        "mobile": false,
        "fieldType": 'number',
        "header": "CHAIN OF TITLE",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "CO-OWNER",
        "mobile": false,
        "fieldType": 'number',
        "header": "CO-OWNER",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "DATE RESTRICTION",
        "mobile": false,
        "fieldType": 'number',
        "header": "DATE RESTRICTION",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "ENTITY SOS STATUS",
        "mobile": false,
        "fieldType": 'number',
        "header": "ENTITY SOS STATUS",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "FEIN EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "FEIN EVID",
        // "filter": true,
        // "fieldType": 'formatCurrency',
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR CLM CERT",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR CLM CERT",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR RLTNSHP EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR RLTNSHP EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "HLDR VERFCTN",
        "mobile": false,
        "fieldType": 'number',
        "header": "HLDR VERFCTN",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "KNWN LOW VALUE",
        "fieldType": 'formatCurrency',
        "mobile": false,
        "header": "KNWN LOW VALUE",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "NOT ON UP SITE",
        "fieldType": 'number',
        "mobile": false,
        "header": "NOT ON UP SITE",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "OWNRSHP EVID",
        "fieldType": 'number',
        "mobile": false,
        "header": "OWNRSHP EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SIGNATORY EVID",
        "mobile": false,
        "fieldType": 'number',
        "header": "SIGNATORY EVID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR CA AFFRM FRM",
        "mobile": false,
        "fieldType": 'number',
        "header": "SLR CA AFFRM FRM",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR SIGNATORY ID",
        "fieldType": 'number',
        "mobile": false,
        "header": "SLR SIGNATORY ID",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SLR UP DECLRTN",
        "mobile": false,
        "fieldType": 'number',
        "header": "SLR UP DECLRTN",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS Enitity Suspension",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS Enitity Suspension",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS SUSP-RPTD OWNR",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS SUSP-RPTD OWNR",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      }, {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SOS SUSP-UPSTRM SLR",
        "mobile": false,
        "fieldType": 'number',
        "header": "SOS SUSP-UPSTRM SLR",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "SPECIAL DOC NEEDED",
        "fieldType": 'number',
        "mobile": false,
        "header": "SPECIAL DOC NEEDED",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 150,
        "field": "Team",
        "filterType": "text",
        "mobile": false,
        "header": "Team",
        // "filter": true,
        "displayInSettings": true,
        "sortable": true
      },

    ]
    return data;
  };

  getFormFields = () => {

    return
  }
  /**
    * get number of rows to show in a table based on user settings.
    * @returns {Number} number of rows
    */
  getrowsPerPage = () => {
    const loginDetails = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : null;
    const limit = (loginDetails && loginDetails.pageLimit) ? parseInt(loginDetails.pageLimit) : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10;
    return limit;
  }

  getSettings = async () => {
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetchMethodRequest('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
          // excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          // excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
        });
      }
    })
  }


  submit = async (item) => {
  }

  render() {

    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={true}
          printRequried={false}
          addRequried={this.state.tabType === "Ready To File" ? true : false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          // filterRequired={true}
          gridRequried={false}
          activeTabNow={this.state.tabType}
          fontAwesome={this.state.tabType === "Ready To File" ? true : false}
          sample={false}
          isClearFilters={true}
          globalSearchFieldName='user'
          // Limit={this.getrowsPerPage()}
          // globalSearch={this.state.tabType === "Ready To File" ? "Seller" : 'Seller'}
          type={this.state.type}
          dontShowTitle={true}
          displayright={true}
          tabCarrying="available"
          settingsData={this.state.settingsData}
          icon={this.state.tabType === "Ready To File" ? "users" : "line-spacing"}
          sortField={this.state.tabType === "Ready To File" || this.state.tabType === "On Hold" || this.state.tabType === "Seller Negative Notice" || this.state.tabType === "CA-N Properties" ? "KnownValue" : ""}
          activeTab={"Ready To File"}
          statusReasonArrayOnHold={this.state.statusReasonArrayOnHold ? this.state.statusReasonArrayOnHold : []}
          statusReasonArrayCAN={this.state.statusReasonArrayCAN ? this.state.statusReasonArrayCAN : []}
          statusReasonArraySellerNegative={this.state.statusReasonArraySellerNegative ? this.state.statusReasonArraySellerNegative : []}
          CANProperties={this.state.CANProperties ? this.state.CANProperties : []}
          ReadyToFileStatus={this.state.ReadyToFileStatus ? this.state.ReadyToFileStatus : []}
          statusReasonArrayReadyFile={this.state.statusReasonArrayReadyFile ? this.state.statusReasonArrayReadyFile : []}
          SellerNegativeNoticeStatus={this.state.SellerNegativeNoticeStatus ? this.state.SellerNegativeNoticeStatus : []}
          OnHoldStatus={this.state.OnHoldStatus ? this.state.OnHoldStatus : []}
          ResourceNeededStatus={this.state.ResourceNeededStatus ? this.state.ResourceNeededStatus : []}
          isSelectMode={this.state.tabType === "Ready To File" ? "multiple" : ""}
          dataKey={this.state.tabType === "On Hold" ? "DealID" : ""}//for data key on selection
          isSql={"yes"}
          tabType={true}
          onlyCoreopsTeam={true}
          tabOptions={["Ready To File", "On Hold", "Seller Negative Notice", "CA-N Properties", "Resource Needed"]}
          routeTo='AvailableToFile'
          screenPermissionsRoute={"Available to File"}
          displayViewOfForm='modal'
          apiResponseKey={this.state.responseKey}
          className={true}
          apiUrl={this.state.apiUrl}
          entityType='employee'
        />

      </span>
    );
  }
}
