import React, { useState, useRef, useEffect } from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import { withTranslation } from 'react-i18next';
import { Button } from "reactstrap"
import fetchMethodRequest from '../../../../config/service';
class RsQueue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: "",
      readOnly: false,
      SelectedForOptions: [{ label: 'In Queue', value: 'In Queue' },
      { label: 'In Progress', value: ["In Progress - Not Started", "In Progress - Started"] },
      { label: 'Finished', value: ["In Queue", "In Progress - Not Started", "In Progress - Started"] }],
      StartorStop: "Start"


    };
  }
  componentDidMount = async () => {
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    if (loginCredentials.rolePermissions["RS Queue"] === "Edit") {
      // setreadOnly(true)
      await this.setState({
        readOnly: true
      })
      this.settingsData();
      this.searchsellerItems();
    }
  }




  searchsellerItems = async (event, criteria) => {
    let apiUrl
    let filterCriteria = this.state.filterCriteria
    if (event) {
      apiUrl = `${apiCalls.Seller}?str=${encodeURIComponent(event.query ? event.query : event)}`
      return fetch('GET', apiUrl)
        .then(async (response) => {
          if (response) {
            let dropdownData = [];
            if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
              dropdownData = response["sellers"]
            }
            else if (response && response.errorMessage) {
              this.props.getErrorResponseFromServer(response)
            }
            if (dropdownData && dropdownData.length === 0) {
              this.setState({
                filteredSuggestions: [],
                noData: true
              });
            } else {
              let dropDopdownValues = dropdownData;
              dropdownData = dropDopdownValues
            }

            await this.setState({
              filteredSuggestions: dropdownData
            });

          }
        }).catch((err) => {
          return err;
        });
    }
  }

  settingsData = async () => {
    let settingsData = "";
    let filterCriteria = {}
    filterCriteria["criteria"] = [{ key: "type", value: "StartOrStopBatch", type: "eq" }]
    fetchMethodRequest('GET', `settings?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0].runBatch
        // this.datatableref.getErrorMessage(response.respMessage, "", "error")
        if (settingsData === "start") {
          await this.setState({ StartorStop: "Stop" })
        } else {
          await this.setState({ StartorStop: "Start" })
        }
        // let criteria = this.datatableref.getFilterCriteria()
        // this.datatableref.getDataFromServer(criteria)
      } else {
        await this.setState({ StartorStop: "Start" })
      }

    })
    return settingsData;

  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        width: 16,
        field: 'reOrder',
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "isBold": "true",
        "field": "sellerName",
        "mobile": false,
        "header": "Deal Name",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "isBold": "true",
        "field": "sellerID",
        "mobile": false,
        "header": "Deal Id",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 100,
        "isBold": "true",
        "field": "priority",
        "mobile": false,
        "header": "Priority",
        "filter": true,
        "displayInSettings": true,
        "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 75,
        "isBold": "true",
        "field": "scoringType",
        "mobile": false,
        "header": "Scoring Type",
        // "filter": true,
        "displayInSettings": true,
        // "sortable": true
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "field": "status",
        "mobile": true,
        "header": "Status",
        "displayInSettings": true,
        "filter": true,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "created",
        "header": "Date Added",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "fieldType": 'Date',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "scheduledTime",
        "fieldType": 'number',
        "header": "Scheduled Date",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY",
        "fieldType": 'Date',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "startTime",
        "fieldType": 'number',
        "header": "Start Time",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "fieldType": 'Date',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "mobile": true,
        "field": "endTime",
        "fieldType": 'number',
        "header": "End Time",
        "filterType": "num",
        "dateFormat": "MM/DD/YYYY hh:mm a",
        "fieldType": 'Date',
        "filter": true,
        "displayInSettings": true,
        "displayInFilter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 60,
        "fieldType": 'Actions',
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };

  getTableFieldsbasedonRole = () => {
    let data = this.getTableFields();
    if (!this.state.readOnly) {
    } else {
      // data.push(
      //   {
      //     "show": true,
      //     "textAlign": "left",
      //     "width": 70,
      //     "fieldType": "rowEditor",
      //     "header": "Edit",
      //     "filter": false,
      //     "sortable": false
      //   },
      // )
    }
    return data;
  }

  getFormFields = () => {
    return [
      {
        value: "",
        type: "autoComplete",
        name: "SellerName",
        label: "Deal",
        // searchApi: apiCalls.Seller,
        className: "inputLabel",
        dealDropDown: true,
        searchField: "SellerNickName",
        id: "SellerName",
        placeholder: "Seller Name",
        required: false,
      },
      // {
      //   value: "",
      //   type: "dropDown",
      //   options: [{ label: "RegularScoring", value: "RegularScoring" }, { label: "DealSizing", value: "DealSizing" }],
      //   name: "ScoringType",
      //   label: "Scoring Type",
      //   id: "ScoringType",
      //   placeholder: "Scoring Type",
      //   required: true,
      // },

    ];
  };

  selectedOption = async () => {
    let flag
    if (this.state.StartorStop === "Start") { flag = "start" } else { flag = "stop" }
    let url = `settings/ScoringBatch?flag=${flag}`;
    return fetchMethodRequest("POST", url, {}).then(async (response) => {
      if (response && response.respMessage) {
        this.datatableref.getErrorMessage(response.respMessage, "", "Success")
        await this.settingsData();
      } else if (response && response.errorMessage) {
        this.datatableref.getErrorMessage(response.errorMessage, "", "error")
        console.log("getting Error Message", response.errorMessage)
      }

    });
    // } else {
    //   console.log("Flag from Settings Call is not getting", setFlag)
    // }

  }


  getHeaderforSchedule = () => {
    return (
      <div className='mt-2'>
        <Button outline
          style={{ padding: "6px 14px" }}
          color="primary"
          onClick={(e) => this.selectedOption(e.value)} >{`${this.state.StartorStop} Scoring Queue`}</Button>
      </div>
    )
  }

  render() {
    return (
      <span>
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFieldsbasedonRole}
          formFields={this.getFormFields}
          criteria={[
            { key: "status", value: "In Queue", type: "eq", add_Filter: true },
            { key: "scoringType", value: ["RegularScoring", "MBD", "singleName"], type: "in" }
          ]}
          sortDirection={"asc"}
          rowspan={true}
          getHeaderforSchedule={this.getHeaderforSchedule}
          sortField={"priority"}
          exportRequried={false}
          printRequried={false}
          // isCustomHeader={true}
          addBatchRequried={true}
          addSingleDealRequried={true}
          editRequired={false}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          statusFilter={true}
          selectedStatusFilter={"In Queue"}
          SelectedForOptions={this.state.SelectedForOptions}
          isClearFilters={true}
          gridRequried={false}
          displayViewOfForm='modal'
          sample={false}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          type='Regular Scoring Queue'
          displayright={true}
          icon='lnr lnr-layers'
          routeTo='rsqueue'
          reorderkey={"priority"}//for reorder
          apiResponseKey={"queues"}
          apiUrl={apiCalls.Queue}
          entityType='employee'
        //edit props
        // isEdit={readOnly ? true : false}
        // multiApi={`${apiCalls.Queue}/multi`}
        // multiMethod="PUT"
        // multiApiKey="selectedRecords"
        />
      </span>
    );
  }
}

export default withTranslation('common')(RsQueue);