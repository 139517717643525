import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service'
// validate
// import validate from './validate';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import configMessages from '../../config/configMessages';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class ReadyToReviewFilters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      States: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      filterCriteria: {},
      SelectedStates: [],
      DealType: [],
      DealTypeV2: ["BK", "CR"],
      isGreen25: true,
      propertyStatusArray: [],
      isExcludeRoyalties: true,
      excludeCoOwners: false,
      isexcludePossiblyPaid: true,
      // excludePossiblyPaid: true,
      ranges: [">$25k"],
      selectedBK: true,
      selectedCR: true
    };
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }
  }


  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.searchsellerItems()
    await this.getDepartments()
    await this.setDefaultTeamOnInitialLoad()
    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
  }
  setDefaultTeamOnInitialLoad = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let departmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      // Check to assign only teams that are  in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departmentIdArray = this.state.taskDepartmentItems.filter(obj => departmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.setState({ Department: departmentArray, departmentIdArray: departmentIdArray })
    }
  }
  getNewStatusForEditor = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }

    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ propertyStatusArray: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }


  onChangeDepartment = async (e) => {
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ Department: DepartmentArray, departmentIdArray: departmentIdArray, Department: e.value, })
    await this.props.onChangeDepartment(e.value, 'selectedDepartment', departmentIdArray)
    // await this.getDataFromServer(this.state.filterCriteria)
  }
  /*
   Fetch all Departments from the api 
   */
  getDepartments = () => {
    let apiUrl;
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id
            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  handleChange = async (e, type, key) => {
    var ranges = this.state.ranges
    var index;
    if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
      })
    } else if (type === "DealType") {
      await this.setState({
        DealTypeV2: e
      })
    } else {
      await this.setState({
        SelectedStates: e
      })
    }
    await this.setState({
      ranges: ranges
    })
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === configMessages.warningMessage || response.errorMessage === configMessages.tokenMessage)) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }


  resetFilters = async () => {
    let filterCriteria = this.props.getFiltercriteria();
    // filterCriteria["criteria"] = []
    await this.setState({
      SelectedStates: [],
      DealType: [],
      DealTypeV2: ["BK", "CR"],
      selectedSeller: "",
      Department: [],
      departmentIdArray: [],
      selectedPropertyStatus: [],
      ranges: [">$25k"],
    })
    let Obj = {
      ranges: this.state.ranges,
      fromSummaries: true
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let departmentArray = [], departmentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      departmentArray = user.secondaryDepartments ? user.secondaryDepartments : []
      departmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      // Check to assign only teams that are  in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departmentIdArray = this.state.taskDepartmentItems.filter(obj => departmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.props.onChangeDepartment(departmentArray, 'selectedDepartment', departmentIdArray)

      // this.submit()
    } else {
      await this.props.onChangeDepartment([], 'selectedDepartment', [])

    }
    this.setState({ Department: departmentArray.length > 0 ? departmentArray : null, departmentIdArray: departmentIdArray.length > 0 ? departmentIdArray : null })

    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      Obj.states = this.state.SelectedStates
    }
    if (this.state.DealType && this.state.DealType.length > 0) {
      Obj.DealType = this.state.DealType
    }
    if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
      Obj.DealType = this.state.DealTypeV2
    }
    this.submit("clear")
  }

  submit = async (clear) => {
    let criteria = await this.props.getFiltercriteria()
    criteria.criteria = criteria && criteria.criteria ? criteria.criteria : []
    criteria.page = 1;
    let Obj = {}
    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      Obj.states = this.state.SelectedStates
    }
    if (this.state.DealTypeV2) {
      Obj.dealType = this.state.DealTypeV2
    }
    if (this.state.Department) {
      Obj.departMentIdArray = this.state.departmentIdArray
    }
    //await this.props.onChangeDepartment(this.state.Department, 'selectedDepartment', this.state.departmentIdArray)
    this.props.getBasicCall(criteria, Obj, clear)
  };

  render() {
    const { handleSubmit } = this.props;
    return (

      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
          <Card className="py-2 ">
            <CardBody className="p-0">
              <form className="form" onSubmit={handleSubmit(this.submit)}>
                <div className='w-100 property_Summary'>
                  <div className='row ml-0 mr-0'>
                    <div className="kanban_filter_field col-lg-3" >
                      <label className="mr-1 kanban_filter_label" style={{ marginTop: 4 }}>
                        Deal:
                      </label>
                      <div>
                        <AutoComplete
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                          // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          className="w-100 d-flex"
                          style={{ width: "100%" }}
                          itemTemplate={this.editorTemplateForDeal}
                          field={"EstateName"}
                          placeholder='Select Deal'
                          dropdown={true}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />

                      </div>
                    </div>
                    <div className='col-lg-2'>
                      <label className='statusReason'>Deal Type</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"dealType"}
                          component={MultiSelect}
                          onChange={(e) => this.handleChange(e.value, "DealType", "in")}
                          type={"dropDown"}
                          filter={true}
                          value={this.state.DealTypeV2}
                          style={{ width: "100%" }}
                          options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                          placeholder={""}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                    <div className='col-lg-2'>
                      <label className="mr-1 kanban_filter_label" id="department" style={{ marginTop: 3 }}>
                        Team:
                      </label>
                      <MultiSelect
                        id="department"
                        className={"form__form-group-field "}
                        style={{ width: "100%", height: 32 }}
                        maxSelectedLabels={1}
                        filter={true}
                        value={this.state.Department}
                        options={this.state.taskDepartmentItems}
                        onChange={(e) => this.onChangeDepartment(e, "Department")}
                        placeholder='Select Team'
                      />
                    </div>



                    <div className='col-lg-2 ml-3'>
                      <label className='statusReason'>State</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          component={MultiSelect}
                          onChange={(e) => this.handleChange(e.value, "States", "in")}
                          type={"dropDown"}
                          filter={true}
                          value={this.state.SelectedStates}
                          style={{ width: "100%" }}
                          options={this.state.States}
                          placeholder={"Select State"}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                    <div className='col-lg-2 d-flex'>
                      <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button>
                      <div className="mt-2" >
                        <span className=" " style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                              width: 16,
                              height: 16,
                              margin: 5,
                              marginTop: 20,
                            }}
                            color="red"
                            onClick={() => this.resetFilters()}
                          />
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </form>

            </CardBody>

          </Card>
        </Col >
      </div >
    );
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ReadyToReviewFilters = reduxForm({
  form: "SummaryForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(ReadyToReviewFilters);

// You have to connect() to any reducers that you wish to connect to yourself
ReadyToReviewFilters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(ReadyToReviewFilters);

export default withTranslation('common')(ReadyToReviewFilters);