import React from 'react';
import { Card, Button, Modal, CardHeader, CardBody, CardFooter } from 'reactstrap';
import dateFormats from '../../../containers/UI/FormatDate/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'primereact/column';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'primereact/datatable';

import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
const bodyStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "left",
  verticalAlign: "inherit"
}
const bodyStyleRight = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: 150,
  fontSize: 12,
  textAlign: "right",
  verticalAlign: "inherit"
}


const headerStyle = {
  width: 150,
  textAlign: "center",
}
export default class MissingMoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      click: true,
      originalname: "Original Name",
      message: "Message",
      filename: "File Name",
      uploadedByUsername: "Uploaded By Username",
      propertyQueueCollection: "Property Queue Collection",
      initialized: "Initialized",
      priority: "Priority",
      status: "Status",
      dateAddedUtc: "Date Added Utc",
      filesParsed: "Files Parsed",
      numberOfPropertiesBeforeDedup: "Number Of Properties Before Dedup",
      numberOfDuplicateProperties: "Number Of Duplicate Properties",
      numberOfPropertiesAfterDedup: "Number Of Properties After Dedup",
      missingMoneyPropertyQueueCollectionName: "Missing Money Property Queue Collection Name",
      fileImportError: "File Import Error",
      fileWithError: "File With Error",
      errorMessage: "Error Message"
    };
  }
  uploadToServer = () => {
    this.setState({ click: true })
    this.fileUploadRef.UploadFileToServer();
  }

  componentDidMount = async () => {
    //Screen permisions value can be edit,view, no view
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    await this.setState({ loginCredentials: loginCredentials })
    await this.getFileQueue()
  }

  getValidation = () => {
    this.setState({ validation: true })
    this.fileUploadRef.clearFile();
  }

  onSelect = (e) => {
    if (!e.target.files[0].name.endsWith(".zip")) {
      this.setState({
        fileName: "",
        fileSize: null,
        uploadedBy: "",
        uploadedDate: "",
        selectedFile: null,
        success: true,
        error: false,
        validation: true
      })
    } else {
      let todaydate = dateFormats.formatDate(
        new Date(),
        "MM/DD/YYYY", "nhg")
      // dataTransfer ? event.dataTransfer.files : event.target.files;
      this.setState({
        fileName: e.target.files[0].name,
        fileSize: this.getSize(e.target.files[0].size),
        uploadedDate: todaydate,
        success: false,
        error: false,
        responseMessage: "",
        selectedFile: e.target.files[0]

      })
      // this.fileUpload.style.display = 'none';
    }
  }

  getSize = (bytes) => {
    if (bytes === 0) {
      return '0 B';
    }

    var k = 1000,
      dm = 3,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  getFileQueue = () => {
    fetchMethodRequest('GET', "fileImportQueue/getAll",)
      .then(async (response) => {
        this.setState({
          FileQueue: response

        })
      }).catch((err) => {
        return err;
      });
  }
  uploadFileDataToServer = () => {
    let missingMoneyObj = {}, errorMessageName = {}
    this.setState({ isLoading: true })
    fetchMethodRequest('POST', "import/uploadMissingMoneyZip", this.state.selectedFile, 'upload')
      .then(async (response) => {
        if (response && response.file) {
          missingMoneyObj = { ...missingMoneyObj, ...response.file, }
          missingMoneyObj.message = response.message
        }
        if (response && response.importResults && response.importResults.fileQueued) {
          missingMoneyObj = { ...missingMoneyObj, ...response.importResults.fileQueued }
          missingMoneyObj.filesParsed = response.importResults.filesParsed
          missingMoneyObj.missingMoneyPropertyQueueCollectionName = response.importResults.missingMoneyPropertyQueueCollectionName
          missingMoneyObj.numberOfDuplicateProperties = response.importResults.numberOfDuplicateProperties
          missingMoneyObj.numberOfPropertiesAfterDedup = response.importResults.numberOfPropertiesAfterDedup
          missingMoneyObj.numberOfPropertiesBeforeDedup = response.importResults.numberOfPropertiesBeforeDedup
        }
        if (response && response.errorMessage) {
          errorMessageName = response
        }
        this.setState({
          fileName: "",
          fileSize: null,
          uploadedBy: "",
          uploadedDate: "",
          selectedFile: null,
          sheets: response,
          success: true,
          error: false,
          response: true,
          isLoading: false,
          missingMoneyObj: missingMoneyObj,
          errorMessageName: errorMessageName
        })
      }).catch((err) => {
        return err;
      });
  }

  clearMessage = () => {
    this.setState({
      success: false,
      error: false,
      responseMessage: "",
      errorMessageName: "",
      missingMoneyObj: null,
      validation: false
    })
    document.getElementById("myFile").value = null
  }

  InvalidCapture = () => {
    console.log("InvalidCapture")
  }

  render() {
    return (
      <Card >
        {/* <CardHeader >
          Missing Money Results File Upload
        </CardHeader> */}
        <Loader loader={this.state.isLoading} />
        <CardBody>
          <div className='row'>
            <div className='col-12 pl-0 pt-2 mb-3' style={{ display: "flex", justifyContent: "center" }}>
              <h4><b>{"Missing Money Results File Upload"}</b></h4>
            </div>
            <div className='col-sm-12' style={{ textAlign: "center" }}>
              <span >
                <Button className="p-fileupload-choose btn btn-primary" style={{ width: "20%" }} disabled={this.state.selectedFile ? true : false} >
                  <input
                    type="file"
                    id="myFile"
                    onChange={(e) => this.onSelect(e)}
                    accept={"zip/*"}
                    onInvalidCapture={this.InvalidCapture}
                    onInvalid={this.InvalidCapture}
                    onClick={this.clearMessage} />
                  Select Zip File
                </Button>
              </span >
              <FontAwesomeIcon icon={faArrowRight}
                className='pl-1 ml-2'
                color='black'
                data-toggle="tool-tip"
                // title="remove field"
                style={{ height: 20, width: 20 }}
              // onClick={() => this.closeOrderModal()}
              />
              {/* {this.state.fileName ?
                <h5 >{this.state.fileName}</h5>
                : ""} */}
              <span >
                <Button
                  style={{ width: "20%", height: 34, }}
                  color="primary"
                  className="import_upload ml-2 mt-1 mb-4 mt-1"
                  onClick={() => this.uploadFileDataToServer()}
                  disabled={this.state.fileName ? false : true}
                >Upload</Button>
              </span>
              {this.state.fileName ?
                <h5 style={{ fontWeight: "bold" }} >{`File Name: ${this.state.fileName}`}</h5>
                : ""}
              {this.state.missingMoneyObj ?
                <div style={{ textAlign: "left" }}>
                  <div class=" mt-2 " style={{ borderBottom: "2px solid #aaa", fontWeight: "bold" }}> Success :</div>
                  {Object.entries(this.state.missingMoneyObj).map((t, k) =>
                    this.state[t[0]] ? <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "green" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div> : null
                  )}
                  {/* {Object.entries(this.state.responseFile).map((t, k) =>
                    <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "green" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div>)}
                  */}
                </div>
                : ""}
              {this.state.errorMessageName ?
                <div class="mt-2" style={{ textAlign: "left", borderTop: "2px solid #aaa" }}>
                  <div class="mt-2" style={{ borderBottom: "2px solid #aaa", fontWeight: "bold" }}> Errors :</div>
                  {Object.entries(this.state.errorMessageName).map((t, k) =>
                    <div className='row'>
                      <div class="mt-2 col-lg-2 font-weight-bold" >{this.state[t[0]]}</div>
                      <div class="mt-2" >{this.state[t[0]] ? ":" : null}</div>
                      <div class="mt-2 ml-3" style={{ color: "red" }} >{this.state[t[0]] ? t[1] : null}</div>
                    </div>)}

                </div>
                : ""}
            </div>
            <div>
              <DataTable value={this.state.FileQueue ? this.state.FileQueue : []} scrollHeight="1000px" resizableColumns={true} columnResizeMode="expand" className='mt-2'

              >

                <Column field="dateAddedUtc" header="Date" bodyStyle={bodyStyle} headerStyle={{
                  width: 120,
                  textAlign: "center",
                }}
                />
                <Column field="filename" header="File" bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column field="initialized" header="Initialized" bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column field="priority" header="Priority" bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column field="propertyQueueCollection" header="Property Queue Collection" bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column field="status" header="Status" bodyStyle={bodyStyle} headerStyle={headerStyle} />
                <Column field="uploadedByUsername" header="User Name" bodyStyle={bodyStyle} headerStyle={headerStyle} />


              </DataTable>
              {this.state.validation ?
                <i> <h5 >*Please select only Zip file.</h5></i>
                : ""}
            </div>
          </div>

        </CardBody>
      </Card >
    )
  }
};