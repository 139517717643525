import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import { RadioButton } from 'primereact/radiobutton';
import fetchMethodRequest from '../../../../config/service';
// config file
export default class OwnedNamesandAddresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Next Action Date Projection',
      selectOwned: "ALL DEAL"
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = () => {

  }

  //  Table Column Fields
  getTableFields = () => {
    let data = [

      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'entityName',
        url: true,
        "mobile": true,
        header: 'Entity Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,

      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'fileName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'File Name',
        // fieldType: 'fileName',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 50,
        field: 'created',
        "fieldType": "Date",
        "dateFormat": "MM/DD/YY",
        "mobile": true,
        header: 'Created',
        "filterType": "num",
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 80,
        filterType: "downloadBtn",
        field: 'downloadBtn',
        "mobile": true,
        header: '',
        filter: true,
        fieldType: "downloadBtn",
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },

    ]
    return data;
  };

  // Form Input Fields
  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "dropDown",
        "options": this.state.propertyStatusArray,
        "name": "claimStatus",
        "label": "Status",
        "id": "name",
        "placeholder": "Claim Status",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "type": "dropDown",
        "options": this.state.mctSourceArray,
        "name": "source",
        "label": "Source",
        "id": "name",
        "placeholder": "Source",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "number",
        "name": "nextActionDateDaysDefault",
        "label": "Next Action Date Days Default",
        "id": "NextActionDateDaysDefault",
        "placeholder": "Next Action Date Days Default",
        "required": true
      },
      {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "activeIndicator",
        "label": "Active /Inactive Status",
        "id": "active",
        "placeholder": "active",
        // "required": true
      },
    ]);
  }

  /**
   * 
   * @param {String} type 
   */
  onShowPrivateCompanies = async (type) => {
    this.setState({
      selectOwned: type
    })
    if (this.datatableref) {
      let filterCriteria = await this.datatableref.getFilterCriteria()
      let findIndex = filterCriteria.criteria.findIndex(obj => obj.key === "ExportType" && obj.add_Filter === true)
      if (findIndex !== -1)
        filterCriteria["criteria"].splice(findIndex, 1)

      filterCriteria.criteria.push({
        key: "ExportType",
        value: type,
        type: "eq",
        add_Filter: true
      })

      this.datatableref.getDataFromServer(filterCriteria)
    }
  }


  // submit = async (item) => {


  // }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          excelDownload={false}
          printRequried={false}
          criteria={[{ key: "ExportType", value: this.state.selectOwned, type: "eq", add_Filter: true }]}
          sortField={"FileName"}
          addRequried={false}
          editRequired={false}
          isClearFilters={false}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          type='Report List - All Owned Names and Addresses'
          displayright={true}
          icon='user'
          routeTo='reports'
          displayViewOfForm='modal'
          apiResponseKey="ownedNamesAndAddresses"
          apiUrl={apiCalls.OwnedNamesAndAddresses}
          entityType='employee'
        />
      </span>
    );
  }
}