import React from 'react';
import { withRouter } from 'react-router-dom';
import { Panel } from 'primereact/panel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimesCircle, faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Sidebar } from 'primereact/sidebar';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { DateGroup } from './DateGroup';
import '../../../../scss/containers/documentUpload.scss';
import fetchMethodRequest, { getApiInstanceIdFromResponse } from '../../../../config/service';
import Loader from '../../../App/Loader';
import Alerts from './Alerts';
import config from '../../../../config/config';
import stateData from './states.json';
import * as documentsAPIs from '../../../../shared/documentsApis';
import { DocSelectFields } from './DocSelectFields';
import { DocTable } from '../../DocumentUpload/components/DocTable';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';

const NAME_IDENTIFIER = 'BusinessNamesID';
const ADDRESS_IDENTIFIER = 'BusinessLocationID';
const NAME_TYPE = 'Name';
const ADDRESS_TYPE = 'Address';
const CLAIM_FORM_REL = 'CLM FORM';

class DocumentUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relevances: [],
            fileDetails: {
                name: null,
                size: null,
                date: null,
                user: null
            },
            docRelevanceData: {},
            showSideBar: false,
            documentSources: [],
            documentTypes: [],
            documentSourceTypes: [],
            documentTags: [],
            deals: [],
            names: [],
            addresses: [],
            formData: {},
            initialFormData: {},
            editRelevance: null,
            editRelType: null,
            editRelIndex: null,
            editRelId: null,
            editRelField: null,
            editRelValue: null,
            companyIdString: null,
            isLoading: false,
            showUserAlert: false,
            userAlertMessage: null,
            selectedData: [],
            enableSave: false,
            enableUpload: false,
            uploadingStatus: 'none',
            uploadProgress: 0,
            fileName: null,
            finalResponse: { severity: null, message: null },
            stateClaimIds: [],
            selectedState: null,
            selectedStateClaimId: null,
            gettingStateIds: false,
            selectingDeal: null,
            showNoteEditor: false,
            loadingStatClaims: false,
            notes: null,
            noStateClaimsFound: false,
            applyToall: false,
            editRelLength: 0,
            showCopyAllOption: false,
            showDocExist: false,
            existedDocDetails: {},
            fromDocMaintain: false,
            singleSelData: {},
            showFileFound: false,
            showRenameOption: false,
            filenameFoundAgain: false,
            checkingFileName: false,
            alertType: null,
            editType: '',
            sidePanWidth: '1280px',
            stateAttributes: [],
            uploadingDocDetails: [],
            copyValueDetails: {},            
            disbaleGoButton: true,
            selectedCategory: null,
            selDocTypes: [],
            noEvidError: false,
            showConfigError: false,
            opType: 'Add',
            showUserWaring: false,
            warningMessage: '',
            callFn: null,
            showNameSelectError: false,
            showFileNameRestrictedCharError:false,
            documentScopeNames:[],
            fileFrequency:{},
            sysGenFileName:''           
        };

        this.allRelevanceTypes = [];
        this.progressInterval = null;
        this.loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials")) : null;
        this.stateList = stateData;
        this.companies = null;
        this.opraId = null;
        this.dealType = null;
        this.selectedFile = null;
        this.mandatoryFields = ['DocumentSource', 'DocumentTag', 'DocumentSourceType', 'FileName'];
        this.dealDetails = null;
        this.origRelevances = [];
        this.relObj = {};
        this.stateAttrObj = {};
        this.docSourceObj = {};
        this.copyValueDetails = {};
        this.categories = [];
        this.editDocData = {};
        this.editId = '';
        this.formDataBefoteEdit = {};
        this.addressCallComplete = false;
        this.fileId = '';
        this.scopeObj = {};
        this.uploadingStatusTimeout = null;        
    }

    componentDidMount() {
        if (document.getElementsByClassName("layout layout--collapse").length === 0) {
            document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
        }
        this.getDropdownValues();
    }

    getDropdownValues = async () => {
        const [sources, sourceTypes, tags] = await Promise.all([documentsAPIs.getSources(), documentsAPIs.getDocSourceTypes(), documentsAPIs.getDocTags()]);
        this.setState({ documentSources: sources, documentSourceTypes: sourceTypes, documentTags: tags });
    }

    /**
     * Getting  reevance seller names related to deal
     * @param {Number} dealID 
     */
    getRelSellerNames = (dealID, nameType) => {
        const type = nameType ? nameType : 'mappedName';
        this.setState({ isLoading: true });
        fetchMethodRequest('GET', `seller/sellerRelevanceNames?estateId=${dealID}&nameType=${type}`).then(async (res) => {
            if (res && res.sellerRelevanceNames && res.sellerRelevanceNames.length > 0) {
                if (type === 'mappedName') {
                    this.setState({ names: res.sellerRelevanceNames });
                } else {
                    this.setState({ relSelnames: res.sellerRelevanceNames });
                }
                if (this.dealDetails && this.dealDetails.businessNameID) {
                    const selData = res.sellerRelevanceNames.filter((elem) => this.dealDetails.businessNameID === elem.BusinessNamesID.toString());
                    if (selData.length > 0) {
                        this.setFormValues('BusinessNameID', selData[0]);
                    }
                }
            } else {
                this.setState({ names: [], isLoading: false });
            }
            this.setState({ isLoading: false });
        });
    }

    /**
     * Get companies data based on selected deal.
     * @param {Object} deal 
     */
    getCompanyData = async (deal) => {
        if (deal.DealID) {
            let filter = { "criteria": [{ "key": "DealID", "value": deal.DealID, "type": "eq" }], "sortfield": "created", "direction": "desc" }
            const res = await fetchMethodRequest('GET', `businessNames/companies?filter=${JSON.stringify(filter)}`)
            if (res && res.companies && res.companies.length > 0) {
                let str = null;
                res.companies.forEach((company) => {
                    if (company && company.CompanyID)
                        if (str) {
                            str = str + `,${company.CompanyID}`;
                        } else {
                            str = company.CompanyID;
                        }
                });
                return Promise.resolve(str);                
            } else {
                this.setState({ userAlertMessage: 'Records not found', isLoading: false });
                return Promise.resolve('');
            }
           

        }
    }


    /**
     * Get address list beased on the companies date when user select deal.
     * @param {Object} event 
     */
    getAddresses = async (event,companyIdString) => {
        let filter = { "limit": 20, "page": 1, "criteria": [], "sortfield": "CompanyID", "direction": "asc" };
        if (event && event.query) {
            filter.criteria.push({ "key": "EntityName", "value": event.query, "type": "regexOr" })
        }
        const res = await fetchMethodRequest('GET', `businessnames/addresses?filter=${JSON.stringify(filter)}&str=${companyIdString}&type=exportToCsv`);
        if (res && res.addresses && res.addresses.length > 0) {            
            let data = [];
            let selAddressIds = [];
            let selAddresses = [];
            let edit = this.editDocData && this.editDocData.documentRelevances && this.editDocData.documentRelevances.length > 0 ? true : false;
            if(edit){ // if edit pushing selected addresses ids (businessLocationId) into an arry
                for(let i=0;i<this.editDocData.documentRelevances.length;i++){
                    if(this.editDocData.documentRelevances[i].businessLocationId){
                        selAddressIds.push(this.editDocData.documentRelevances[i].businessLocationId);
                    }
                }               
            }           
            for (let i = 0; i < res.addresses.length; i++) {
                let obj = res.addresses[i];
                obj.CombinedName = `${res.addresses[i].Line1}, ${res.addresses[i].City}, ${res.addresses[i].StateProvince}`;
                if (selAddressIds.length > 0) {
                    const indx = selAddressIds.findIndex((e) => e === res.addresses[i].BusinessLocationID);
                    if (indx > -1) {
                        selAddresses.push(obj); // For showing selected addresses on top
                        selAddressIds.splice(indx,1);
                    } else {
                        data.push(obj);
                    }
                } else {
                    data.push(obj);
                }               
                
            }
            return Promise.resolve([...selAddresses,...data]);           
        } else {
            this.setState({ userAlertMessage: 'Records not found', isLoading: false });
            return Promise.resolve([]);            
        }
    }

    /**
     * On user select file from local. Creating data about selected file to display.
     * @param {Event} e 
     */
    onFileSelect = (e) => {
        if (e.target.files[0]) {
            let file = {};
            file.name = e.target.files[0].name;
            if (e.target.files[0].size) {
                if (e.target.files[0].size > 1024 * 1024) {
                    file.size = Math.ceil(e.target.files[0].size / (1024 * 1024)) + ' MB';
                } else {
                    file.size = Math.ceil(e.target.files[0].size / 1024) + ' KB';
                }
            }
            const date = new Date();
            file.date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
            file.user = (this.loginCredentials && this.loginCredentials.displayName) ? this.loginCredentials.displayName : null;
            this.setState({ fileDetails: file });
            this.selectedFile = e.target.files[0];
            this.setFormValues('FileName', file.name);
        } else {
            this.setState({
                fileDetails: {
                    name: null,
                    size: null,
                    date: null,
                    user: null
                }
            });
            this.selectedFile = null;
        }
        this.checkValidation();
    }

    /**
     * Clear selected file & data related to file when user click on close (close icon showing in file data row)
     */
    clearFile = () => {
        if (document.getElementById('doc-uploader')) {
            document.getElementById('doc-uploader').value = null;
        }
        this.setState({
            fileDetails: {
                name: null,
                size: null,
                date: null,
                user: null
            }
        });
        this.selectedFile = null;
        this.checkValidation();
    }

    /**
     * Checking validation to enable/disable Save and upload buttons.
     */
    checkValidation = () => {
        let validated = true;

        this.mandatoryFields.forEach(field => {
            if (!this.state.formData[field]) {
                validated = false;
            }
        });
        if (validated) {
            this.setState({ enableSave: true });
        } else {
            this.setState({ enableSave: false });
        }
    }

    /**
     * Setting user selected values into state
     * @param {String} field 
     * @param {Object} value 
     */
    setFormValues = async (field, value) => {
        let formData = this.state.formData;
        if (field === 'deal') {
            if (value) {
                this.opraId = value && value.DealID ? value.DealID : null;
                this.dealType = value && value.DealType ? value.DealType : null;
            }
            //Clearing all data when deal changed or removed
            if (!value || (formData.deal && formData.deal.DealID !== value.DealID)) {
                await this.setState({ category: null, docRelevanceData: {}, fileName: null, editRelId: null, editRelevance: null, relevances: this.allRelevanceTypes });
                formData = {};
                formData[field] = value;
                if (document.getElementById('doc-name')) {
                    document.getElementById('doc-name').value = null;
                }
            }
        } else if (field === 'BusinessNameID') {
            await this.setState({ selectedData: [value], singleSelData: value });
        }
        formData[field] = value;
        await this.setState({ formData: formData });       
        this.checkValidation();
    }  

    /**
     * For dynamically getting the panel width based on the drop down options length
     * @param {String} relType Addresses or Name
     * @returns Width of the side panel
     */
    getPanelWidth = (relType) => {
        let maxWidth = 0;
        let multi = 8;
        if (relType === ADDRESS_TYPE) {
            this.state.addresses.forEach(e => {
                if (e.label && maxWidth < e.label.length) {
                    maxWidth = e.label.length;
                }
            });
            multi = 10;

        } else {
            this.state.names.forEach(e => {
                if (e.CombinedName && maxWidth < e.CombinedName.length) {
                    maxWidth = e.CombinedName.length;
                }
            });
        }

        const maxPanelSize = Math.ceil((window.innerWidth * 80) / 100);
        let panelWidth = (maxWidth * multi) > maxPanelSize ? maxPanelSize : (maxWidth * multi);
        panelWidth = panelWidth > 1280 ? panelWidth : 1280;
        return panelWidth;
    }


    /**
     * Setting user input values in relevance into state.
     * @param {String} type 
     * @param {String} relevance 
     * @param {Number} index 
     * @param {String} value 
     * @param {String} field 
     */
    updateUserInputRelevance = (type, relevance, index, value, field) => {
        let relData = this.state.docRelevanceData;
        if (this.state.applyToall) {
            relData[type][relevance] = relData[type][relevance].map((elem) => {
                elem[field] = value;
                return elem;
            })
        } else {
            relData[type][relevance][index][field] = value;
        }

        this.setState({ docRelevanceData: relData, showNoteEditor: false, showCopyAllOption: false, applyToall: false });
    }


    /**
     * Add or remove file extention (.pdf)
     * @param {String} value 
     * @param {String} todo 
     */
    toggleFileExtension = (value, todo) => {
        if (this.state.formData.FileName) {
            value = value.trim();
            if (todo === 'add' && value.slice(-4) !== '.pdf') {
                value = value + '.pdf';
            } else if (todo === 'remove' && value.slice(-4) === '.pdf') {
                value = value.slice(0, -4);
            }
            let formData = this.state.formData;
            formData['FileName'] = value;
            this.setState({ formData: formData, fileName: formData['FileName'] });
        }
    }

    /**
     * Show notes dialog when focus on notes field 
     * @param {String} type 
     * @param {String} relevance 
     * @param {Number} index 
     * @param {Number} value 
     */
    onFocusNote = (typeIndex, nameIndex, field, value) => {
        let docDetails = this.state.uploadingDocDetails;
        const details = {
            typeIndex: typeIndex,
            nameIndex: nameIndex,
            field: field,
            value: value,
            count: docDetails[typeIndex].selNamesData.length,
            type: docDetails[typeIndex].docTypeName
        }
        this.setState({
            notes: value,
            copyValueDetails: details,
            applyToall: false,
            showNoteEditor: true
        });
    }

    /**
     * Check whether atleast one relevance selected or not. If relevence selected show alert.
     */
    checkNamesSelected = async () => {       
        let namesSelected = true;
        this.state.uploadingDocDetails.forEach((cat) => {
            if ((!cat.selNames || (cat.selNames && cat.selNames.length === 0)) && cat.scope && (cat.scope.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME) || cat.scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER) || cat.scope.includes(documentsAPIs.DOC_SCOPE_ADDRESS))) {
                namesSelected = false
            }
        })
        if (namesSelected) {
            this.setState({ showNameSelectError: false });
            this.checkDocumentExist();
        } else {
            this.setState({ showNameSelectError: true });
        }
    }

    /**
     * Check if document already exist with same deal Id and file name.
     */
    checkDocumentExist = async (checkingAgain) => {
        if (this.state.formData.FileName && documentsAPIs.checkFileNameForRestrictedChar(this.state.formData.FileName)) {
            this.setState({ showFileNameRestrictedCharError: true });
            return;
        }
        this.setState({ showFileNameRestrictedCharError: false });
        await this.setState({ showUserAlert: false, uploadProgress: 1, checkingFileName: (checkingAgain ? true : false) });
        let relTypes = [];
        let docTypes = [];
        const relList = this.getRelList();
        relList.forEach((e) => {
            if (e.RelevanceTypeName) {
                relTypes.push(e.RelevanceTypeName);
            }
            if (e.DocumentTypeName) {
                docTypes.push(e.DocumentTypeName)
            }
        })
        const data = {
            DealId: this.state.formData.deal.DealID,
            FileName: encodeURIComponent((JSON.parse(JSON.stringify(this.state.formData.FileName))).replaceAll(" ", "_")),
            RelevanceType: [...new Set(relTypes)].join(','),
            DocumentTypeName: [...new Set(docTypes)].join(',')
        }
        if (this.state.opType === 'edit' && this.editDocData.fileName === data.FileName) {
            this.prepareData();
            return;
        }
        fetchMethodRequest('POST', `DocumentCheck`, data).then(async (res) => {
            if (res && res.respCode && res.respCode === 200 && res.outResponse && res.outResponse.details && res.outResponse.details.length > 0) {
                if (checkingAgain) {
                    await this.setState({ filenameFoundAgain: true, checkingFileName: false });
                }
                await this.setState({ isLoading: false, showFileFound: true });
            } else if (res && res.errorMessage && res.errorMessage.includes('Unable to Find Document')) {
                this.setState({ showFileFound: false, showRenameOption: false, filenameFoundAgain: false, checkingFileName: false, uploadingStatus: 'inProgress', isLoading: false });
                this.prepareData();
            }
        });
    }

    /**
     * Prepare date for api call
     */
    prepareData = () => {        
        let data = this.state.opType === 'edit' ? { DocumentNumber: this.editDocData.documentNumber, OrginalFileName: this.editDocData.orginalFileName, id: this.editDocData.id } : {};
        data['BusinessNameID'] = this.state.formData.selRelSellerName && this.state.formData.selRelSellerName.BusinessNamesID ? this.state.formData.selRelSellerName.BusinessNamesID : null;
        data['MappedNameID'] = this.state.formData.selMappedName && this.state.formData.selMappedName.BusinessNamesID ? this.state.formData.selMappedName.BusinessNamesID : null;
        // data['DocumentTypeId'] = this.state.formData.DocumentTypeId && this.state.formData.DocumentTypeId.Id ? this.state.formData.DocumentTypeId.Id : null;
        data['DealId'] = this.state.formData.deal && this.state.formData.deal.DealID ? this.state.formData.deal.DealID : null;
        data['DealType'] = this.state.formData.deal && this.state.formData.deal.DealType ? this.state.formData.deal.DealType : null;
        // data['DocumentSource'] = this.state.formData.DocumentSource && this.state.formData.DocumentSource.DocDataSourceName ? this.state.formData.DocumentSource.DocDataSourceName : null;
        data['FileName'] = this.state.formData.FileName;
        data['DocumentCategoryID'] = this.state.selectedCategory && this.state.selectedCategory._id ? this.state.selectedCategory._id : null;
        data['DocumentDate'] = this.state.formData.DocumentDate;
        data['DocFilingDateYear'] = this.state.formData.DocFilingDateYear;
        data['DocFilingDateMonth'] = this.state.formData.DocFilingDateMonth;
        data['DocFilingDateDay'] = this.state.formData.DocFilingDateDay;
        data['DocState'] = this.state.formData.selState && this.state.formData.selState.shortCode ? this.state.formData.selState.shortCode : '';
        data['DocStateClaimID'] = this.state.formData.selStateClaimId && this.state.formData.selStateClaimId.stateClaimID ? this.state.formData.selStateClaimId.stateClaimID : '';
        data['DocOPPClaimID'] = this.state.formData.selStateClaimId && this.state.formData.selStateClaimId.oppClaimID ? this.state.formData.selStateClaimId.oppClaimID : '';
        data['DocumentScreenshotOrActualPDF'] = this.state.formData.DocumentScreenshotOrActualPDF;
        data['DocumentRelevances'] = this.getRelList();
        data['SharepointLocation'] = this.state.formData.deal && this.state.formData.deal.SellerNickName ? this.state.formData.deal.SellerNickName : null;
        if (this.state.fileDetails.name) {
            data['OrginalFileName'] = this.state.fileDetails.name;
        }       
        // Setting id & adding methods to relevance data in case of edit
        if (this.state.fromDocMaintain && !this.state.fileDetails.name) {
            data['DocumentRelevance'] = this.getRelWithStatus(this.origRelevances, data['DocumentRelevance']);
            data['id'] = this.dealDetails.id;
        }
        //Showing Progress bar
        this.setState({ uploadingStatus: 'inProgress', isLoading: false, displayMandotaryErrorMsg: false, });
        this.progressInterval = setInterval(() => {
            if (this.state.uploadProgress > 80) {
                clearInterval(this.progressInterval);
            }
            if (this.state.uploadProgress < 80) {
                this.setState((prevState) => ({ uploadProgress: prevState.uploadProgress + 5 }))
            }
        }, 1000);
        if (this.state.opType === 'edit') {
            //Redirecting to update functionality.                               
            this.updateDocumentRecord(data, this.editDocData);
        } else {
            // this.fileUploadToServer(data);
            this.addNewDocument(data);
        }
    }


    addNewDocument = async (data) => {        
        const tData = JSON.parse(JSON.stringify(data));
        tData.FileName = documentsAPIs.formatteFileName(data.FileName,true);
        const response = await documentsAPIs.saveDocumentDetails(tData);       
        if (response && response.outResponse) {
            const docDetails = response.outResponse;
            const documentId = docDetails.documentNumber ? docDetails.documentNumber : '';
            const recordId = docDetails.documentId ? docDetails.documentId : '';
            const fileName = documentsAPIs.formatteFileName(data.FileName);
            const res = await documentsAPIs.uploadDocument(this.selectedFile, fileName, documentId, recordId, this.state.formData.deal);
            if (res && res.respCode && res.respCode === 200) {
                const availDocuments = await documentsAPIs.getAvailableDocuments(this.state.formData, this.state.stateAttributes, this.state.selectedCategory, null, null, "AvailableDocs");
                if (availDocuments && availDocuments.length > 0) {
                    const tableData = documentsAPIs.formatAvailDocumentsData(this.state.stateAttributes, availDocuments);
                    this.setState({ stateAttributes: tableData });
                }
                const response = { severity: 'success', message: this.state.showDocExist ? `We found an existing document with the same name and hence, we added the new document with _v1.pdf as its file name, ${fileName}, successfully saved` : `The document, ${fileName}, successfully saved` };
                this.setProgressResp(response);
                this.clearData();
            } else {
                this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
            }
        } else {
            this.showErrorMessage(response && response.errorMessage ? response.errorMessage : '');
        }
    }

   
 
    /**
     * setting selected relevence data into an array.
     * @returns {Object}
     */
    getSelRelevenceList = () => {
        let selRel = []
        for (const type in this.state.uploadingDocDetails) {
            for (const relevance in this.state.docRelevanceData[type]) {
                selRel = [...selRel, ...this.state.docRelevanceData[type][relevance]];
            }
        }
        return selRel;
    }
    /**
     * Update relevance types with Method ADD or UPDATE or DELETE
     * @param {Array} origRel 
     * @param {Array} updatedRel 
     * @returns {Array} result relevance types arry
     */
    getRelWithStatus = (origRel, updatedRel) => {
        let resArray = [];
        for (let i = 0; i < updatedRel.length; i++) {
            if (updatedRel[i].id) {
                const fIndex = origRel.findIndex((e) => e.id === updatedRel[i].id);
                if (fIndex > -1) {
                    resArray.push({ ...updatedRel[i], Method: 'UPDATE' });
                    origRel.splice(fIndex, 1);
                }
            } else {
                resArray.push({ ...updatedRel[i], Method: 'ADD' })
            }
        }
        origRel.forEach((rel) => {
            resArray.push({ ...rel, Method: 'DELETE' });
        })
        return resArray;
    }

    /**
     * Upload file to web server.
     * @param {Object} data 
     */
    fileUploadToServer = async (data) => {
        let fileName = JSON.parse(JSON.stringify(this.state.formData.FileName));        
        fileName.replace("/", "_")
        const formData = new FormData();
        formData.append("file", this.selectedFile, fileName.slice(0, -4));
        try {
            const resPromise = await fetch(`${config.apiUrl}uploads?uploadPath=claims&type=PDF&extType=pdf`, {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': `Bearer ${this.loginCredentials.accessToken}`
                },
            });

            const res = await resPromise.json();
            if (res && res.fileName) {
                const sanFileName = encodeURIComponent(res.fileName);
                data.FileName = sanFileName;
                this.createDocumentRecord(data, sanFileName);
            } else {
                this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
            }

        } catch (err) {
            this.showErrorMessage();
        }
    }

    /**
     * Crating records in sql db.
     * @param {Object} data 
     * @param {String} fileName 
     */
    createDocumentRecord = (data, fileName) => {
        fetchMethodRequest('POST', `SaveDocuments`, data).then(async (res) => {
            if (res && res.respCode && res.respCode === 200) {
                const documentId = res.outResponse.documentNumber ? res.outResponse.documentNumber : '';
                const recordId = res.outResponse.documentId ? res.outResponse.documentId : '';
                this.uploadPdfDocument(fileName, documentId, recordId);
            } else {
                this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
            }
        });
    }

    /**
     * Uploading file to sharepoint server
     * @param {String} fileName 
     * @param {String} documentId 
     */
    uploadPdfDocument = async (fileName, documentId, recordId) => {
        fetchMethodRequest('POST', `UploadPDFDocument?fileName=${fileName}&DocumentID=${documentId}&recordId=${recordId}&uploadPath=${this.state.formData.deal && this.state.formData.deal.SellerNickName ? encodeURIComponent(this.state.formData.deal.SellerNickName) : ''}&DealID=${this.state.formData.deal.DealID}&DealType=${this.state.formData.deal && this.state.formData.deal.DealType ? this.state.formData.deal.DealType : ''}`).then(async (res) => {
            if (res && res.resultCode && res.resultCode === 200 && res.success) {
                const availDocuments = await documentsAPIs.getAvailableDocuments(this.state.formData, this.state.stateAttributes, this.state.selectedCategory, null, null, "AvailableDocs");
                if (availDocuments && availDocuments.length > 0) {
                    const tableData = documentsAPIs.formatAvailDocumentsData(this.state.stateAttributes, availDocuments);
                    this.setState({ stateAttributes: tableData });
                }
                const response = { severity: 'success', message: this.state.showDocExist ? `We found an existing document with the same name and hence, we added the new document with _v1.pdf as its file name, ${fileName}, successfully saved` : `The document, ${fileName}, successfully saved` };
                this.setProgressResp(response);
                this.clearData();
            } else {
                this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
            }
        });
    }

    setProgressResp = async (response) => {
        await this.setState({ uploadProgress: 100, finalResponse: response, showDocExist: false });
        setTimeout(() => { this.setState({ uploadingStatus: 'done' }); }, 500);
        this.uploadingStatusTimeout = setTimeout(() => {
            this.setState({ uploadingStatus: 'none', showSideBar: false });
        }, 1000 * 5);

    }
    /**
     * Updating document details
     * @param {Object} data 
     * @param {Object} dealData 
     */
    updateDocumentRecord = async (data, dealData) => {
        const res = await documentsAPIs.updateDocumentRecord(data);
        if (res && res.respCode && res.respCode === 200) {
            const FileName = (JSON.parse(JSON.stringify(this.state.formData.FileName))).replaceAll(" ", "_");
            if (this.state.formData.FileName !== dealData.fileName) {
                this.updateFileNameSharepoint(data, dealData);
            } else {
                const availDocuments = await documentsAPIs.getAvailableDocuments(this.state.formData, this.state.stateAttributes, this.state.selectedCategory, null, null, "AvailableDocs");
                if (availDocuments && availDocuments.length > 0) {
                    const tableData = documentsAPIs.formatAvailDocumentsData(this.state.stateAttributes, availDocuments);
                    this.setState({ stateAttributes: tableData });
                }
                const response = { severity: 'success', message: `The document , ${data.FileName}, successfully updated` };
                this.setProgressResp(response);
                this.clearData();
                if (!window.location.href.includes('claimDocumentation')) {
                    window.history.replaceState(null, null, `${window.location.origin}/sellersEdit/documentUpload/${this.state.formData.deal && this.state.formData.deal.DealID ? this.state.formData.deal.DealID : ''}`);
                }
            }
        } else {
            this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
        }

    }

    /**
     * Renaming the file in sharepoint
     * @param {Object} data 
     * @param {Object} dealData 
     */
    updateFileNameSharepoint = (data, dealData) => {
        const docId = dealData && dealData.documentNumber ? dealData.documentNumber : '';
        fetchMethodRequest('POST', `UpdatePDFDocument?FileName=${data.FileName}&recordId=${data.id}&OldFileName=${dealData.fileName}&FileId=${this.fileId}&DocumentId=${docId}`).then(async (res) => {
            if (res && res.resultCode && res.resultCode === 200) {
                const availDocuments = await documentsAPIs.getAvailableDocuments(this.state.formData, this.state.stateAttributes, this.state.selectedCategory, null, null, "AvailableDocs");
                if (availDocuments && availDocuments.length > 0) {
                    const tableData = documentsAPIs.formatAvailDocumentsData(this.state.stateAttributes, availDocuments);
                    this.setState({ stateAttributes: tableData });
                }
                const response = { severity: 'success', message: `The document , ${data.FileName}, successfully updated` };
                this.setProgressResp(response);
                this.clearData();
                if (!window.location.href.includes('claimDocumentation')) {
                    window.history.replaceState(null, null, `${window.location.origin}/sellersEdit/documentUpload/${this.state.formData.deal && this.state.formData.deal.DealID ? this.state.formData.deal.DealID : ''}`);
                }
            } else {
                this.showErrorMessage(res.errorMessage ? res.errorMessage : '');
            }
        });
    }

    /**
     * Show error message
     * @param {String} errorMessage 
     */
    showErrorMessage = (errorMessage) => {
        const response = { severity: 'danger', message: `An error occurred. ${errorMessage ? errorMessage : ''}. Please contact your system administrator` }
        this.setState({ uploadProgress: 0, uploadingStatus: 'done', finalResponse: response });

    }

    /**
     * Clearing all fields
     */
    clearData = async () => {
        let formDataT = {
            BusinessNameID: this.state.formData.BusinessNameID,
            deal: this.state.formData.deal,
            selRelSellerName: this.state.formData.selRelSellerName,
            selMappedName: this.state.formData.selMappedName,
            selState: this.state.formData.selState,
            selStateClaimId: this.state.formData.selStateClaimId
        };
        await this.setState({
            uploadingStatus: 'none',
            formData: formDataT,
            docRelevanceData: {},
            uploadProgress: 0,
            editRelevance: null,
            editRelType: null,
            editRelIndex: null,
            editRelId: null,
            editRelField: null,
            editRelValue: null,
            singleSelData: null,
            fromDocMaintain: null,
            editType: null,
            relevances: this.allRelevanceTypes,
            opType: 'Add',
            uploadingDocDetails:[],
            fileFrequency:documentsAPIs.getFileFrequency()
        });
        this.dealDetails = null;
        this.editDocData = {};
        this.clearFile();
        if (document.getElementById('doc-name')) {
            document.getElementById('doc-name').value = null;
        }
        // window.history.pushState({}, document.title, "/documentUpload");
    }

    /**
     * Change upload status to show progress to user
     */
    changeStatus = () => {
        this.setState({ uploadingStatus: 'none' });
    }



    handleFilingDate = (type, value) => {
        if (type && value) {
            const formValueName = type === 'year' ? 'DocFilingDateYear' : type === 'month' ? 'DocFilingDateMonth' : 'DocFilingDateDay';
            this.setFormValues(formValueName, value);
        }
    }

    handleEventDate = (type, value, otherFields) => {
        if (type && value && otherFields) {
            const field = type === 'year' ? 'EventDateYear' : type === 'month' ? 'EventDateMonth' : 'EventDateDay';
            this.onChangeRelData(otherFields.typeIndex, otherFields.nameIndex, field, value);
            if (this.state.uploadingDocDetails[otherFields.typeIndex] && this.state.uploadingDocDetails[otherFields.typeIndex].selNamesData && this.state.uploadingDocDetails[otherFields.typeIndex].selNamesData.length > 1) {
                this.setState({ showCopyAllOption: true });
            }
        }
    }

    namesDropDownTemplate = (col) => {
        return <span>{col.CombinedName}</span>
    }

    /**
     * On click Go getting the state attributes config & uploaded document details.
     * @param {Object} formData 
     * @param {Object} selCategory 
     * @param {Array} names 
     * @param {Object} rowData 
     */

    getDocumentDetails = async (formData, selCategory, names, rowData) => { 
        this.setState({ isLoading: true, showConfigError: false });
        const stateCode = formData && formData.selState && formData.selState.shortCode ? formData.selState.shortCode : '';
        const dealType = formData && formData.deal && formData.deal.DealType ? formData.deal.DealType : '';
        // const selCatNamme = (selCategory && selCategory.subCategoryName ? selCategory.subCategoryName : '');
        const selCatNames = selCategory.map((e) => e.subCategoryName)
        const stateAttributes = await documentsAPIs.getStateAttributes(dealType, stateCode, selCatNames); // getting records from state attributes
        this.scopeObj = {}
        selCategory.forEach((e) => {
            this.scopeObj[e.subCategoryName] = e.documentScopeName;
        });
        //Getting document type multiselect options data
        const documentTypes = stateAttributes.map((e) => { return { name: e.DocumentTypeName, scope: this.scopeObj[e.SubCategory], subCategory:e.SubCategory } });
        const docTypes = stateAttributes.map((e) => e.DocumentTypeName);
        let addresses = [];
        //If selected document type MAPPED_NAME_ADDRESS_EVID - getting the addresses for that deal
        if (docTypes.includes(documentsAPIs.MAPPED_NAME_ADDRESS_EVID)) {            
            const companyIdString = await this.getCompanyData(formData.deal);            
            addresses = await this.getAddresses(null,companyIdString);            
        }
        let tableData = stateAttributes;
        let showConfigError = true;
        if (stateAttributes && stateAttributes.length > 0) {
            showConfigError = false;
            const availDocuments = await documentsAPIs.getAvailableDocuments(formData, stateAttributes, selCategory, null, null, "AvailableDocs"); // getting uploaded document list
            if (availDocuments && availDocuments.length > 0) {
                // Combining data from state attributes & uploaded documents.
                tableData = documentsAPIs.formatAvailDocumentsData(stateAttributes, availDocuments);
            }

        }       
       
        await this.setState({ stateAttributes: tableData,fileFrequency:documentsAPIs.getFileFrequency(), showConfigError: showConfigError, formData: formData, names: names, addresses: addresses, selectedCategory: selCategory, documentTypes: documentTypes.filter((e) => e.subCategory !== documentsAPIs.OPP_CLMNT_INFO),  isLoading: false});        
        if (rowData) {
            this.onClickUpload(rowData);
        }
    }

    /**
     * On click upload document functionality
     * @param {Object} rowData 
     * @returns 
     */
    onClickUpload = async (rowData,type) => {  
        if (type && type === 'add') {
            this.editDocData = {};            
            await this.setState({ opType: 'add' })
        }     
        if (rowData.RelevanceTypeName === documentsAPIs.OPP_CLMNT_INFO || rowData.relevanceTypeName === documentsAPIs.OPP_CLMNT_INFO || rowData.SubCategory === documentsAPIs.OPP_CLMNT_INFO) {
            return;
        }
        this.setState({ isLoading: true });
        const sideBarDropdownType = (rowData.RelevanceTypeName === 'ADDR EVID' || rowData.relevanceTypeName === 'ADDR EVID' ) ? ADDRESS_TYPE : NAME_TYPE;
        const panelWidth = this.getPanelWidth(sideBarDropdownType);
        let selName = [];
        let selNamesData = [];
        const subCat = rowData.SubCategory ? rowData.SubCategory : rowData.subCategoryName ?  rowData.subCategoryName : '';  
        if (this.scopeObj[subCat] && this.scopeObj[subCat].includes(documentsAPIs.DOC_SCOPE_REL_SELLER) && this.state.formData && this.state.formData.selRelSellerName && this.state.formData.selRelSellerName.BusinessNamesID) {
            selName = [this.state.formData.selRelSellerName];            
            selNamesData.push({id:this.state.formData.selRelSellerName.BusinessNamesID});
        }
        if(this.scopeObj[subCat] && this.scopeObj[subCat].includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME) && this.state.formData && this.state.formData.selMappedName && this.state.formData.selMappedName.BusinessNamesID ){
            selName = [this.state.formData.selMappedName];
            selNamesData.push({id:this.state.formData.selMappedName.BusinessNamesID});
        }
        let uploadData = [{
            docTypeName: rowData.DocumentTypeName ? rowData.DocumentTypeName : rowData.documentTypeName ? rowData.documentTypeName : '' ,
            docSubCategory: subCat,
            selNames: selName,
            selNamesData: selNamesData,
            scope: this.scopeObj[subCat]
        }];
        let formData = this.state.formData;
        //If edit functionality - assigning existing values to input fields
        if (this.editDocData && this.editDocData.documentRelevances && this.editDocData.documentRelevances.length > 0 ) {
            this.formDataBefoteEdit = JSON.parse(JSON.stringify(this.state.formData));
            formData.DocFilingDateDay = this.editDocData.docFilingDateDay;
            formData.DocFilingDateMonth = this.editDocData.docFilingDateMonth;
            formData.DocFilingDateYear = this.editDocData.docFilingDateYear;
            formData.FileName = this.editDocData.fileName;
            formData.DocumentSource = this.state.documentSources.find((e) => e._id === this.editDocData.documentRelevances[0].documentSourceId);
            formData.DocumentSourceType = this.state.documentSourceTypes.find((e) => e._id === this.editDocData.documentRelevances[0].documentSourceTypeId);
            formData.DocumentTag = this.state.documentTags.find((e) => e.tag === this.editDocData.documentRelevances[0].documentTagName);
            let formatData = this.getEditFormattedData(this.editDocData.documentRelevances);
            if (formatData && formatData.length > 0) {
                uploadData = formatData;
            }
        } else {
            formData.DocFilingDateDay = null;
            formData.DocFilingDateMonth = null;
            formData.DocFilingDateYear = null;
            formData.FileName = null;
            formData.DocumentSource = null;
            formData.DocumentSourceType = null;
            formData.DocumentTag = null;
            this.setDocumentName(this.state.formData, this.state.selectedCategory, rowData);
        }
        // If user click on upload icon in edit case - adding that details in case the document type doesn't exist. 
        if (this.state.opType === 'edit') {
            const fIndex = uploadData.findIndex((e) => e.docTypeName === rowData.DocumentTypeName || e.docTypeName === rowData.documentTypeName);
            if (fIndex === -1) {
                uploadData.push({
                    docTypeName: rowData.DocumentTypeName ? rowData.DocumentTypeName : rowData.documentTypeName ? rowData.documentTypeName : '' ,
                    docSubCategory: subCat,
                    selNames: selName,
                    selNamesData: selNamesData,
                    scope: this.scopeObj[subCat]
                });               
            }
            this.setDocumentName(this.state.formData, this.state.selectedCategory,'',uploadData.map((e) => { return { name: e.docTypeName, scope: e.scope, subCategory: e.docSubCategory } }));
        }
        if (type && type === 'add') {
            this.clearFile();
            if (document.getElementById('doc-name')) {
                document.getElementById('doc-name').value = null;
            }
        }  
        this.setState({
            sidePanWidth: panelWidth,
            showNameSelectError: false,
            uploadingDocDetails: uploadData,
            showSideBar: true,
            selDocTypes: uploadData.map((e) => { return { name: e.docTypeName, scope: e.scope, subCategory: e.docSubCategory } }),
            noEvidError: false,
            formData: formData,
            initialFormData: { ...formData },
            isLoading: false,
            displayMandotaryErrorMsg: false,
            uploadingStatus: 'none', 
            uploadProgress: 0,
            showFileNameRestrictedCharError:false          
        });
    }

    /**
     * On select, de select document types from side bar handling functionality
     * @param {Array} docTypes 
     */
    onChangeDocumentTypes = (docTypes) => {
        if (docTypes.length > 0) {
            let uploadData = this.state.uploadingDocDetails;
            const docTypeNames = docTypes.map((e) => e.name);
            //delete unselected items
            uploadData = uploadData.filter((e) => docTypeNames.includes(e.docTypeName) === true);
            docTypes.forEach((e) => {
                const fi = uploadData.findIndex((elem) => elem.docTypeName === e.name);
                if (fi === -1) {
                    let selName = [];
                    let selNamesData = [];
                    if (e.scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER) && this.state.formData && this.state.formData.selRelSellerName && this.state.formData.selRelSellerName.BusinessNamesID) {
                        selName = [this.state.formData.selRelSellerName];
                         delete selName[0].CombinedNameLabel;
                         delete selName[0].StateOfIncorporation;
                        selNamesData.push({ id: this.state.formData.selRelSellerName.BusinessNamesID });
                    }
                    if (e.scope.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME) && this.state.formData && this.state.formData.selMappedName && this.state.formData.selMappedName.BusinessNamesID) {
                        selName = [this.state.formData.selMappedName];
                        selNamesData.push({ id: this.state.formData.selMappedName.BusinessNamesID });
                    }
                    uploadData.push({
                        docTypeName: e.name,
                        selNames: selName,
                        docSubCategory: e.subCategory,
                        selNamesData: selNamesData,
                        scope: e.scope ? e.scope : []
                    });
                }
            }); 
            if (this.state.opType !== 'edit') {
                this.setDocumentName(this.state.formData, this.state.selectedCategory, '', docTypes);
            }
            this.setState({ uploadingDocDetails: uploadData, selDocTypes: docTypes, noEvidError: false });
        } else {
            this.setState({ uploadingDocDetails: [], selDocTypes: docTypes, noEvidError: true });
        }
    }   

    /**
     * Functionality to handle on select de select names/addresses from multi select in sidebar.
     * @param {Number} index 
     * @param {Array} value 
     */
    onChangeRelNames = (index, value) => {
        let uploadData = this.state.uploadingDocDetails;
        uploadData[index].selNames = value;
        let selNamesData = JSON.parse(JSON.stringify(uploadData[index].selNamesData));
        const idVar = uploadData[index].docTypeName === documentsAPIs.MAPPED_NAME_ADDRESS_EVID ? ADDRESS_IDENTIFIER : NAME_IDENTIFIER;
        //delete unselected items
        uploadData[index].selNamesData = selNamesData.filter((e) => e && value.findIndex((name) => name[idVar] === e.id) !== -1);
        //Adding newly selecting Items
        uploadData[index].selNames.forEach((rel) => {
            const fi = selNamesData.findIndex((e) => e && (e.id === rel[idVar]));
            if (fi === -1) {
                // editRel[index].selNameData.push({ id: rel.BusinessNamesID, PageNumberReference: '', ParagraphNumberReference: '', DocumentRelevanceNotes: '',EventDateYear:'',EventDateMonth:'',EventDateDay:'' });
                uploadData[index].selNamesData.push({ id: rel[idVar] });
            }
        });
        this.setState({ uploadingDocDetails: uploadData });
    }

    onSelectAddDocumentType = (docType) => {
        let uploadData = this.state.uploadingDocDetails;
        uploadData.push({
            docTypeName: docType.Name,
            selNames: [],
            selNamesData: []
        });
        this.setState({ uploadingDocDetails: uploadData });
    }

    /**
     * Updating values in state from names/addresses related data from side bar
     * @param {Number} typeIndex 
     * @param {Number} nameIndex 
     * @param {String} field 
     * @param {*} value 
     */
    onChangeRelData = (typeIndex, nameIndex, field, value) => {
        let uploadData = JSON.parse(JSON.stringify(this.state.uploadingDocDetails));
        if (uploadData[typeIndex] && uploadData[typeIndex].selNamesData && uploadData[typeIndex].selNamesData[nameIndex]) {
            uploadData[typeIndex].selNamesData[nameIndex][field] = value;
            const details = {
                typeIndex: typeIndex,
                nameIndex: nameIndex,
                field: field,
                value: value,
                count: uploadData[typeIndex].selNamesData.length,
                type: uploadData[typeIndex].docTypeName
            }
            this.setState({ uploadingDocDetails: JSON.parse(JSON.stringify(uploadData)), copyValueDetails: details, showNoteEditor: false });
            if (field === 'DocumentRelevanceNotes' && this.state.applyToall) {
                this.copyValuesToAll(details)
            }
        }
    }

    copyValuesToAll = (copyingDetails) => {
        const { typeIndex, nameIndex, field, value } = copyingDetails;
        let uploadData = JSON.parse(JSON.stringify(this.state.uploadingDocDetails));
        if (uploadData[typeIndex] && uploadData[typeIndex].selNamesData && uploadData[typeIndex].selNamesData.length > 0) {
            const count = uploadData[typeIndex].selNamesData.length;
            for (let i = 0; i < count; i++) {
                uploadData[typeIndex].selNamesData[i][field] = value;
            }
            this.setState({ uploadingDocDetails: JSON.parse(JSON.stringify(uploadData)), showCopyAllOption: false });
        }        
    }

    onSelCategory = (value) => {
        this.setState({ selectedCategory: value, disbaleGoButton: true, formData: { DealID: null }, selectingDeal: null, stateAttributes: [],showConfigError:false })
    }

    setDocumentName = (data, category, selRow, docTypes) => {        
        let scope = [];
        let allScopes = (category.map((e) => e.documentScopeName)).flat();
        if(allScopes.length > 0){
            scope = [...new Set(allScopes)];
        }        
        let docName = '';
        if (scope.includes(documentsAPIs.DOC_SCOPE_DEAL) && data.deal) {
            docName = `${data.deal && data.deal.SellerNickName ? data.deal.SellerNickName : ''}${data.deal && data.deal.DealID ? `(${data.deal.DealID})` : ''}`
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME) && data.selMappedName) {
            docName = docName + (data.selMappedName && data.selMappedName.BusinessNamesID ? `-${data.selMappedName.BusinessNamesID}` : '');
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER) && data.selRelSellerName) {
            docName = docName + (data.selRelSellerName && data.selRelSellerName.BusinessNamesID ? `-${data.selRelSellerName.BusinessNamesID}` : '');
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_STATE) && data.selState) {
            docName = docName + (data.selState && data.selState.shortCode ? `-${data.selState.shortCode}` : '');
        }
        if (scope.includes(documentsAPIs.DOC_SCOPE_OPP_CLAIMID) && data.selStateClaimId) {
            docName = docName + (data.selStateClaimId && data.selStateClaimId.oppClaimID ? `-${data.selStateClaimId.oppClaimID}` : '');
        }
        if (selRow && selRow.DocumentTypeName) {
            docName = `${docName}-${selRow.DocumentTypeName}.pdf`;
        } else if (docTypes) {
            if (docTypes.length > 3) {
                docName = `${docName}-Multiple Document Types.pdf`;
            } else {
                docName = `${docName}-${docTypes.map((e) => e.name).join('-')}.pdf`;
            }
        }
        this.setState({ sysGenFileName: docName });
    } 

    /**
     * To get selected Sub category ID
     * @param {String} subCat 
     * @returns subcategory Id
     */
    getSubCatID = (subCat) => {
        const cat = this.state.selectedCategory.find((e) => e.subCategoryName === subCat);
        if (cat && cat._id) {
            return cat._id;
        } else {
            return null;
        }
    }

    /**
     * Formatting relevances data for api
     * @returns Array of formatted relevances 
     */
    getRelList = () => {
        let relData = [];
        this.state.uploadingDocDetails.forEach((dt) => {                      
            const idVar = dt.docTypeName === documentsAPIs.MAPPED_NAME_ADDRESS_EVID ? ADDRESS_IDENTIFIER : NAME_IDENTIFIER;
            const idPar = dt.docTypeName === documentsAPIs.MAPPED_NAME_ADDRESS_EVID ? 'BusinessLocationId' : 'BusinessNameId';
            const attributeDetails = this.state.stateAttributes.find((e) => e.DocumentTypeName === dt.docTypeName);
            if (attributeDetails) {
                let obj = {
                    RelevanceTypeId: attributeDetails.RelevanceTypeId,
                    RelevanceTypeName: attributeDetails.RelevanceTypeName,
                    DocumentTypeId: attributeDetails.DocumentTypeId,
                    DocumentTypeName: attributeDetails.DocumentTypeName,
                    SubCategoryName :dt.docSubCategory,
                    SubCategoryID:this.getSubCatID(dt.docSubCategory),
                    DocumentSourceId: this.state.formData.DocumentSource && this.state.formData.DocumentSource._id ? this.state.formData.DocumentSource._id : '',
                    DocumentSourceTypeId: this.state.formData.DocumentSourceType && this.state.formData.DocumentSourceType._id ? this.state.formData.DocumentSourceType._id : '',
                    DocumentSourceTypeName: this.state.formData.DocumentSourceType && this.state.formData.DocumentSourceType.sourceType ? this.state.formData.DocumentSourceType.sourceType : '',
                    DocumentTagId: this.state.formData.DocumentTag && this.state.formData.DocumentTag._id ? this.state.formData.DocumentTag._id : null,
                    DocumentTagName: this.state.formData.DocumentTag && this.state.formData.DocumentTag.tag ? this.state.formData.DocumentTag.tag : '',
                    StateUpFilingId: attributeDetails._id
                }
                if(dt.documentRelevanceId){
                    obj.DocumentRelevanceId = dt.documentRelevanceId;
                }
                if (dt.scope && dt.scope.length > 0) {
                    if (dt.scope.includes(documentsAPIs.DOC_SCOPE_STATE)) {
                        obj.State = this.state.formData.selState && this.state.formData.selState.shortCode ? this.state.formData.selState.shortCode : '';
                    }
                    if (dt.scope.includes(documentsAPIs.DOC_SCOPE_OPP_CLAIMID)) {
                        obj.StateClaimID = this.state.formData.selStateClaimId && this.state.formData.selStateClaimId.stateClaimID ? this.state.formData.selStateClaimId.stateClaimID : '';
                        obj.OPPClaimID = this.state.formData.selStateClaimId && this.state.formData.selStateClaimId.oppClaimID ? this.state.formData.selStateClaimId.oppClaimID : '';
                    }
                    if(dt.scope.includes(documentsAPIs.DOC_SCOPE_CLAIMID)){
                        obj.StateClaimID = this.state.formData.selStateClaimIdOnly && this.state.formData.selStateClaimIdOnly.stateClaimID ? this.state.formData.selStateClaimIdOnly.stateClaimID : '';
                    }
                }
                if (dt && dt.selNamesData.length > 0) {
                    dt.selNamesData.forEach((selData) => {
                        obj = {
                            ...obj,
                            ...selData,
                            [idPar]: selData.id
                        }
                        relData.push(obj);
                    })
                } else if (dt && dt.selNames.length > 0 && dt.selNamesData.length === 0) {
                    dt.selNames.forEach((e) => {
                        obj = { ...obj, [idPar]: e[idVar] };
                        relData.push(obj);
                    })

                } else {
                    relData.push(obj)
                }
            }

        });
        return relData;
    }
   
    clearTableData = () => {
        this.setState({
            stateAttributes: [],
            showConfigError: false
        });
    }

    setDocumentData = (fileId,details) => {        
        this.fileId = fileId;        
        this.editDocData = details;
        this.setState({ opType: 'edit' });
    }

    /**
     * Formating data for side panel for update functionality
     * @param {Array} relData 
     * @returns {Array} Formatted rel array
     */
    getEditFormattedData = (relData) => {
        let uploadingDetails = [];
        if (relData && relData.length > 0) {
            relData.forEach((rel) => {
                if (this.state.selectedCategory.findIndex((e) => e.subCategoryName === rel.subCategoryName) > -1) {
                    const selDocType = this.state.stateAttributes.find((e) => e.DocumentTypeId === rel.documentTypeId);
                    const docTypeName = selDocType && selDocType.DocumentTypeName ? selDocType.DocumentTypeName : '';
                    let selName = '';
                    if (rel.businessNameId) {
                        const busId = parseInt(rel.businessNameId);
                        selName = this.state.names.find((e) => e.BusinessNamesID === busId);
                    } else if (rel.businessLocationId) {
                        const busId = parseInt(rel.businessLocationId);
                        selName = this.state.addresses.find((e) => e.BusinessLocationID === busId);
                    }
                    const relOtherFieldsData = {
                        id: rel.businessNameId ? rel.businessNameId : (rel.businessLocationId ? rel.businessLocationId : ''),
                        EventDateYear: rel.eventDateYear,
                        EventDateMonth: rel.eventDateMonth,
                        EventDateDay: rel.eventDateDay,
                        PageNumberReference: rel.pageNumberReference,
                        ParagraphNumberReference: rel.paragraphNumberReference,
                        DocumentRelevanceNotes: rel.documentRelevanceNotes
                    }                    
                    let index = uploadingDetails.findIndex((e) => e.docTypeId === rel.documentTypeId);
                    if (index > -1) {
                        if (selName) {
                            uploadingDetails[index].selNames.push(selName);
                            uploadingDetails[index].selNamesData.push(relOtherFieldsData);
                        }
                    } else {
                        uploadingDetails.push({
                            docTypeName: docTypeName,
                            docTypeId: rel.documentTypeId,
                            docSubCategory: rel.subCategoryName,
                            documentRelevanceId:rel.documentRelevanceId,
                            selNames: selName ? [selName] : [],
                            selNamesData: selName ? [relOtherFieldsData] : [],
                            scope: this.scopeObj[rel.subCategoryName]
                        });
                    }
                }

            });
        }             
        return uploadingDetails;
    }

    onClickCloseSideBar = () => {
        if (!window.location.href.includes('claimDocumentation')) {
            window.history.replaceState(null, null, `${window.location.origin}/sellersEdit/documentUpload/${this.state.formData.deal && this.state.formData.deal.DealID ? this.state.formData.deal.DealID : ''}`);
        }
        if (this.uploadingStatusTimeout) {
            clearTimeout(this.uploadingStatusTimeout);
            this.setState({ uploadingStatus: 'none' });
        }
        if (this.state.opType === 'edit') {
            // this.showWarningDialog('Are you sure, You want to close without Update?', this.onClickYesOnCloseEdit);            
            this.setState({ showSideBar: false,uploadingDocDetails:[]});
        } else {
            this.setState({ showSideBar: false, formData: {...this.state.initialFormData}, displayMandotaryErrorMsg: false,uploadingDocDetails:[] });
            this.clearFile();
        }
    }

    onClickYesOnCloseEdit = () => {
        this.editDocData = {};
        this.setState({ opType: 'Add', formData: this.formDataBefoteEdit, showSideBar: false, showUserWaring: false });
        if (!window.location.href.includes('claimDocumentation')) {
            window.history.replaceState(null, null, `${window.location.origin}/sellersEdit/documentUpload/${this.state.formData.deal && this.state.formData.deal.DealID ? this.state.formData.deal.DealID : ''}`);
        }
    }

    onClickDelDocType = (index, docType) => {
        let updateData = this.state.uploadingDocDetails;
        updateData.splice(index, 1);
        let selDocuments = this.state.selDocTypes;
        let i = this.state.selDocTypes.findIndex((e) => e.name === docType);
        if (i > -1) {
            selDocuments.splice(i, 1);
            this.onChangeDocumentTypes(selDocuments)
        }
        this.setState({ uploadingDocDetails: updateData, showUserWaring: false });
    }

    showWarningDialog = (message, fn) => {
        this.setState({ warningMessage: message, callFn: fn, showUserWaring: true });
    }

    getDocTypePanelHeader = (docType, index) => {
        return <div className='d-flex justify-content-between'>
            <div>{docType}</div>
            <div><FontAwesomeIcon
                icon={faTimes}
                color={'red'}
                data-toggle="tool-tip"
                title="Close"
                onClick={() => this.showWarningDialog('Are you sure, You want to Delete ?', () => this.onClickDelDocType(index, docType))}
                className='cursor-pointer'
            /></div>
        </div>
    }

    /**
    * Show red star besides mandatory fields
    * @returns {HTMLElement}
    */
    getMandatoryStar = () => {
        return <FontAwesomeIcon icon={faStarOfLife}
            className='pl-1'
            color='red'
            data-toggle="tool-tip"
            title="Mandotary Field"
            style={{ width: 8 }}
        />
    }

    /**
     * 
     * @param {data} value 
     * @param {String} errorMsg 
     * @returns 
     */
    requiredMessage = (value, errorMsg) => {
        return !value && this.state.displayMandotaryErrorMsg ? <small className='text-danger'>{errorMsg ? errorMsg : 'Above field is required.'}</small> : null;
    }

    onClickEdit = async (documentId,rowData) => {
        const docDetails = await documentsAPIs.getDocumentDetails(documentId);
        this.fileId = docDetails.sharePointDetails && docDetails.sharePointDetails[0] && docDetails.sharePointDetails[0].id ? docDetails.sharePointDetails[0].id : '';;
        this.editDocData = docDetails;
        this.setState({ opType: 'edit' },() =>{
            this.onClickUpload(rowData);
        });
    }   

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': false,
            'modal-dialog--header': true,
        });

        return (
            <div className='mt-2 mx-1 document-upload'>
                <div className=" w-100" >
                    {/* <Panel header={`Document Upload ${this.state.editType === 'update' ? ' - Update' : ''}`} className='w-100'> */}
                        {/* Document level fields starts */}

                        <DocSelectFields
                            tab={documentsAPIs.DOC_UPLOAD_TAB_NAME}
                            getDocumentDetails={this.getDocumentDetails}
                            getStateAttributes={this.getStateAttributes}
                            setLoader={(value) => this.setState({ isLoading: value })}
                            onSelCategory={this.onSelCategory}
                            getCompanyData={this.getCompanyData}
                            clearTableData={this.clearTableData}
                            setDocumentData={this.setDocumentData}
                            setOpType={(type) => { this.setState({ opType: type }) }}
                            names={this.props.names}
                            relSellerNames={this.props.relSellerNames}
                            stateClaimIds={this.props.stateClaimIds}
                            setDocumentsDropdownsData={this.props.setDocumentsDropdownsData}
                            selectedDeal={this.props.selectedDeal}
                            
                        />


                        {/* Document level fields ends */}

                        <div className='mt-3'>
                            {
                                this.state.stateAttributes.length > 0 ?
                                    <DocTable
                                        stateAttributes={this.state.stateAttributes}
                                        tab={documentsAPIs.DOC_UPLOAD_TAB_NAME}
                                        redirectToUplload={this.redirectToUplload}
                                        onClickUpload={this.onClickUpload}
                                        onClickEdit={this.onClickEdit}
                                        fileFrequency={this.state.fileFrequency}
                                        redirectToPDFEditor={documentsAPIs.redirectToPDFEditor}
                                        preSalePermission = {this.props.preSalePermission}

                                    />
                                    :
                                    <>{this.state.showConfigError && <div className='text-danger config-error-message'>No Document Relevance found. Please contact the OPRA2 team to check the configurations.</div>}</>


                            }
                        </div>





                    {/* </Panel> */}
                </div>
                {/* Side bar starts */}
                <Sidebar visible={this.state.showSideBar} style={{ width: this.state.sidePanWidth, overflowY: 'auto' }} position="right" showCloseIcon={false} onHide={() => this.setState({ showSideBar: false })} dismissable={false} closeOnEscape={false} iconsTemplate={() => (<div className='d-flex justify-content-end'>
                    <FontAwesomeIcon
                        icon={faTimes}
                        color={'red'}
                        data-toggle="tool-tip"
                        title="Close"
                        onClick={() => this.onClickCloseSideBar()}
                        className='cursor-pointer'
                    />
                </div>)}>


                    <div className='d-flex flex-column h-100 side-bar-body mt-2'>
                        {this.state.opType !== 'edit' &&
                            <div className="row mt-1">
                                <div className="col-md-3 d-flex justify-content-end">
                                    <h4>Upload File</h4>
                                </div>
                                <div className="col-md-9">
                                    <label for="doc-uploader" className={`doc-upload-button ${(this.state.uploadingStatus === 'inProgress') ? 'doc-upload-button-disable' : ''}`}>
                                        Select File
                                    </label>
                                    <input type='file' id="doc-uploader" accept='application/pdf' onChange={(e) => this.onFileSelect(e)}></input>
                                    {this.state.fileDetails.name && <small className='ml-2'>{this.state.fileDetails.name}</small>}
                                </div>
                            </div>
                        }                      
                        <div className="row mt-2">
                        <div className="col-md-3 d-flex justify-content-end">
                                <h4>Document Name{this.getMandatoryStar()}</h4>
                            </div>
                            <div className='col-md-9'> 
                                <div>
                                    <InputText className='w-100' id='doc-name' value={this.state.formData.FileName} onChange={(e) => this.setFormValues('FileName', e.target.value)} />
                                    {this.requiredMessage(this.state.formData.FileName, 'Please enter Document Name.')}
                                    {this.state.showFileNameRestrictedCharError && <strong className='text-danger'>{documentsAPIs.RESTRICT_FILE_NAME_CHAR_ERROR}</strong>}
                                </div> 
                                {this.state.fileDetails.name && <>
                                    <div>
                                        <span className='text-secondary'>You can rename the file or select either of the below suggested filenames:</span>
                                    </div>
                                    <div>
                                        <div className='my-1'>
                                            <RadioButton inputId={`documentName1`} name="documentName" onChange={() => this.setFormValues('FileName', this.state.fileDetails.name)} checked={this.state.fileDetails.name === this.state.formData.FileName} />
                                            <label htmlFor={`documentName1`} className="p-radiobutton-label">{this.state.fileDetails.name}</label>
                                        </div>
                                        <div className='my-1'>
                                            <RadioButton inputId={`documentName2`} name="documentName" checked={this.state.sysGenFileName === this.state.formData.FileName} onChange={() => this.setFormValues('FileName', this.state.sysGenFileName)} />
                                            <label htmlFor={`documentName2`} className="p-radiobutton-label">{this.state.sysGenFileName}</label>
                                        </div>
                                    </div>
                                </>}
                            </div>


                        </div>
                        
                        <div className="row mt-2">
                            <div className="col-md-3 d-flex justify-content-end">
                                <h4>Document Tags</h4>
                            </div>
                            <div className="col-md-3">
                                <label>Source Type{this.getMandatoryStar()}</label>
                                <Dropdown value={this.state.formData.DocumentSourceType} optionLabel='sourceType' placeholder='Select Source Type' onChange={(e) => { this.setFormValues('DocumentSourceType', e.value) }} options={this.state.documentSourceTypes}
                                    style={{ width: '100%' }} filter={true} filterPlaceholder="Search" dataKey="_id"
                                    filterMatchMode='startsWith' filterInputAutoFocus={true} />
                                {this.requiredMessage(this.state.formData.DocumentSourceType, 'Please select Source Type.')}
                            </div>
                            <div className="col-md-3">
                                <label>Document Tag{this.getMandatoryStar()}</label>
                                <Dropdown value={this.state.formData.DocumentTag} optionLabel='tag' placeholder='Select Document Tag' onChange={(e) => { this.setFormValues('DocumentTag', e.value) }} options={this.state.documentTags}
                                    style={{ width: '100%' }} filter={true} filterPlaceholder="Search" dataKey="tag"
                                    filterMatchMode='startsWith' filterInputAutoFocus={true} />
                                {this.requiredMessage(this.state.formData.DocumentTag, 'Please select Document Tag.')}
                            </div>
                            <div className="col-md-3">
                                <label>Document Source{this.getMandatoryStar()}</label>
                                <Dropdown value={this.state.formData.DocumentSource} optionLabel='documentSourceName' placeholder='Select Document Source' onChange={(e) => { this.setFormValues('DocumentSource', e.value) }} options={this.state.documentSources}
                                    style={{ width: '100%' }} filter={true} filterPlaceholder="Search" dataKey="_id"
                                    filterMatchMode='startsWith' filterInputAutoFocus={true} />
                                {this.requiredMessage(this.state.formData.DocumentSource, 'Please select Document Source.')}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-3 d-flex justify-content-end">
                                <h4>Document Filing Date</h4>
                            </div>
                            <div className="col-md-6">                                
                                <DateGroup
                                    handleOutput={this.handleFilingDate}
                                    disableAll={this.state.editType === 'update'}
                                    populateItems={{ year: this.state.formData.DocFilingDateYear, month: this.state.formData.DocFilingDateMonth, day: this.state.formData.DocFilingDateDay }}
                                />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className='w-100 px-3'>
                                {/* <div className='d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center upload-header'>
                                    {this.state.opType === 'edit' ?
                                        <div>
                                            <button className='doc-upload-button' type='button' onClick={() => this.checkNamesSelected()} disabled={this.state.selDocTypes.length === 0}>
                                                Update
                                            </button>

                                        </div>
                                        :
                                        <div>

                                            <label for="doc-uploader" className={`doc-upload-button ${(this.state.uploadingStatus === 'inProgress') ? 'doc-upload-button-disable' : ''}`}>
                                                Select File
                                            </label>

                                            <input type='file' id="doc-uploader" accept='application/pdf' onChange={(e) => this.onFileSelect(e)}></input>

                                            <span onClick={e => this.setState({ displayMandotaryErrorMsg: true })}>
                                                <button className='doc-upload-button ml-3' type='button' disabled={this.state.noEvidError || !this.state.fileDetails.name || this.state.uploadingStatus === 'inProgress' || !this.state.enableSave} onClick={() => this.checkNamesSelected()}>
                                                    <FontAwesomeIcon
                                                        icon={faUpload} className='mr-1' />  Upload
                                                </button>
                                            </span>

                                        </div>
                                    }
                                </div> */}

                                {/* File details, progress bar & success/failure messages */}
                                {/* <div className='upload-content'>
                                    {this.state.uploadingStatus === 'inProgress' ?
                                        <ProgressBar value={this.state.uploadProgress}></ProgressBar> :
                                        this.state.uploadingStatus === 'done' ?
                                            <Alerts type={this.state.finalResponse.severity} message={this.state.finalResponse.message} changeStatus={this.changeStatus} /> :
                                            <div className='d-flex justify-content-between'>
                                                <div>{this.state.fileDetails.name}</div>
                                                <div>{this.state.fileDetails.size}</div>
                                                <div>{this.state.fileDetails.date}</div>
                                                <div>{this.state.fileDetails.user}</div>
                                                {this.state.fileDetails.name &&
                                                    <div><FontAwesomeIcon
                                                        icon={faTimesCircle} onClick={() => this.clearFile()} /></div>
                                                }
                                            </div>
                                    }

                                </div> */}

                            </div>
                        </div>
                        <div >
                            {this.state.displayMandotaryErrorMsg && (this.state.noEvidError || !this.state.fileDetails.name || this.state.uploadingStatus === 'inProgress' || !this.state.enableSave) ? <div className='alert alert-danger'> Please select mandatory fields.</div> : <div className='py-4'></div>}
                        </div>
                        {this.state.documentTypes && this.state.documentTypes.length > 1 &&
                            <>
                                <div className="row">
                                    <div className="col-md-3 d-flex align-items-center justify-content-end">
                                       <h4> Add / Remove Document Types </h4>
                                    </div>
                                    <div className="col-md-9">
                                        <MultiSelect placeholder='Add/Remove Document Type' maxSelectedLabels={3} value={this.state.selDocTypes} onChange={(e) => { this.onChangeDocumentTypes(e.value) }} options={this.state.documentTypes} optionLabel='name' style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>

                                {this.state.noEvidError &&
                                    <div className="mt-2 px-2 text-danger text-center">
                                        <strong>Please select at least one document type</strong>

                                    </div>
                                }
                            </>
                        }


                        <div className='text-left'>


                            {this.state.showNameSelectError && <div className='alert alert-danger'> Please select at least one name/Address for selected category.</div>}
                            {this.state.uploadingDocDetails.length > 0 && this.state.uploadingDocDetails.map((rel, i) => {
                                return <div>
                                    {(rel.scope && rel.scope.length > 0 && (rel.scope.includes(documentsAPIs.DOC_SCOPE_MAPPED_NAME) || rel.scope.includes(documentsAPIs.DOC_SCOPE_REL_SELLER) || rel.scope.includes(documentsAPIs.DOC_SCOPE_ADDRESS))) ?
                                        <Panel header={this.getDocTypePanelHeader(rel.docTypeName, i)} className='w-100 my-1'>
                                            {(rel.docTypeName === documentsAPIs.MAPPED_NAME_ADDRESS_EVID || rel.scope.includes(documentsAPIs.DOC_SCOPE_ADDRESS)) ?
                                                <div className='w-100'>
                                                    <label>{`Addresses (${this.state.addresses.length})`}{this.getMandatoryStar()}</label>
                                                    <MultiSelect optionLabel={"CombinedName"} value={this.state.uploadingDocDetails[i].selNames} options={this.state.addresses} onChange={(e) => this.onChangeRelNames(i, e.value)}
                                                        style={{ width: '100%' }} filter={true} filterBy={'CombinedName'} filterPlaceholder="Search" placeholder={"Select Addresses"} />
                                                    {this.state.showNameSelectError && this.state.uploadingDocDetails[i].selNames && this.state.uploadingDocDetails[i].selNames.length === 0 && <small className='text-danger'>Please Select Address</small>}

                                                </div> :
                                                <div>
                                                    <label>{`Names ${`(${this.state.names.length})`}`}{this.getMandatoryStar()}</label>
                                                    <MultiSelect optionLabel={"BusinessName"} dataKey='BusinessNamesID' value={this.state.uploadingDocDetails[i].selNames} options={this.state.names} onChange={(e) => this.onChangeRelNames(i, e.value)}
                                                        style={{ width: '100%' }} filterMatchMode='startsWith' filter={true} filterBy={'BusinessName'} itemTemplate={this.namesDropDownTemplate} filterPlaceholder="Search" placeholder={"Select Names"} disabled={true}  />
                                                    {/* {this.state.selectedCategory.documentScopeName.includes(documentsAPIs.DOC_SCOPE_CLAIMID) && this.state.uploadingDocDetails[i].selNames.length === 0 && <div> <small >No name matched with selected state claim ID</small></div>} */}
                                                    {this.state.showNameSelectError && this.state.uploadingDocDetails[i].selNames && this.state.uploadingDocDetails[i].selNames.length === 0 && <small className='text-danger'>Please Select Name.</small>}
                                                </div>
                                            }

                                            {
                                                this.state.uploadingDocDetails[i].selNames.length > 0 && this.state.uploadingDocDetails[i].selNamesData.length > 0 && this.state.uploadingDocDetails[i].selNames.map((selName, j) => {
                                                    return <div className="row mt-1 border-top">
                                                        <div className='col-12'><small><strong>{selName && selName.CombinedName ? selName.CombinedName : ''}</strong></small></div>
                                                        <div className="col-5">
                                                            <label>Event/As of date</label>
                                                            <DateGroup
                                                                handleOutput={this.handleEventDate}
                                                                otherFields={{ typeIndex: i, nameIndex: j }}
                                                                disableAll={this.state.editType === 'update'}
                                                                populateItems={{ year: this.state.uploadingDocDetails[i].selNamesData[j].EventDateYear, month: this.state.uploadingDocDetails[i].selNamesData[j].EventDateMonth, day: this.state.uploadingDocDetails[i].selNamesData[j].EventDateDay }}
                                                            />
                                                        </div>

                                                        <div className='col-3'>
                                                            <div className="row">
                                                                <div className='col-6'>
                                                                    <label>Page</label>
                                                                    <InputText className='w-100' value={this.state.uploadingDocDetails[i].selNamesData[j].PageNumberReference} onChange={(e) => this.onChangeRelData(i, j, 'PageNumberReference', e.target.value)} onBlur={() => this.state.uploadingDocDetails[i].selNamesData.length > 1 ? this.setState({ showCopyAllOption: true }) : undefined} />

                                                                </div>
                                                                <div className='col-6'>
                                                                    <label>Paragraph</label>
                                                                    <InputText className='w-100' value={this.state.uploadingDocDetails[i].selNamesData[j].ParagraphNumberReference} onChange={(e) => this.onChangeRelData(i, j, 'ParagraphNumberReference', e.target.value)} onBlur={() => this.state.uploadingDocDetails[i].selNamesData.length > 1 ? this.setState({ showCopyAllOption: true }) : undefined} />

                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div className='col-4'>
                                                            <label>Note</label>
                                                            <InputText className='w-100' value={this.state.uploadingDocDetails[i].selNamesData[j].DocumentRelevanceNotes} onFocus={(e) => { this.onFocusNote(i, j, 'DocumentRelevanceNotes', e.target.value) }} />
                                                        </div>


                                                    </div>
                                                })
                                            }

                                        </Panel>
                                        :
                                        <Panel header={this.getDocTypePanelHeader(rel.docTypeName, i)} className='empty-panel w-100 my-1'> </Panel>


                                    }

                                </div>

                            })
                            }




                            {/* {(this.state.selectedCategory && this.state.selectedCategory.documentScopeName && (this.state.selectedCategory.documentScopeName.includes(documentsAPIs.DOC_SCOPE_CLAIMID))) &&
                                <Panel header={this.state.uploadingDocDetails && this.state.uploadingDocDetails[0] && this.state.uploadingDocDetails[0].docTypeName ? this.state.uploadingDocDetails[0].docTypeName : ''} className='w-100 my-1'>
                                    {this.state.formData.selStateClaimId && this.state.formData.selStateClaimId.relevantSellerName ? this.state.formData.selStateClaimId.relevantSellerName : ''}
                                </Panel>
                            } */}






                        </div>
                        {/* File details, progress bar & success/failure messages */}
                        <div className='my-2'>
                            {this.state.uploadingStatus === 'inProgress' ?
                                <ProgressBar value={this.state.uploadProgress}></ProgressBar> :
                                this.state.uploadingStatus === 'done' ?
                                    <Alerts type={this.state.finalResponse.severity} message={this.state.finalResponse.message} changeStatus={this.changeStatus} /> : null
                                   
                            }
                        </div>
                        <div className="row">
                            {this.state.opType === 'edit' ?
                                <div className='col-md-3 offset-md-3'>
                                    <button className='doc-upload-button' type='button' onClick={() => this.checkNamesSelected()} disabled={this.state.selDocTypes.length === 0}>
                                        Update
                                    </button>

                                </div> :
                                <div className='mt-2 col-md-3 offset-md-3' onClick={() => this.setState({ displayMandotaryErrorMsg: true })} >
                                
                                    <button className='doc-upload-button' type='button' disabled={this.state.noEvidError || !this.state.fileDetails.name || this.state.uploadingStatus === 'inProgress' || !this.state.enableSave} onClick={() => {this.checkNamesSelected()}}>
                                        <FontAwesomeIcon
                                            icon={faUpload} className='mr-1' />  Upload
                                    </button>
                                </div>

                            }
                        </div>

                    </div>
                </Sidebar>
                {/* Side bar ends */}

                {/* Evidence not selected alert  */}
                <Dialog showHeader={false} visible={this.state.showUserAlert} style={{ maxWidth: '50vw' }} modal={true} onHide={() => this.setState({ showUserAlert: false })}>
                    <div className='text-center p-2'>
                        {
                            this.state.alertType === 'diffCategory' ?
                                <h5 className='mb-3'> Please select document relevance of the same type </h5> :
                                <h5 className='mb-3'>
                                    The document,&nbsp;
                                    <span className='my-1' style={{ color: 'blue' }}>{this.state.formData.FileName}</span>,&nbsp;
                                    will be saved without any document evidence assignment.<br></br>
                                    Do you want to continue?
                                </h5>

                        }

                        <Button
                            color="primary"
                            onClick={() => this.setState({ showUserAlert: false, alertType: null })}
                        >
                            {this.state.alertType === 'diffCategory' ? 'Close' : 'No'}
                        </Button>
                        {!this.state.alertType &&
                            <Button
                                color="primary"
                                onClick={() => this.checkDocumentExist()}
                            >Yes</Button>
                        }
                    </div>
                </Dialog>

                {/* Notes dialog */}
                <Dialog showHeader={true} header={'Note'} visible={this.state.showNoteEditor} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ showNoteEditor: false })}>
                    <div className='text-center p-4'>
                        <div className='w-100'>
                            <InputTextarea rows={5} cols={30}
                                style={{ width: '100%' }}
                                value={this.state.notes}
                                onChange={(e) => this.setState({ notes: e.target.value })}
                                placeholder='Enter Note..'
                            />
                        </div>
                        {this.state.copyValueDetails.count > 1 &&
                            <div className='text-left'>
                                <Checkbox onChange={e => this.setState({ applyToall: e.checked })} checked={this.state.applyToall}> </Checkbox> Copy this value to all {this.state.copyValueDetails.count} {this.state.copyValueDetails.type}
                            </div>
                        }
                        <div className='mt-2 w-100 text-center'>
                            <Button
                                color="primary"
                                onClick={() => this.onChangeRelData(this.state.copyValueDetails.typeIndex, this.state.copyValueDetails.nameIndex, 'DocumentRelevanceNotes', this.state.notes)}
                            >Done</Button>
                        </div>
                    </div>
                </Dialog>

                {/* Copy all option dialog */}
                <Dialog showHeader={false} visible={this.state.showCopyAllOption} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showCopyAllOption: false })}>
                    <div className='text-center p-4'>
                        <div className='text-center'>
                            <h5>Do you want to copy this value to all {this.state.copyValueDetails.count} {this.state.copyValueDetails.type}?</h5>

                        </div>
                        <div className='mt-2 w-100 text-center'>
                            <Button
                                color="primary"
                                onClick={() => this.setState({ showCopyAllOption: false })}
                            >
                                NO
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => this.copyValuesToAll(this.state.copyValueDetails)}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Filename found  dialog */}
                <Dialog showHeader={false} visible={this.state.showFileFound} style={{ width: '50vw' }} modal={true} onHide={() => this.setState({ showFileFound: false })}>
                    <div className='text-center p-4'>
                        {this.state.showRenameOption ?
                            <div className='row mt-1'>
                                <div className="form__form-group col-lg-12 text-left">
                                    <label className="form__form-group-label">Document Name</label>
                                    <div className=' form__form-group-field'>
                                        <InputText id='doc-name' value={this.state.formData.FileName} onBlur={(e) => this.toggleFileExtension(e.target.value, 'add')} className='w-100' onChange={(e) => { this.setState({ filenameFoundAgain: false }); this.setFormValues('FileName', e.target.value) }} />
                                    </div>
                                    {this.state.showFileNameRestrictedCharError && <strong className='text-danger'>{documentsAPIs.RESTRICT_FILE_NAME_CHAR_ERROR}</strong>}
                                    {this.state.checkingFileName && <>Checking file name <div class="spinner-border spinner-border-sm text-secondary my-1" role="status">
                                        <span class="sr-only">...</span>
                                    </div> </>
                                    }
                                    {
                                        this.state.filenameFoundAgain && <small className='text-danger'>File name already exist. Please enter diferent file name.</small>
                                    }
                                </div>

                            </div>
                            :
                            <div className='text-center'>
                                <h5>A file name, <span style={{ color: 'blue' }}>{this.state.formData.FileName}</span> already exists for this Document Relevance. Either  rename the file or cancel the upload operation.</h5>

                            </div>
                        }
                        <div className='mt-3 w-100 text-center'>
                            {this.state.showRenameOption ?
                                <Button
                                    color="primary"
                                    disabled={this.state.checkingFileName}
                                    onClick={() => { this.checkDocumentExist(true) }}
                                >
                                    Save
                                </Button> :
                                <Button
                                    color="primary"
                                    onClick={() => this.setState({ showRenameOption: true })}
                                >
                                    Rename
                                </Button>
                            }
                            <Button
                                color="primary"
                                disabled={this.state.checkingFileName}
                                onClick={() => this.setState({ showFileFound: false, showRenameOption: false })}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <Dialog showHeader={false} visible={this.state.showUserWaring} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showUserWaring: false })}>
                    <div className='text-center p-4'>
                        <div className='text-center'>
                            <h4>{this.state.warningMessage}</h4>

                        </div>
                        <div className='mt-2 w-100 text-center'>
                            <Button
                                color="primary"
                                onClick={() => this.setState({ showUserWaring: false })}
                            >
                                NO
                            </Button>
                            <Button
                                color="primary"
                                onClick={this.state.callFn}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <Loader loader={this.state.isLoading} />
            </div>
        )
    }
}


export default withRouter(DocumentUpload);