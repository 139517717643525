import fetchMethodRequest from '../config/service';
import dateFormats from '../containers/UI/FormatDate/formatDate';
import config from '../config/config';


let fileFrequency = {};
const loginCredentials = localStorage.getItem("loginCredentials") ? JSON.parse(localStorage.getItem("loginCredentials")) : {};

export const getColumnOrders = async (screen) => {
    const filterCriteria = {
        "criteria": [{ "key": "screen", "value": screen, "type": "eq", "add_Filter": true }, { "key": "userId", "value": loginCredentials._id, "type": "eq" }
        ],
    };
    const result = await fetchMethodRequest('GET', `columnOrderPreference?filter=${JSON.stringify(filterCriteria)}`);
    if (result && result.columnOrderPreference && result.columnOrderPreference.length > 0) {
        return Promise.resolve(result.columnOrderPreference);
    } else {
        return Promise.resolve([]);
    }
}
export const getColumnOrderById = async (id) => {
    const filterCriteria = {
        "criteria": [{ "key": "_id", "value": id, "type": "eq", "add_Filter": true }
        ],
    };
    const result = await fetchMethodRequest('GET', `columnOrderPreference/${id}`);
    if (result && result.details) {
        return Promise.resolve(result.details)
    } else {
        return Promise.resolve([]);
    }
}