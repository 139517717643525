import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { withRouter } from 'react-router';
import fetchMethodRequest from '../../../../config/service';
import EditDataTable from '../../CommonModals/EditDatatable';
import Loader from '../../../App/Loader';
import * as documentsAPIs from '../../../../shared/documentsApis';
class DocumentMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            documentSources: [],
            documentTypes: [],
            relevences: [],
            filterCriteria: {
                limit: this.getrowsPerPage(),
                page: 1,
                criteria: [],
                sortfield:'createdDateUTC'
            },
            isLoading: false,
            progress: true,
            selCategories: [],
            selDocumentSources: [],
            selDocumentTypes: [],
            selRelevences: [],
        }
    }

    componentDidMount() {  
        this.editdt.resetPaginator();
        this.getInitialDocumentsDetails();        
    }

    componentDidUpdate(prevProps, prevState) {               
        if (prevProps.selectedDealID !== this.props.selectedDealID) {
            this.getDocuments(this.props.selectedDealID, 'all');
        }
    }
    /**
     * get number of rows to show in a table based on user settings.
     * @returns {Number} number of rows
     */
    getrowsPerPage = () => {
        const loginDetails = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : null;
        const limit = (loginDetails && loginDetails.pageLimit) ? parseInt(loginDetails.pageLimit) : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10;
        return limit;
    }

    /**
     * Get the data needed for multiselect dropdowns from api.
     */
    getInitialDocumentsDetails = async () => {
        const categories = await documentsAPIs.getCategories();
        const sources = await documentsAPIs.getSources();
        const docTypes = await documentsAPIs.getDocTypes();
        const relevanceTypes = await documentsAPIs.getRelevenceTypes();
        this.setState({
            categories: categories,
            documentSources: sources,
            documentTypes: docTypes,
            relevences: relevanceTypes
        });
        if (this.props.selectedDealID) this.getDocuments(this.props.selectedDealID, 'all');
    }

    /**
     * create filter criteria array from top multiselect dropdown options
     * @returns {Array} filter criteria
     */
    getCriteriaFromTopFilters = () => {
        let criteria = [];
        if (this.state.selCategories && this.state.selCategories.length > 0) {
            const cat = this.state.selCategories.map((elem) => elem.Id);
            criteria.push({ "key": "DocumentCategoryID", "value": cat, "type": "in" });
        }
        if (this.state.selDocumentSources && this.state.selDocumentSources.length > 0) {
            const sources = this.state.selDocumentSources.map((elem) => elem.DocDataSourceName);
            criteria.push({ "key": "DocumentSource", "value": sources, "type": "in" });
        }
        if (this.state.selDocumentTypes && this.state.selDocumentTypes.length > 0) {
            const docTypes = this.state.selDocumentTypes.map((elem) => elem.Id);
            criteria.push({ "key": "DocumentTypeId", "value": docTypes, "type": "in" });
        }
        if (this.state.selRelevences && this.state.selRelevences.length > 0) {
            const relTypes = this.state.selRelevences.map((elem) => elem.Name);
            criteria.push({ "key": "RelevanceType", "value": relTypes, "type": "in" });
        }
        return criteria;
    }

    /**
     * Get all documents associated with the deal.
     * @param {String} dealId 
     * @param {String} type 
     * @param {Object} filter 
     */
    getDocuments = async (dealId, type, filter) => {
        this.setState({ isLoading: true });
        let api = `GetDocumentsData?DealId=${dealId}&Screen=DM&SPData=${false}`;
        let filterCriteriaT = filter ? filter : this.state.filterCriteria;
        if (type) {
            filterCriteriaT.page = 1;
            this.editdt.resetPaginator();
        }
        if (type === 'all') {
            await this.setState({
                selCategories: [],
                selDocumentSources: [],
                selDocumentTypes: [],
                selRelevences: [],
            });
        } else if (type === 'clearFilters') {
            this.editdt.getremoveFilters();
            return;
        }
        //Top dropdown filters.

        if (filterCriteriaT.criteria && filterCriteriaT.criteria.length > 0) {
            const filterElem = new Set(['DocumentCategoryID', 'DocumentSource', 'DocumentTypeId', 'RelevanceType']);
            const criteria = filterCriteriaT.criteria.filter((elem) => !filterElem.has(elem.key));
            filterCriteriaT.criteria = [...criteria];
        } else {
            filterCriteriaT.criteria = [];
        }
        filterCriteriaT.criteria = [...filterCriteriaT.criteria, ...this.getCriteriaFromTopFilters()];

        await this.setState({ filterCriteria: filterCriteriaT });
        api = api + `&filter=${JSON.stringify(this.state.filterCriteria)}`;
        fetchMethodRequest('GET', api).then(async (res) => {
            if (res) {
                const count = res.outResponse && res.outResponse.pagination && res.outResponse.pagination.totalCount ? res.outResponse.pagination.totalCount : 10;
                if (res.respCode && res.respCode === 200 && res.outResponse && res.outResponse.details && res.outResponse.details.length > 0) {
                    this.editdt.getValueForTable(res.outResponse.details, count);
                } else {
                    this.editdt.getValueForTable([], count);
                    this.editdt.resetPaginator();
                }
                this.setState({ isLoading: false });
            }
        });
    }

    //On filter
    toggle = (key,filter) => {            
        this.getDocuments(this.props.selectedDealID,'',filter);
    }

    /**
     * Table fields
     * @returns {Array} table fields array
     */
    getTableFields = () => {
        let data = [
            {
                "show": true,
                "textAlign": "left",
                "width": 150,
                "field": "fileName",
                "mobile": true,
                "header": "Document Name",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 50,
                "field": "relevanceType",
                "mobile": true,
                "header": "Document Relevance",
                "displayInSettings": true,
                filter: true,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 40,
                "field": "createdDateUTC",
                filterType: "num",
                "mobile": true,
                "fieldType": "dateOnly",
                "dateFormat": 'MM-DD-yyyy',
                "header": "Dt Uploaded",
                "displayInSettings": true,
                filter: false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 50,
                "header": "Uploaded By",
                "mobile": true,
                "field": "createdByUser",
                "displayInSettings": true,
                filter: false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 40,
                "field": "updatedDateUTC",
                filterType: "num",
                "mobile": true,
                "fieldType": "dateOnly",
                "dateFormat": 'MM-DD-yyyy',
                "header": "Dt Updated",
                "displayInSettings": true,
                filter: false,
                "sortable": true,
            },           
            {
                "show": true,
                "textAlign": "left",
                "width": 50,
                "header": "Updated By",
                "mobile": true,
                "field": "updatedByUser",
                "displayInSettings": true,
                filter: false,
                "sortable": true,
            },
            {
                "show": true,
                "textAlign": "left",
                "width": 85,
                field: "Actions",
                // fieldType === "Actions"
                "fieldType": "Actions",
                "header": "Actions",
                "filter": false,
                "sortable": false
            }
        ]
        return data;
    };

    /**
     * Shows action buttons in table.
     * @param {Object} col 
     * @returns {HTMLElement} template with buttons.
     */
    getActions = (col) => {        
        return <div className='dm-action-buttons'>
            <button className='btn  btn-primary mb-0 btn-sm'  onClick={() => {this.onClickEdit(col)}}>Edit Doc</button>
            {col.relevanceType !== 'CLM PACKET' && <button className='btn  btn-info mb-0 btn-sm' onClick={() => {this.onClickEdit(col,'update')}}>Edit Relv</button>}
            {col.relevanceType !== 'CLM PACKET' && <button className='btn  btn-danger mb-0 btn-sm' onClick={() => {this.onClickEdit(col,'newVer')}}>New Ver</button>}
           
        </div>
    }

    /**
     * on click action buttons functionality.
     * on click Edit - if relevance type claim packet redirects to claim builder, for other relevance type redirects to PDF Editor and loads the PDF Documents.     
     * On click New Ver/Edit Rel - redirects to document upload screen.
     * @param {Object} data .
     * @param {String} [type] 
     */
    onClickEdit = async (data,type) => {
        const sharePointDeatils = await documentsAPIs.getSharepointDetails(this.props.selectedDealID, data.id);
        data.sharePointDetails = sharePointDeatils;
        if (type) {
            window.open(`/documentUpload?details=${encodeURIComponent(JSON.stringify({ ...data, opType: type }))}`);
        } else {
            if (this.props.selectedDeal && this.props.selectedDeal.SellerNickName) {
                data['SellerNickName'] = this.props.selectedDeal.SellerNickName;
                data['DealType'] = this.props.selectedDeal.DealType;
            }
            if (data.relevanceType === 'CLM PACKET') {
                const relDetails = data.documentRelevance && data.documentRelevance.length > 0 && data.documentRelevance[0] ? data.documentRelevance[0] : {};
                window.open(`/claimBuilder?companyID=${this.props.selectedDealID}&stateClaimID=${relDetails.stateClaimID}&state=${relDetails.state}&relevantSellerBusinessNameID=${data.businessNameID}&releveantSellerName=${data.SellerNickName}&OPPClaimID=${relDetails.oppClaimID}&claimAmount=${data.claimLatestEstimatedValue}&mappedNameID=${data.mappedNameID}&mappedName=${data.mappedName}&sharePointDetails=${JSON.stringify(data.sharePointDetails)}`);
            } else {
                window.open(`/pdfEditor?details=${encodeURIComponent(JSON.stringify(data))}`);
            }

        }

    }

   

    render() {
        return <div className='m-3 doc-maintenance'>
            <Loader loader={this.state.isLoading} className="screen-loader" />
            {/* Top multi select dropdown filters row starts */}
            <div className='row'>
                <div className='col-lg-10'>
                    <div className='row'>
                        <div className="form__form-group pb-2  col-lg-3">
                            <label className="form__form-group-label">Document Category</label>
                            <div className=' form__form-group-field'>
                                <MultiSelect value={this.state.selCategories} placeholder='Select Category' optionLabel='Name' options={this.state.categories}
                                    filter={true} filterPlaceholder="Search" filterBy="Name" style={{ width: '100%' }} appendTo={document.body} onChange={(e) => { this.setState({ selCategories: e.value }) }} />
                            </div>
                        </div>
                        <div className="form__form-group pb-2  col-lg-3">
                            <label className="form__form-group-label">Document Source</label>
                            <div className=' form__form-group-field'>
                                <MultiSelect value={this.state.selDocumentSources} placeholder='Select Source' optionLabel='DocDataSourceName' options={this.state.documentSources}
                                    filter={true} filterPlaceholder="Search" filterBy="Name" style={{ width: '100%' }} appendTo={document.body} onChange={(e) => { this.setState({ selDocumentSources: e.value }) }} />
                            </div>
                        </div>
                        <div className="form__form-group pb-2  col-lg-3">
                            <label className="form__form-group-label">Document Type</label>
                            <div className=' form__form-group-field'>
                                <MultiSelect value={this.state.selDocumentTypes} placeholder='Select Type' optionLabel='Name' options={this.state.documentTypes}
                                    filter={true} filterPlaceholder="Search" filterBy="Name" style={{ width: '100%' }} appendTo={document.body} onChange={(e) => { this.setState({ selDocumentTypes: e.value }) }} />
                            </div>
                        </div>
                        <div className="form__form-group pb-2  col-lg-3">
                            <label className="form__form-group-label">Document Relevance</label>
                            <div className=' form__form-group-field'>
                                <MultiSelect value={this.state.selRelevences} placeholder='Select Relevance' optionLabel='Name' options={this.state.relevences}
                                    filter={true} filterPlaceholder="Search" filterBy="Name" style={{ width: '100%' }} appendTo={document.body} onChange={(e) => { this.setState({ selRelevences: e.value }) }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-2 d-flex align-items-center justify-content-end dm-search-btn'>
                    <button className='btn btn-primary btn-sm mb-0' style={{ top: '2px' }} onClick={() => { this.getDocuments(this.props.selectedDealID,'go') }}>
                        Go
                    </button>
                    <button className='btn btn-primary btn-sm mb-0' style={{ top: '2px' }} onClick={() => { this.getDocuments(this.props.selectedDealID,'all') }}>
                        Reset
                    </button>
                </div>
                

            </div>
            {/* Top multi select dropdown filters row ends */}

            <div className='d-flex align-items-center justify-content-end dm-search-btn'>
                <button className='btn btn-primary btn-sm mb-0 cf-btn'  onClick={() => { this.getDocuments(this.props.selectedDealID,'clearFilters') }} >
                   &nbsp; Clear Filters&nbsp;
                </button>

            </div>

            {/* Data table */}
            <div className='mt-1'>
                <EditDataTable
                    onRef={(ref) => this.editdt = ref}
                    metaKeySelection={false}
                    nonEditable={true}
                    hidePreset={true}
                    getTableFields={this.getTableFields}
                    // strArray={this.state.strArray}
                    disableInlineEdit={this.disableInlineEdit}
                    // tablefieldsToShow={this.state.tablefieldsToShow}
                    // deleArray={this.state.deleArray}
                    // activeTab={this.state.activeTab}
                    // shownFieldTab={this.state.shownFieldTab}
                    type="DocumentMaintenance"
                    // onSort={this.sortChange}
                    filterCriteria={this.state.filterCriteria}
                    anyOneField={[]}
                    requiredFields={[]}
                    toggle={this.toggle}
                    getActions={this.getActions}
                    activeTab='DocMaintenance'
                    noShowHideColOpt={true}
                    defSortField='createdDateUTC'
                />

            </div>
        </div>
    }
}

export default withRouter(DocumentMaintenance);