// import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, Card } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import * as commonApi from "../../../shared/commonApi"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import CheckBox from '../../../shared/components/form/CheckBox';
import config from '../../../config/config';
import fetch from "../../../config/service";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configImages from '../../../config/configImages';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import ConfirmaionModel from './ConfirmationModal';

const Container1 = styled.div`
  display: flex;
`
const Container = styled.div`
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDragging
            ? 'skyblue' : 'lightgrey'};
`
const Container5 = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;


  display: flex;
  flex-direction: column;
`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  min-height: 100px;
`

class ColumnOrderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableFields: [],
            changedTableFields: '',
            selectTableFields: [],
            incomingPreferences: JSON.parse(JSON.stringify(this.props.tableFields)),
            notSelectedTableFields: [],
            openConfirmationModal: false,
            name: this.props.edited ? this.props.columnOrderPreferenceDetailsObj.label : "",
            hideFields: [],
            columnOrderById: {},
            updateClaim: this.props.edited,
            newClaim: !this.props.edited,
            columns: {
                'column-1': {
                    id: 'column-1',
                    title: 'To do',
                    taskIds: ['task-1', 'task-2', 'task-3', 'task-4']
                },
            },
            columnOrder: ['column-1'],
            finalArr: [],
            permissions: {},
            views: config.viewTypes,
            selectedViewType: ""
        };
    }

    componentDidMount = async () => {
        await this.getScreenFieldsData();
        if (this.props.edited) {
            await this.getColumnOrderDetailsById()
        }
    }
    getColumnOrderDetailsById = async (id) => {
        const results = await commonApi.getColumnOrderById(id ? id : this.props.columnOrderPreferenceDetailsObj.value);
        await this.setState({ columnOrderById: results })
    }

    /**
     * 
     * @param {String} type 
     * closing of model for show hide columns
     */

    closeShowHideColumnsModalForDeal = async (type) => {
        await this.setState({
            isopenColumnModal: false
        })
        if (type === "close") {
            this.props.closeShowHideColumnsModalForDeal('close', null, this.state.incomingPreferences)
        }
    }


    /**
     * 
     * @returns opening the confirmation model for Showing message
     */
    getConfirmaionModel() {
        return (
            <ConfirmaionModel
                openConfirmationModal={this.state.isopenColumnModal}
                closeConfirmationModal={this.closeShowHideColumnsModalForDeal}
                shownText={"Are you sure you want to close before Saving?"}
                confirm={this.submit}
            />
        )
    }
    /**
     * 
     * @param {String} viewType 
     * rearranging the data in order to show as columns
     */
    getScreenFieldsData = async (viewType) => {
        // if (viewType) {
        let columnOrder = this.state.columnOrder
        let originalTableFields = this.props.originalTableFields
        let columns = this.state.columns;
        let colOrder = this.props.tableFields;
        // let localcolumns = await JSON.parse(colOrder);
        let tempTableFields = colOrder;
        let tablenotKnownFields = originalTableFields.filter(function (el) {
            let index = tempTableFields.findIndex(obj => obj.field === el.field)

            if (index == -1) {
                return el
            }
        });
        if (tablenotKnownFields && tablenotKnownFields.length > 1) {
            tempTableFields = [...tempTableFields, ...tablenotKnownFields]
        }
        for (var column of tempTableFields) {
            let index = originalTableFields.findIndex(obj => obj.field === column.field)
            if (index !== -1) {
                if (column.field === originalTableFields[index].field) {
                    column.header = originalTableFields[index].header
                }
            }
        }
        if (tempTableFields) {
            let index = tempTableFields.findIndex(obj => obj.field === null)
            if (index !== -1) tempTableFields.splice(index, 1)
        }

        if (originalTableFields) {
            for (var column of tempTableFields) {
                let isPresentinlocal = originalTableFields.find(item => item.field === column.field);
                if (isPresentinlocal === undefined) {
                    let deleteindex = tempTableFields.findIndex(obj => obj.field === column.field)
                    tempTableFields.splice(deleteindex, 1)
                }
                tempTableFields = tempTableFields
            }
        }

        if (tempTableFields.length > 15) {
            columns['column-1'].taskIds = tempTableFields
            columnOrder = ['column-1']
        } else {
            columns['column-1'].taskIds = tempTableFields
            columnOrder = ['column-1']
        }
        this.setState({
            tableFields: tempTableFields,
            changedTableFields: tempTableFields,
            hideFields: this.props.columnOrderPreferenceDetailsObj ? this.props.columnOrderPreferenceDetailsObj.hideFields : [],
            columns: columns,
            columnOrder: columnOrder
        })
        // }
    }

    /**
     * 
     * @param {Object} newState 
     * Dividing the array based on length
     */
    divideArray = async (newState) => {
        let tempFields = [...newState.columns['column-1'].taskIds, ...newState.columns['column-2'].taskIds, ...newState.columns['column-3'].taskIds,]
        let columns = this.state.columns
        await this.setState({
            columns: columns
        })

    }
    /**
     * 
     * @param {Array} result 
     * @returns On drag and drop of column fields arraning the order
     */
    onDragEnd = result => {
        const { destination, source } = result
        if (!destination) {
            return
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const start = this.state.columns[source.droppableId]
        const finish = this.state.columns[destination.droppableId]

        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds)
            let changedParam = start.taskIds[source.index]
            newTaskIds.splice(source.index, 1)
            newTaskIds.splice(destination.index, 0, changedParam)

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            }

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn
                }
            }

            this.setState(newState)
            return
        }

        // Moving from one list to another
        const startTaskIds = Array.from(start.taskIds)
        let ChangedParam = startTaskIds[source.index]
        startTaskIds.splice(source.index, 1)
        const newStart = {
            ...start,
            taskIds: startTaskIds
        }

        const finishTaskIds = Array.from(finish.taskIds)
        finishTaskIds.splice(destination.index, 0, ChangedParam)
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        }
        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        }

        this.setState(newState)
        this.divideArray(newState)

    }
    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     *  Onchange checkbox
     */
    onChange = async (event, column) => {
        let columns = this.state.columns
        if (event && event.target.name && columns[column] && columns[column].taskIds && columns[column].taskIds.length > 0) {
            columns[column].taskIds.map(col => {
                if (col.field === event.target.name) {
                    col.checked = !col.checked
                }
                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }
    /**
    * 
    * @param {*} cardtype 
    * @param {*} value 
    * @param {*} data 
    * @param {*} dataType 
    * showing the new exhibit values
    */
    getnewExistingValues = async (cardtype, value, data, dataType) => {
        let isSplitt_States = this.state.hideFields
        if (value && value.field && isSplitt_States && isSplitt_States.length > 0) {
            isSplitt_States.map(col => {
                if (col.field === value.field) {
                    col.checked = !col.checked
                }
                return col
            })
        }
        this.setState({
            hideFields: isSplitt_States,
        })

    }
    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     * @param {String} changedField 
     *  Onchange of width
     */
    onChangeWidth = async (event, column, changedField) => {
        let columns = this.state.columns
        if (event && columns[column] && columns[column].taskIds && columns[column].taskIds.length > 0) {
            columns[column].taskIds.map(col => {
                if (col.field === changedField) {
                    if (parseInt(event.target.value)) {
                        col.width = parseInt(event.target.value)
                    } else {
                        col.width = ""
                    }
                }

                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }

    /**
     * closing of models
     */
    getCloseData = () => {

        this.closeShowHideColumnsModalForDeal()

    }
    /**
     * 
     * @param {Array} values 
     * submit function on click of submit 
     */
    submit = async (values) => {
        this.setState({ disabledSaveButton: true });
        let method, url, body = {}
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
        if (this.state.updateClaim) {
            body = this.state.columnOrderById
            body.userPreference = this.state.columns["column-1"].taskIds
            body.hideFields = this.state.hideFields
            body.columnOrderName = this.state.name
            method = "PUT"
            url = `columnOrderPreference/${this.props.columnOrderPreferenceDetailsObj.value}`

        } else {
            method = "POST"
            body = { columnOrderName: this.state.name, screen: this.props.type, userPreference: this.state.columns["column-1"].taskIds, hideFields: this.state.hideFields, userId: loginCredentials._id }
            url = 'columnOrderPreference'
        }
        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode) {
                    await this.getColumnOrderDetailsById(response.columnOrderPreferenceId)
                    await this.props.closeShowHideColumnsModalForDeal(this.state.columnOrderById)
                }
                this.setState({ disabledSaveButton: false });
            })
            .catch((err) => {
                return err;
            });


    }
    /**
   * Moving Columns from left to right
   */
    moveColumnsFromLeftToRight = async () => {
        if (this.state.columns) {
            let originalColumns = this.state.columns;
            let originalColumnsForComparing = this.state.columns ? JSON.parse(JSON.stringify(this.state.columns)) : null;
            let originalArray = this.state.hideFields ? this.state.hideFields : [];
            var newArray = originalColumns["column-1"].taskIds.filter(function (el) {
                return el.checked === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of originalColumns["column-1"].taskIds) {
                    var combinedObj = Object.assign({}, obj)
                    if (obj.checked === true) {
                        combinedObj.checked = true
                        combinedObj.show = false
                        originalArray.push(combinedObj)
                        originalColumnsForComparing["column-1"].taskIds.splice(originalColumnsForComparing["column-1"].taskIds.findIndex(a => a.field === obj.field), 1)
                    }
                }
                await originalArray.sort((a, b) => {
                    let fa = a.header.toLowerCase(),
                        fb = b.header.toLowerCase();
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                await this.setState({ columns: originalColumnsForComparing, hideFields: originalArray, })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })
            }
            this.setState({
                showErrorMessage: false
            })
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })

        }
    }
    /**
   * Moving Columns from right to left
   */
    moveColumnsFromRightToLeft = async () => {
        await this.setState({
            isLoading: true
        })
        if (this.state.hideFields) {
            let hiddenFields = this.state.hideFields;
            let hiddenFieldsForComparing = JSON.parse(JSON.stringify(this.state.hideFields));
            let originalColumns = this.state.columns;
            var newArray = hiddenFields.filter(function (el) {
                return el.checked === true
            });
            if (newArray && newArray.length > 0) {
                for (var obj of hiddenFields) {
                    var combinedObj = Object.assign({}, obj)
                    if (obj.checked === true) {
                        combinedObj.checked = true
                        combinedObj.show = true
                        originalColumns["column-1"].taskIds.push(combinedObj)
                        hiddenFieldsForComparing.splice(hiddenFieldsForComparing.findIndex(e => e.field === obj.field), 1)
                    }
                }
                await this.setState({ columns: originalColumns, hideFields: hiddenFieldsForComparing })
            } else {
                this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })
            }
        } else {
            this.setState({ apierrorResponse: true, apierrorMessage: `Please select any Fields before clicking` })

        }
    }
    onNameChange = async (e) => {
        await this.setState({ name: e.target.value })
    }
    selectViewType = async (e) => {
        await this.setState({ selectedViewType: e })
        await this.getScreenFieldsData(this.state.selectedViewType);

    }
    setType = async (key) => {
        key === "updateClaim" ? this.setState({ updateClaim: true, newClaim: false, name: this.props.columnOrderPreferenceDetailsObj.label }) : this.setState({ updateClaim: false, newClaim: true, name: "" })
    }

    render() {
        const { t, handleSubmit, viewType, isShownnFieldType } = this.props;
        return (
            <Modal isOpen={this.props.isOpenShowHideColumnsModal} fade={false}
                className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>
                <ModalHeader className="modal__header" style={{ padding: 16, justifyContent: "center" }}>
                    <button className="lnr lnr-cross modal__close-btn" type="button"
                        style={{ color: "black" }}
                        onClick={() => this.props.closeShowHideColumnsModalForDeal()}
                    />

                    <b>Manage Columns Order </b>
                </ModalHeader>
                <ModalBody className='deleteModalBody' style={isShownnFieldType ? { margin: "auto" } : {}}>
                    <form onSubmit={handleSubmit(this.submit)} >
                        <div >
                            <div className="row ml-1 justify-content-lg-center">
                                {this.props.edited ? <div className="row ml-1 justify-content-lg-center">
                                    <RadioButton inputId="updateClaim" name="updateClaim" value={this.state.updateClaim} onChange={(e) => this.setType("updateClaim")} checked={this.state.updateClaim} />
                                    <label className="ml-2">Update Existing Column Order</label>
                                    <RadioButton className='ml-5' inputId="newClaim" name="newClaim" value={this.state.newClaim} onChange={(e) => this.setType("newClaim")} checked={this.state.newClaim} />
                                    <label className="ml-2">Create New Column Order</label>
                                </div> : null}
                            </div>
                            <div className='mt-1 mb-2 d-flex'>
                                <p className='mr-2 mt-2'> {"Name :"}</p>
                                <InputText
                                    type="Name"
                                    name='Name'
                                    id='Name'
                                    value={this.state.name}
                                    style={{
                                        height: 37,
                                        width: "90%"
                                    }}
                                    onChange={(e) => this.onNameChange(e)}
                                    placeholder={'Enter Column Order Name'}
                                />
                            </div>

                            <>
                                <div className='row'>
                                    <div className=' col-md-5' style={{ height: "73vh", overflow: "auto" }}>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Container1>
                                                {this.state.columnOrder.map(columnId => {
                                                    const column = this.state.columns[columnId]

                                                    const tasks = column.taskIds
                                                    return (
                                                        <Container5>
                                                            <Droppable droppableId={column.id} type="TASK">
                                                                {(provided, snapshot) => (
                                                                    <TaskList
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        {tasks.map((task, index) => (

                                                                            task.header && task.field ?
                                                                                <Draggable
                                                                                    key={task.field}
                                                                                    draggableId={task.field}
                                                                                    index={index}
                                                                                >
                                                                                    {(provided, snapshot) => (

                                                                                        <Container
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            ref={provided.innerRef}
                                                                                            isDragging={snapshot.isDragging}
                                                                                        ><div className="d-flex">
                                                                                                <Field
                                                                                                    className='mr-0 my-0 mt-0'
                                                                                                    key={task.field}
                                                                                                    name={task.field ? task.field : null}
                                                                                                    component={CheckBox}
                                                                                                    checked={viewType === 'grid' ? task.mobile : task.checked}
                                                                                                    value={viewType === 'grid' ? task.mobile : task.checked}
                                                                                                    onChange={(e) => this.onChange(e, columnId)}
                                                                                                    label={task.header ? `${t(task.header)}` : "Selection"}
                                                                                                />
                                                                                                <span className="ml-auto" style={{ width: "21%" }}>
                                                                                                    <input
                                                                                                        className='ml-3'
                                                                                                        type="text"
                                                                                                        style={{ height: 24, width: "70%" }}
                                                                                                        key={`${task.field}+width`}
                                                                                                        name={task.field ? `${task.field}+width` : null}
                                                                                                        component={DefaultInput}
                                                                                                        defaultValue={task.width}
                                                                                                        value={task.width}
                                                                                                        onChange={(e) => this.onChangeWidth(e, columnId, task.field)}
                                                                                                        label={task.header ? `${t(task.header)}--${t(task.width)}px` : "Selection"}
                                                                                                    /></span>
                                                                                            </div>
                                                                                        </Container>

                                                                                    )}
                                                                                </Draggable>
                                                                                : null
                                                                        )
                                                                        )}
                                                                        {provided.placeholder}
                                                                    </TaskList>
                                                                )}
                                                            </Droppable>
                                                        </Container5>
                                                    )
                                                })}
                                            </Container1>
                                        </DragDropContext>
                                    </div>
                                    <div className='col-sm-2' style={{ placeItems: "center", top: "100px" }}>
                                        <span className='ml-5' style={{ textAlign: "center", display: "grid" }}>
                                            <FontAwesomeIcon
                                                style={{ width: "75px", cursor: 'pointer', }}
                                                icon={faArrowRight}
                                                className='fa-4x'
                                                onClick={() => this.moveColumnsFromLeftToRight()}
                                            />
                                            <FontAwesomeIcon
                                                style={{ width: "75px", cursor: 'pointer', }}
                                                icon={faArrowLeft}
                                                className='fa-4x mt-3 mb-3'
                                                onClick={() => this.moveColumnsFromRightToLeft()}
                                            />

                                        </span>
                                    </div>
                                    <div className='col-md-5' style={{ height: "73vh", overflow: "auto" }}>
                                        <Card className="pb-0" style={{ overflow: 'auto', border: "1px solid grey" }}>
                                            {this.state.hideFields && this.state.hideFields.length > 0 ? this.state.hideFields.map((notShowPreference, index2) => {
                                                return <div className='d-flex m-1'>
                                                    <Field
                                                        className='mr-0 my-0 mt-0'
                                                        key={notShowPreference.field}
                                                        name={notShowPreference.field ? notShowPreference.field : null}
                                                        component={CheckBox}
                                                        checked={viewType === 'grid' ? notShowPreference.mobile : notShowPreference.checked}
                                                        value={viewType === 'grid' ? notShowPreference.mobile : notShowPreference.checked}
                                                        onChange={(e) => this.getnewExistingValues("newExisting", notShowPreference, this.state.hideFields)}
                                                        label={notShowPreference.header ? `${t(notShowPreference.header)}` : "Selection"}
                                                    />
                                                </div>

                                            }) : null}

                                        </Card>
                                    </div>
                                </div>
                                <div className='col-sm-12 text-center'>
                                    <Button
                                        color="primary"
                                        outline
                                        type="button"
                                        onClick={() => this.props.closeShowHideColumnsModalForDeal()}
                                        className='deleteModalBtn marginRight'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color='primary'
                                        outline
                                        type="submit"
                                        disabled={(!this.state.name || (this.state.name && this.state.disabledSaveButton)) ? true : false}
                                        className='deleteModalBtn'
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </>

                        </div>


                    </form>
                    {this.state.isopenColumnModal ?
                        this.getConfirmaionModel() : null
                    }
                </ModalBody>
            </Modal>
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ColumnOrderModal = reduxForm({
    form: "ColumnOrderModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(ColumnOrderModal);

export default withTranslation('common')(ColumnOrderModal);