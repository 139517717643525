import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import apiCalls from '../../../../config/apiCalls'
import fetchMethodRequest from '../../../../config/service';
import store from '../../../App/store';
import UserRequestFilters from "../ReportBugFilters"
// import RolePermissions from '../../CommonModals/Permissions';
import NotesModal from "../../../../containers/Cruds/CommonModals/NotesModal"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

// config file
export default class ReportedBug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Ad HOC Report',
            OpenModel: false,
            dropDownFields: ["issueType", "opra2Page", "businessPriority", "issueStatus"],
            issueType: [{ 'label': "Feature", "value": "Feature" },
            { 'label': "Bug", "value": "Bug" }],
            opra2Page: [{ 'label': "Property Editor", "value": "Property Editor" },
            { 'label': "Claims Manager", "value": "Claims Manager" },
            { 'label': "Deal Management", "value": "Deal Management" },
            { 'label': "Other", "value": "Other" }],
            businessPriority: [{ 'label': "Critical", "value": "Critical" },
            { 'label': "High", "value": "High" },
            { 'label': "Medium", "value": "Medium" },
            { 'label': "Low", "value": "Low" }],
            issueStatus: [{ 'label': "Not Started", "value": "Not Started" },
            { 'label': "Under Developmen", "value": "Under Developmen" },
            { 'label': "In Testing", "value": "In Testing" },
            { 'label': "Solved", "value": "Solved" }]
        };
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }


    static getDerivedStateFromProps(props, state) {
        let storeData = store.getState()
        let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
        return { languageData: languageData };
    }

    setModel = async (flag) => {
        await this.setState({
            OpenModel: flag
        })
    }

    componentDidMount = async () => {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        // await this.getStatusOptions()
        await this.getDropDownValues()
        let rolePermissions = JSON.parse(localStorage.getItem("rolePermissions"));
        let opra2Page = []
        if (rolePermissions) {
            Object.entries(rolePermissions).forEach(([key, value]) => {
                if (value === "Edit") {
                    opra2Page.push({ label: key, value: key })
                } // key and corresponding value
            });
        }
        const sorted = opra2Page.sort((a, b) => {
            let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        this.setState({ opra2Page: sorted })
    }
    getTableFields = () => {
        let data = [
            {
                "show": true,
                "textAlign": "center",
                "width": 90,
                "fieldType": 'Actions',
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "displayInSettings": true,
                "sortable": false
            },
            {
                'show': true,
                'width': 95,
                "type": "text",
                "field": "issueID",
                "filterType": "num",
                allowInt: true,
                "header": "Request ID",
                "id": "issueID",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 105,
                "field": "issueType",
                "header": "Request Type",
                "id": "issueType",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "type": "dropDown",
                "options": [{ 'label': "Feature", "value": "Feature" },
                { 'label': "Bug", "value": "Bug" }],
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "field": "issueStatus",
                "header": "Status",
                "id": "issueStatus",
                filter: true,
                "type": "dropDown",
                "options": [{ 'label': "Not Started", "value": "Not Started" },
                { 'label': "Under Developmen", "value": "Under Developmen" },
                { 'label': "In Testing", "value": "In Testing" },
                { 'label': "Solved", "value": "Solved" }],
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 100,
                "fieldType": "Date",
                "filterType": "num",
                "dateFormat": 'MM-DD-yyyy',
                "field": "issueReportedDate",
                "header": "Reported Date",
                "id": "issueReportedDate",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 100,
                "type": "text",
                "field": "createdBy",
                "header": "Reported By",
                "id": "createdBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 140,
                "field": "opra2Page",
                "header": "OPRA2 Page",
                "id": "opra2Page",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "type": "dropDown",
                "options": [{ 'label': "Property Editor", "value": "Property Editor" },
                { 'label': "Claims Manager", "value": "Claims Manager" },
                { 'label': "Deal Management", "value": "Deal Management" },
                { 'label': "Other", "value": "Other" }],
            },
            {
                'show': true,
                'width': 90,
                "type": "dropDown",
                "options": [{ 'label': "Critical", "value": "Critical" },
                { 'label': "High", "value": "High" },
                { 'label': "Medium", "value": "Medium" },
                { 'label': "Low", "value": "Low" }],
                "field": "businessPriority",
                "header": "Priority",
                "id": "businessPriority",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 450,
                "type": "textarea",
                "field": "issueDescription",
                "header": "Request Description",
                "id": "issueDescription",
                "fieldType": "viewTextInModal",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 150,
                "value": "",
                "fieldType": "multipleprofile",
                "name": "fileNames",
                "field": "fileNames",
                "header": "Attachments",
                "id": "fileNames",
                "customUpload": true,
                "placeholder": "Profile",
            },
            {
                'show': true,
                'width': 350,
                "type": "text",
                "field": "notes",
                "header": "Notes/ Updates",
                "fieldType": 'custumChangeFieldValues',
                displayfieldtype: "notesModal",
                "id": "notes",
                filter: false,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 110,
                "value": "",
                "fieldType": "RedirectLink",
                "name": "referanceLink",
                "field": "referanceLink",
                "header": "Recording Link",
                "id": "referanceLink",
            },
            {
                'show': true,
                'width': 100,
                "type": "text",
                "filterType": "num",
                "field": "targetReleaseDate",
                "header": "Target Release Date",
                "id": "targetReleaseDate",
                "fieldType": "Date",
                "dateFormat": 'MM-DD-yyyy',
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "filterType": "num",
                "field": "sprint",
                allowInt: true,
                "header": "Sprint",
                "id": "sprint",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 100,
                "fieldType": "Date",
                "filterType": "num",
                "dateFormat": 'MM-DD-yyyy',
                "field": "issueResolvedDate",
                "header": "Resolved Date",
                "id": "issueResolvedDate",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 150,
                "type": "text",
                "field": "issueRelatedDealName",
                "header": "Deal Name",
                "id": "issueRelatedDealName",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "field": "jiraTicket",
                "header": "Jira Ticket",
                "id": "jiraTicket",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "field": "issueFixDevelopedBy",
                "header": "Fixed By",
                "id": "issueFixDevelopedBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "field": "issueTestedBy",
                "header": "Tested By",
                "id": "issueTestedBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "field": "updatedBy",
                "header": "Last Updated By",
                "id": "updatedBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "fieldType": "Date",
                "filterType": "num",
                "dateFormat": 'MM-DD-yyyy',
                "field": "updatedAtUtc",
                "header": "Last Updated Date",
                "id": "updatedAtUtc",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }

        ]
        return data;
    };
    getFormFields = () => {
        return ([
            {
                'show': true,
                'width': 90,
                "name": "issueType",
                "label": "Request Type",
                "placeholder": "Request Type",
                "id": "issueType",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "type": "dropDown",
                "options": this.state.issueType,
            },
            {
                'show': true,
                'width': 90,
                "name": "opra2Page",
                "label": "OPRA2 Page",
                "placeholder": "OPRA2 Page",
                "id": "opra2Page",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
                "type": "dropDown",
                "options": this.state.opra2Page,
            },

            {
                'show': true,
                'width': 90,
                "type": "textarea",
                "name": "issueDescription",
                "row": "8",
                "label": "Description",
                "placeholder": "Description",
                "id": "issueDescription",
                required: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                'show': true,
                'width': 90,
                "type": "dropDown",
                "label": "Priority",
                "placeholder": "Priority",
                "options": this.state.businessPriority,
                "name": "businessPriority",
                "id": "businessPriority",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "name": "jiraTicket",
                "label": "Jira Ticket ",
                "placeholder": "Jira Ticket",
                "id": "jiraTicket",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                'show': true,
                'width': 90,
                "type": "text",
                "name": "sprint",
                "label": "Sprint ",
                "placeholder": "Sprint",
                "id": "sprint",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "name": "issueStatus",
                "id": "issueStatus",
                "label": "Status",
                "placeholder": "Status",
                filter: true,
                "type": "dropDown",
                "options": this.state.issueStatus,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            }, {
                'show': true,
                'width': 90,
                "type": "date",
                "dateFormat": 'MM-DD-yyyy',
                "filterType": "num",
                "name": "issueResolvedDate",
                "label": "Issue Resolved Date",
                "placeholder": "Issue Resolved Date",
                "id": "issueResolvedDate",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "textarea",
                "name": "notes",
                "label": "Notes ",
                "placeholder": "Notes",
                "id": "notes",
                filter: false,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: false,
                disable: true
            },
            {
                value: "",
                type: "autoComplete",
                "name": "issueRelatedDealName",
                "id": "issueRelatedDealName",
                "label": "Deal Name",
                "placeholder": "Deal Name",
                className: "inputLabel",
                dealValFormat: true,
                dealDropDown: true,
                searchField: "SellerNickName",
                id: "SellerName",
                required: false,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "name": "issueFixDevelopedBy",
                "label": "Fixed By",
                "placeholder": "Fixed By",
                "id": "issueFixDevelopedBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },
            {
                'show': true,
                'width': 90,
                "type": "text",
                "name": "issueTestedBy",
                "label": "Tested By",
                "placeholder": "Tested By",
                "id": "issueTestedBy",
                filter: true,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: true,
            },

            {
                'show': true,
                'width': 90,
                "type": "date",
                "filterType": "num",
                "name": "targetReleaseDate",
                "header": "Target Release Date",
                "label": "Target Release Date",
                "placeholder": "Target Release Date",
                "id": "targetReleaseDate",
                "dateFormat": 'MM-DD-yyyy',
                filter: false,
                "displayInSettings": true,
                "displayInFilter": true,
                sortable: false,
            },
            {
                'show': true,
                "value": "",
                "type": "text",
                "name": "referanceLink",
                "field": "referanceLink",
                "placeholder": "Recording Link",
                "label": "Recording Link",
                "id": "referanceLink",
            },
            {
                "value": "",
                "type": "multipleprofile",
                "name": "fileNames",
                "label": "Attachments",
                "id": "fileNames",
                "customUpload": true,
                "placeholder": "Profile",
            },
        ]);
    }

    getDropDownValues = () => {
        let url = apiCalls.userRequestConfiguration;
        if (url != null) {
            url += '?filter={"sortfield":"sequence","direction":"asc","criteria":[{"key":"Screen","value":"UserRequests","type":"eq"}]}'
            fetchMethodRequest('GET', url, {}, null)
                .then(async (response) => {
                    if (response && response.userRequestConfigurations && response.userRequestConfigurations.length) {
                        for (let obj of response.userRequestConfigurations) {
                            if (obj.key != null && obj.key != "opra2Page" && this.state.dropDownFields.includes(obj.key)) {
                                await this.setState({
                                    [obj.key]: obj.options
                                })
                            }
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    getFiltercriteria = async () => {
        let criteria = await this.datatableref.getFilterCriteria()
        // console.log("criteria", criteria)
        return criteria
    }

    getCall = async (filterCriteria) => {
        await this.datatableref.getDataFromServer(filterCriteria);
    }

    changeNoteClaims = (item, fromGrid) => {
        let Comments = item.notes
        console.log("item", item)
        this.setState({
            isnotesModal: true,
            Comments: Comments,
            rfiValues: item,
            fromGrid: fromGrid ? true : false
            // notesSno:             // notesSno: item

        })
    }
    cancelNotes = async (type, id) => {
        this.setState({
            isnotesModal: false,
            openpaymentsModal: false,
            selectedRowPayments: ""
        })

    }

    saveClaimNotes = async (comment, item, Data, editComment, edited) => {
        let claimNotes = Data && Data.length > 0 ? Data : []
        if (Data && Data.length === 0 || !Data) {
            if (editComment !== undefined) {
                // claimNotes.shift()
                // claimNotes.unshift({ note: editComment })
                claimNotes[0].note = editComment.note
            }
            if (comment !== undefined) {
                claimNotes.unshift({ note: comment })
            }
        }

        this.cancelNotes()
        let criteria = await this.datatableref.getFilterCriteria()
        if (this.state.fromGrid) {
            this.datatableref.getDataFromServer(criteria)
        } else {
            this.datatableref.setNotesToChange(claimNotes)
        }
        // await this.setNotesToChange(claimNotes)
    }



    getNotesModal() {
        return (
            <NotesModal
                openNotesModal={this.state.isnotesModal}
                cancelReset={this.cancelNotes}
                saveClaimNotes={this.saveClaimNotes}
                claimNotesEdit={true}
                url={"reportNewBug"}
                notesSno={this.state.notesSno}
                screenType={"rfiNotes"}
                rfiValues={this.state.rfiValues}
                formType={this.state.formType}
                Comments={this.state.Comments}
                type={"Notes1"}
            />
        )
    }

    getTaskFilters = () => {
        return (
            <UserRequestFilters
                criteria={this.getFiltercriteria}
                getBasicCall={this.getCall}
            />
        )
    }

    custumChangeFieldValues = (item, column) => {
        // if (column.displayfieldtype === "notesModal") {notes

        return (
            <div style={{ display: "flex", width: column.bodyStyle.width }}>
                <span> <FontAwesomeIcon icon={faExternalLinkAlt}
                    className='pl-1 mr-2'
                    data-toggle="tool-tip"
                    // validate={[required]}
                    title="click to see all Notes"
                    style={{ width: 20, color: "grey" }}
                    onClick={() => this.changeNoteClaims(item, column.header)}
                /></span>
                <div
                    className="textElipses displayintablefor3lines " title={item[column.field] && item[column.field].length > 0 ? item[column.field][0].note : ""}>

                    <span
                        onClick={() => this.changeNoteClaims(item, column.header)}
                    >
                        {item[column.field] && item[column.field].length > 0 ? item[column.field][0].note : ""}
                    </span>
                </div>
            </div>
        )
        // }
    }

    render() {
        return (
            <span>
                {this.state.isnotesModal ? this.getNotesModal() : null}
                <DataTables
                    onRef={(ref) => (this.datatableref = ref)}
                    getTableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sortField={"IssueReportedDate"}
                    exportRequried={false}
                    printRequried={false}
                    custumChangeFieldValues={this.custumChangeFieldValues}
                    addRequried={true}
                    gettaskFilters={this.getTaskFilters}
                    setModel={this.setModel}
                    editRequired={true}
                    isClearFilters={true}
                    deleteRequired={true}
                    viewRequired={true}
                    settingsRequired={false}
                    filterRequired={true}
                    changeNoteClaims={this.changeNoteClaims}
                    gridRequried={false}
                    fileUploadLimit={5}
                    sample={false}
                    dontShowTitle={true}
                    globalSearchFieldName="description"
                    globalSearch={""}
                    type="User Requests"
                    displayright={true}
                    icon="tag"
                    folderPath="userRequests"
                    routeTo="userRequests"
                    displayViewOfForm="modal"
                    apiResponseKey={"reportedBugs"}
                    apiUrl={apiCalls.ReportNewBug}
                    entityType="reportedBug"
                />

            </span>
        );
    }
}