import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../config/apiCalls';
import fetch from '../../config/service'
// validate
// import validate from './validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { setUserAction } from '../../redux/actions/userActions';
import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import configMessages from '../../config/configMessages';
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class AvailableToReMapFilters extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      States: [
        { label: "AL", value: "AL" },
        { label: "AK", value: "AK" },
        { label: "AZ", value: "AZ" },
        { label: "AR", value: "AR" },
        { label: "CA", value: "CA" },
        { label: "CO", value: "CO" },
        { label: "CT", value: "CT" },
        { label: "DE", value: "DE" },
        { label: "FL", value: "FL" },
        { label: "GA", value: "GA" },
        { label: "HI", value: "HI" },
        { label: "ID", value: "ID" },
        { label: "IL", value: "IL" },
        { label: "IN", value: "IN" },
        { label: "IA", value: "IA" },
        { label: "KS", value: "KS" },
        { label: "KY", value: "KY" },
        { label: "LA", value: "LA" },
        { label: "ME", value: "ME" },
        { label: "MD", value: "MD" },
        { label: "MA", value: "MA" },
        { label: "MI", value: "MI" },
        { label: "MN", value: "MN" },
        { label: "MS", value: "MS" },
        { label: "MO", value: "MO" },
        { label: "MT", value: "MT" },
        { label: "NE", value: "NE" },
        { label: "NV", value: "NV" },
        { label: "NH", value: "NH" },
        { label: "NJ", value: "NJ" },
        { label: "NM", value: "NM" },
        { label: "NY", value: "NY" },
        { label: "NC", value: "NC" },
        { label: "ND", value: "ND" },
        { label: "OH", value: "OH" },
        { label: "OK", value: "OK" },
        { label: "OR", value: "OR" },
        { label: "PA", value: "PA" },
        { label: "PR", value: "PR" },
        { label: "RI", value: "RI" },
        { label: "SC", value: "SC" },
        { label: "SD", value: "SD" },
        { label: "TN", value: "TN" },
        { label: "TX", value: "TX" },
        { label: "UT", value: "UT" },
        { label: "VT", value: "VT" },
        { label: "VA", value: "VA" },
        { label: "DC", value: "DC" },
        { label: "WA", value: "WA" },
        { label: "WV", value: "WV" },
        { label: "WI", value: "WI" },
        { label: "WY", value: "WY" },
      ],
      filterCriteria: {},
      SelectedStates: [],
      DealType: [],
      DealTypeV2: ["BK", "CR"],
      isGreen25: true,
      propertyStatusArray: [],
      isExcludeRoyalties: true,
      excludeCoOwners: false,
      // isexcludePossiblyPaid: true,
      // excludePossiblyPaid: true,
      ranges: [">$25k"],
      selectedBK: true,
      selectedCR: true
    };
  }
  componentDidMount = async () => {
    this.searchsellerItems()
    await this.getDepartments()
    await this.getIssueNameCode()
    await this.getLegacyIssueNameCode()
    await this.setDefaultTeamOnInitialLoad()
    await this.getNewStatusForEditor()

    let userDetails = JSON.parse(localStorage.getItem('loginCredentials'))
    // let apiUrl=''
    this.setState({
      isLoading: true
    });
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'userObjId', value: userDetails['_id'], type: 'eq' }];
    fetch('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          isLoading: false,
          settingsData: settingsData,
          excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
        });
      }
    })
  }
  setDefaultTeamOnInitialLoad = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let departmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departMentIdArray = this.state.taskDepartmentItems.filter(obj => departMentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.setState({ Department: departmentArray, departMentIdArray: departMentIdArray })
    }
  }
  getNewStatusForEditor = async (val) => {

    let propertyStatusArray = [];
    let filterCriteria = {}

    filterCriteria['criteria'] = filterCriteria['criteria'] = [
      { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      // { key: 'propertyStatusFlag', value: true, type: 'eq', add_Filter: true },
      { key: 'availableToReMap', value: true, type: 'eq', add_Filter: true },
      // { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }

    ]

    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"

    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            propertyStatusArray.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ propertyStatusArray: propertyStatusArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ propertyStatusArray: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }
  //Issue Names Dropdown
  getIssueNameCode = async () => {
    let issueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }, { key: "BusinessNameIssueCode", value: "N/A", type: "ne" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            let index = issueNameCodes.findIndex(obj => obj.label === "Blank")
            let codeIndex = issueNameCodes.findIndex(obj => obj.label === name.BusinessNameIssueCode)
            if (codeIndex === -1) {
              if (name.BusinessNameIssueCode === "" && index === -1) {
                issueNameCodes.push({ label: "Blank", value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
              else if (name.BusinessNameIssueCode && name.BusinessNameIssueCode !== null) {
                issueNameCodes.push({ label: name.BusinessNameIssueCode, value: name.BusinessNameIssueCode, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
            }
          }

          await this.setState({ issueNameOptions: issueNameCodes })
        } else {
          this.setState({ issueNameOptions: [] })
        }
      }).catch((err) => {
        return err;
      });
  }
  //Issue Names Dropdown
  getLegacyIssueNameCode = async () => {
    let LegacyIssueNameCodes = []
    let filter = { "limit": 200, "page": 1, "criteria": [{ key: "DWRowIsCurrent", value: true, type: "eq" }] }
    let urlNames = `${apiCalls.businessnameissuecodeconfiguration}?filter=${JSON.stringify(filter)}`
    await fetch("GET", urlNames)
      .then(async (response) => {
        if (response && response.businessNameIssueCodeConfigurations) {
          for (let name of response.businessNameIssueCodeConfigurations) {
            let index = LegacyIssueNameCodes.findIndex(obj => obj.label === name.LegacyIssueID)
            let blankIndex = LegacyIssueNameCodes.findIndex(obj => obj.label === "Blank")
            if (index === -1) {
              if (name.LegacyIssueID === "" && blankIndex === -1) {
                LegacyIssueNameCodes.push({ label: "Blank", value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
              else if (name.LegacyIssueID && name.LegacyIssueID !== null) {
                LegacyIssueNameCodes.push({ label: name.LegacyIssueID, value: name.LegacyIssueID, title: name.BusinessNameIssueCodeDescription, BusinessNameIssueCodeId: name.BusinessNameIssueCodeId })
              }
            }
          }

          await this.setState({ LegacyIssueNameCodes: LegacyIssueNameCodes })
        } else {
          this.setState({ LegacyIssueNameCodes: [] })
        }
      }).catch((err) => {
        return err;
      });
  }
  onChangeDepartment = async (e) => {
    let DepartmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        DepartmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ Department: DepartmentArray, departMentIdArray: departmentIdArray, Department: e.value, })
    await this.props.onChangeDepartment(e.value, 'selectedDepartment', departmentIdArray)

    // await this.getDataFromServer(this.state.filterCriteria)
  }
  /*
   Fetch all Departments from the api 
   */
  getDepartments = () => {
    let apiUrl;
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id
            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  getReasonForEditor = async (val, key) => {
    var ReasonsArray = [], data = []
    let filterCriteria = {}
    let array = []
    if (val && val.length > 0) {
      // if (key) {
      //   data = val.split(",")
      // } else {
      for (var obj of val) {
        if (!array.includes(obj.status)) {
          if (obj.status) {
            data.push(obj.status)
          }
        }
      }

      filterCriteria['criteria'] = [{ key: 'status', value: data, type: 'in', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'availableToReMap', value: true, type: 'eq', add_Filter: true },
        // { key: 'screenType', value: ['Property Editor'], type: 'in', add_Filter: true }
      ];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            ReasonsArray.push({ label: reason.status + " - " + reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
          }
          await this.setState({ shownreasons: ReasonsArray })
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ shownreasons: [] })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }
  handleChange = async (e, type, key) => {
    var ranges = this.state.ranges
    let filtercriteria = this.props.filterCriteria
    var index;
    if (type === "selectedPropertyStatus") {
      await this.setState({
        selectedPropertyStatus: e.value,
        selectedPropertyReasons: "",
      })
      let dataforStatus = []
      await this.getReasonForEditor(e.value, key)
      if (e.value) {

        for (var obj of e.value) {
          if (!dataforStatus.includes(obj.status)) {
            if (obj.status) {
              dataforStatus.push(obj.status)
            }
          }
        }
      }
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0)
        filtercriteria["criteria"].push({
          key: "propertyStatus",
          value: dataforStatus,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "selectedPropertyReasons") {
      await this.setState({
        selectedPropertyReasons: e.value
      })
      let dataforReasons = []
      if (e.value) {

        for (var obj of e.value) {
          if (!dataforReasons.includes(obj.reason)) {
            if (obj.reason) {
              dataforReasons.push(obj.reason)
            }
          }
        }
      }

      // await this.getReasons(e.value)
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.selectedPropertyReasons && this.state.selectedPropertyReasons.length > 0)
        filtercriteria["criteria"].push({
          key: "propertyStatusReason",
          value: dataforReasons,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "newNameIssueCode") {
      await this.setState({
        newNameIssueCode: e
      })

      // await this.getReasons(e.value)
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "newNameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.newNameIssueCode && this.state.newNameIssueCode.length > 0)
        filtercriteria["criteria"].push({
          key: "newNameIssueCode",
          value: e,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "legacyNameIssueCode") {
      await this.setState({
        legacyNameIssueCode: e
      })

      // await this.getReasons(e.value)
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "legacyNameIssueCode")
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.state.legacyNameIssueCode && this.state.legacyNameIssueCode.length > 0)
        filtercriteria["criteria"].push({
          key: "legacyNameIssueCode",
          value: e,
          type: "in",
          add_Filter: true
        })
      // this.submit()
    } else if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
      })
    } else if (type === "DealType") {
      if (this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap") {
        await this.setState({
          DealTypeV2: e
        })
      } else {
        await this.setState({
          DealType: e
        })
      }
    } else {
      await this.setState({
        SelectedStates: e
      })
    }
    await this.setState({
      ranges: ranges
    })
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === configMessages.warningMessage || response.errorMessage === configMessages.tokenMessage)) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleChangeExcludeRoyalties = (type) => {
    this.setState({
      isExcludeRoyalties: !this.state.isExcludeRoyalties
    })
  }
  handleChangeExcludeCoOwners = (type) => {
    this.setState({
      excludeCoOwners: !this.state.excludeCoOwners
    })
  }
  handleChangePossiblyPaid = (type) => {
    this.setState({
      isexcludePossiblyPaid: !this.state.isexcludePossiblyPaid
    })
  }
  handleExcludePossiblyPaid = async (type) => {
    // this.setState({
    //   excludePossiblyPaid: !this.state.excludePossiblyPaid
    // })
    this.setState(prevState => ({
      excludePossiblyPaid: !prevState.excludePossiblyPaid
    }))
  }



  resetFilters = async () => {
    let filterCriteria = this.props.filterCriteria;
    // filterCriteria["criteria"] = []
    fetch('GET', `settings`).then(async (response) => {
      if (response && response.settings) {
        let settingsData = response.settings[0]
        this.setState({
          settingsData: settingsData,
          excludePossiblyDefaultEnable: settingsData.excludePossiblyDefaultEnable,
          excludePossiblyPaid: settingsData.excludePossiblyDefaultEnable
        });
      }
    })
    await this.setState({
      SelectedStates: [],
      DealType: [],
      DealTypeV2: ["BK", "CR"],
      selectedSeller: "",
      Department: [],
      departMentIdArray: [],
      excludeCoOwners: false,
      // isexcludePossiblyPaid: true,
      // excludePossiblyPaid: true,
      isExcludeRoyalties: true,
      selectedPropertyStatus: [],
      fromDate: "",
      selectedPropertyReasons: [],
      newNameIssueCode: [],
      legacyNameIssueCode: [],
      ranges: [">$25k"],
    })
    let Obj = {
      ranges: this.state.ranges,
      fromSummaries: true
    }
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let departmentArray = [], departMentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      departmentArray = user.secondaryDepartments ? user.secondaryDepartments : []
      departMentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departMentIdArray.push(user.departmentId)
      // Check to assign only teams that are  in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departMentIdArray = this.state.taskDepartmentItems.filter(obj => departMentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.props.onChangeDepartment(departmentArray, 'selectedDepartment', departMentIdArray)

      // this.submit()
    } else {
      await this.props.onChangeDepartment([], 'selectedDepartment', [])

    }
    this.setState({ Department: departmentArray.length > 0 ? departmentArray : null, departMentIdArray: departMentIdArray.length > 0 ? departMentIdArray : null })

    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      Obj.states = this.state.SelectedStates
    }
    if (this.state.DealType && this.state.DealType.length > 0) {
      Obj.DealType = this.state.DealType
    }
    if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
      Obj.DealType = this.state.DealTypeV2
    }
    this.submit("clear")

  }
  submit = (clear) => {
    let filterCriteria = {
      // criteria: this.props.filterCriteria && this.props.filterCriteria.criteria ? this.props.filterCriteria.criteria : [],
      criteria: [],
      sortfield: "SP1EstimatedValue",
      direction: "desc"
    }

    let Obj = {
    }
    let deals = [], dataforStatus = [], dataforReasons = []
    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.SelectedStates && this.state.SelectedStates.length > 0) {
      Obj.states = this.state.SelectedStates
    }
    if (this.state.DealTypeV2) {
      Obj.dealType = this.state.DealTypeV2
    }
    if (this.state.Department) {
      // let index = filterCriteria["criteria"].findIndex(obj => obj.key === "Department")
      // if (index !== -1) {
      //   filterCriteria["criteria"].splice(index, 1)

      // }
      // filterCriteria.criteria.push({ key: "Department", value: this.state.Department, type: "in" })
      Obj.departMentIdArray = this.state.departMentIdArray
    }
    if (this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0) {
      for (var obj of this.state.selectedPropertyStatus) {
        if (!dataforStatus.includes(obj.status)) {
          if (obj.status) {
            dataforStatus.push(obj.status)
          }
        }
      }
      filterCriteria.criteria.push({ key: "PropertyStatus", value: dataforStatus, type: "in" })
      Obj.status = dataforStatus
    }
    if (this.state.fromDate) {
      filterCriteria.criteria.push({ key: "ConfirmedOnsiteDate", value: this.state.fromDate, type: "dategte" })
      Obj.ConfirmedOnsiteDate = this.state.fromDate
    }

    if (this.state.selectedPropertyReasons && this.state.selectedPropertyReasons.length > 0) {
      for (var obj of this.state.selectedPropertyReasons) {
        if (!dataforReasons.includes(obj.reason)) {
          if (obj.reason) {
            dataforReasons.push(obj.reason)
          }
        }
      }
      filterCriteria.criteria.push({ key: "PropertyStatusReason", value: dataforReasons, type: "in" })
      Obj.reason = dataforReasons
    }
    if (this.state.newNameIssueCode && this.state.newNameIssueCode.length > 0) {
      filterCriteria.criteria.push({ key: "NewNameIssueCode", value: this.state.newNameIssueCode, type: "in" })
      Obj.newNameIssueCode = this.state.newNameIssueCode
    }
    if (this.state.legacyNameIssueCode && this.state.legacyNameIssueCode.length > 0) {
      filterCriteria.criteria.push({ key: "NameIssueCode", value: this.state.legacyNameIssueCode, type: "in" })
      Obj.legacyNameIssueCode = this.state.legacyNameIssueCode
    }
    if (this.state.isexcludePossiblyPaid) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "ConfirmedOnsite")
      if (index === -1) {
        filterCriteria.criteria.push({ key: "ConfirmedOnsite", value: this.state.isexcludePossiblyPaid, type: "eq" })
      }
      Obj.excludeNotConfirmedOnSite = "true"
    }
    if (this.state.excludePossiblyPaid) {
      filterCriteria.criteria.push({ key: "ExcludePossiblyPaid", value: this.state.excludePossiblyPaid, type: "eq" })
      Obj.excludePossiblyPaid = "true"
    }
    Obj.hitGoButton = true
    Obj.hit = true
    // Obj.newNameIssueCode = ["9.1"]
    // Obj.legacyNameIssueCode = ["9.2"]
    filterCriteria.page = 1
    this.props.setDataCriteriaforOpportunity(filterCriteria, Obj, clear)

  };

  render() {
    const { handleSubmit } = this.props;
    return (

      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
          <Card className="py-2 ">
            <CardBody className="p-0">
              <form className="form" onSubmit={handleSubmit(this.submit)}>
                <div className='w-100 property_Summary'>
                  {/* {
                      this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 ?
                        <div className="d-flex align-items-center" style={this.props.tabNo % 2 == 0 || this.props.tabNo == 0 ? { width: "100%", backgroundColor: "#f5eee3", marginBottom: "5px", height: "40px" } : { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" }}>
                          <h4 className='ml-2'><b>{this.props.tabType}: </b>
                            {this.props.tabDescriptionArray}</h4></div> :
                        null
                    } */}
                  <div className='row'>
                    <div className='col-lg-4'>
                      <label className='statusReason'>Deal</label>
                      <div >
                        <AutoComplete
                          value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                          // value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? this.state.selectedSeller.EstateName : this.state.selectedSeller}
                          suggestions={this.state.filteredSuggestions}
                          completeMethod={this.searchsellerItems}
                          minLength={1}
                          itemTemplate={this.editorTemplateForDeal}
                          field={"EstateName"}
                          style={{ width: "100%" }}
                          placeholder='Select Deal'
                          dropdown={true}
                          onChange={(e) => this.handleChange(e, 'Seller')}
                          appendTo={document.body} />
                      </div>
                    </div>
                    <div className='row col-lg-4'>
                      <div className='col-lg-6'>
                        <label className='statusReason'>Deal Type</label>
                        <div className="form__form-group-field">
                          <MultiSelect
                            // id={item.id}
                            name={"dealType"}
                            component={MultiSelect}
                            filter={true}
                            onChange={(e) => this.handleChange(e.value, "DealType", "in")}
                            type={"dropDown"}
                            value={this.state.DealTypeV2}
                            style={{ width: "100%" }}
                            options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" }]}
                            placeholder={""}
                          // isDisable={item.isDisable ? item.isDisable : false}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className="mr-1 kanban_filter_label" id="department" style={{ marginTop: 3 }}>
                          Team:
                        </label>
                        <MultiSelect
                          id="department"
                          className={"form__form-group-field "}
                          filter={true}
                          style={{ width: "100%", height: 32 }}
                          maxSelectedLabels={1}
                          value={this.state.Department}
                          options={this.state.taskDepartmentItems}
                          onChange={(e) => this.onChangeDepartment(e, "Department")}
                          placeholder='Select Team'
                        />
                      </div>
                    </div>
                    <div className='col-lg-2 ml-3 pl-4'>
                      <label className='statusReason'>State</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          component={MultiSelect}
                          filter={true}
                          onChange={(e) => this.handleChange(e.value, "States", "in")}
                          type={"dropDown"}
                          value={this.state.SelectedStates}
                          style={{ width: "100%" }}
                          options={this.state.States}
                          placeholder={"Select State"}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                    <div className='row col-lg-2 ml-4'>
                      <Button color="primary" style={{ marginTop: "25px" }} outline type="submit">Go</Button>
                      <div className="mt-2" >
                        <span className=" " style={{ cursor: "pointer" }}>
                          <FontAwesomeIcon
                            icon={faTimes}
                            style={{
                              width: 16,
                              height: 16,
                              margin: 5,
                              marginTop: 20,
                            }}
                            color="red"
                            onClick={() => this.resetFilters()}
                          />
                        </span>
                      </div>
                    </div>

                  </div>

                  <div className='row mt-1'>
                    <div className='col-lg-4'>
                      <label className='statusReason'>Property Status</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"dealType"}
                          component={MultiSelect}
                          filter={true}
                          onChange={(e) => this.handleChange(e, "selectedPropertyStatus")}
                          type={"dropDown"}
                          value={this.state.selectedPropertyStatus}
                          style={{ width: "92%" }}
                          options={this.state.propertyStatusArray}
                          placeholder='Select Status'
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <label className='statusReason'>Property Reason</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"dealType"}
                          component={MultiSelect}
                          filter={true}
                          type={"dropDown"}
                          value={this.state.selectedPropertyReasons}
                          style={{ width: "92%" }}
                          disabled={this.state.selectedPropertyStatus && this.state.selectedPropertyStatus.length > 0 ? false : true}
                          options={this.state.shownreasons && this.state.shownreasons.length > 0 ? this.state.shownreasons : []}
                          placeholder={"Select Reason"}
                          onChange={(e) => this.handleChange(e, "selectedPropertyReasons")}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                    <div className='col-lg-3'>
                      <label className='statusReason'>New Name Issue Code</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          filter={true}
                          component={MultiSelect}
                          onChange={(e) => this.handleChange(e.value, "newNameIssueCode", "in")}
                          type={"dropDown"}
                          value={this.state.newNameIssueCode}
                          style={{ width: "100%" }}
                          options={this.state.issueNameOptions}
                          placeholder={"Select New Name Issue Code"}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row mt-2'>
                    <div className='row col-lg-4'>

                      <div className='col-lg-6'>
                        {/* <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Conf. Onsite Properties Only"}</label>
                        <div className='mt-2 ml-5'>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'isexcludePossiblyPaid'}
                            className=""
                            checked={this.state.isexcludePossiblyPaid}
                            onChange={(e) => this.handleChangePossiblyPaid(e, "isexcludePossiblyPaid")}
                          />
                        </div> */}
                      </div>
                      <div className='col-lg-6'>
                        {/* <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label" style={{ marginRight: "10px", fontSize: 14 }}>{"Excl. Possibly Paid"}</label>
                        <div className='mt-2 ml-5'>
                          <Checkbox
                            label='Unassigned Only'
                            type='checkbox'
                            // component={renderCheckBoxField}
                            name={'excludePossiblyPaid'}
                            className=""
                            checked={this.state.excludePossiblyPaid}
                            onChange={(e) => this.handleExcludePossiblyPaid(e, "excludePossiblyPaid")}
                          />
                        </div> */}
                      </div>

                    </div>
                    <div className='col-lg-4' style={{ marginLeft: "1.8rem" }}>
                      <label className='statusReason'>Minimum Confirmed Onsite From Date (Greater Than Or Equal To)</label>
                      <div >

                        <Calendar
                          style={{ zindex: 1004, width: "92%", lineHeight: 1.15, height: '25px' }}
                          appendTo={document.body}
                          monthNavigator={true}
                          yearNavigator={true}
                          hourFormat="24"
                          // minDate={new Date()}
                          yearRange="1940:2530"
                          name={"calendar1"}
                          placeholder={"Select Date"}
                          // placeholder='mm/dd/yyyy'
                          dateFormat="mm/dd/yy"
                          value={this.state.fromDate}
                          onChange={(e) => this.setState({ fromDate: e.value })}
                        >
                        </Calendar>
                      </div>
                    </div>
                    <div className='col-lg-3 ml-1'>
                      <label className='statusReason'>Legacy Name Issue Code</label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"states"}
                          component={MultiSelect}
                          filter={true}
                          onChange={(e) => this.handleChange(e.value, "legacyNameIssueCode", "in")}
                          type={"dropDown"}
                          placeholder={"Select Legacy Name Issue Code"}
                          value={this.state.legacyNameIssueCode}
                          style={{ width: "100%" }}
                          options={this.state.LegacyIssueNameCodes}
                        // isDisable={item.isDisable ? item.isDisable : false}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </CardBody>
          </Card>
        </Col >
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div >
    );
  }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
AvailableToReMapFilters = reduxForm({
  form: "SummaryForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(AvailableToReMapFilters);

// You have to connect() to any reducers that you wish to connect to yourself
AvailableToReMapFilters = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(AvailableToReMapFilters);

export default withTranslation('common')(AvailableToReMapFilters);