import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button
} from 'reactstrap';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
// import { load as loadAccount } from '../../redux/reducers/commonReducer';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer'
import { Redirect } from 'react-router-dom';
// file upload
import { Row } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import apiCalls from '../../../../config/apiCalls';
import fetch from '../../../../config/service'
import config from '../../../../config/config'
import dateFormats from '../../../UI/FormatDate/formatDate'
import statesList from '../../../../shared/states.json'
// validate
// import validate from './validate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
// import { setUserAction } from '../../redux/actions/userActions';
import { setUserAction } from '../../../../redux/actions/userActions';
// import SessionWarningModal from '../Cruds/CommonModals/SessionWarningModal';
import SessionWarningModal from '../../CommonModals/SessionWarningModal'
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import configMessages from '../../../../config/configMessages';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar'
const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);


renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class EvidenceSummary extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      locationProps: '',
      states: [...statesList.map((e) => { return { label: e.shortCode, value: e.shortCode } })],
      onSitePropOnly: true,
      propertyNameScore: 90,
      filterCriteria: {},
      notDefaultArray: [],
      propertyReason: [],
      selectedStates: [],
      isShowNote: true,
      propertyValue: 50000,
      DealType: [],
      DealTypeV2: ["BK", "CR"],
      isGreen25: true,
      isGreen10_25: false,
      isGreen5_10: false,
      isGreen1_5: false,
      isGreen1: false,
      isGreenAll: false,
      isBlue25: true,
      isBlue10_25: false,
      isBlue5_10: false,
      isBlue1_5: false,
      isBlue1: false,
      isBlueAll: false,
      isExcludeRoyalties: true,
      excludeCoOwners: false,
      isexcludePossiblyPaid: false,
      ranges: [">$25k"],
      selectedBK: true,
      selectedCR: true,
      propertyStatus: [],
      unknownProp: false
    };
  }
  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    // filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }];
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetch('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.group, value: status.dataSourceNames })
          dataArray.push({ label: status.group, value: status.group })

          if (status.group === "State UP Data") {

            await this.setState({ setDefaultDataGroup: true })
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray })
      }
    })
    return this.state.settingsData;
  }
  onChangeDepartment = async (e) => {
    let departmentArray = [], departmentIdArray = []
    for (let id of e.value) {
      let z = this.state.taskDepartmentItems.find(elem => elem.value === id)
      if (z) {
        departmentArray.push(z.department)
        departmentIdArray.push(z.departmentId)
      }
    }
    this.setState({ department: departmentArray, departmentIdArray: departmentIdArray, department: e.value, })
    // await this.getDataFromServer(this.state.filterCriteria)
  }
  /*
 Fetch all Departments from the api 
 */
  getDepartments = () => {
    let apiUrl;
    let filterCriteria = {}
    filterCriteria.criteria = [{ key: "CoreOpsTeam", value: true, type: "eq" }, { key: 'activeIndicator', value: true, type: 'eq' }]
    return fetch('GET', `${apiCalls.Departments}?type=exportToCsv&filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.departments) {
          let labels = response.departments;
          let modifiedLabels = [];
          for (let label of labels) {
            modifiedLabels.push({
              label: label.departmentName,
              value: label.departmentName,
              departmentId: label._id
            })
          }
          /* Sorting the response based on the Alphabetical descending*/
          const sorted = modifiedLabels.sort((a, b) => {
            let fa = a.label.toLowerCase(),
              fb = b.label.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          await this.setState({
            taskDepartmentItems: sorted
          })
        } else {
          this.setState({
            taskDepartmentItems: []
          });
          // let perObj = this.state.perObj
          if (response) {
            if (response['errorMessage'] === configMessages.warningMessage) {
              this.setState({
                sessionWarning: true
              })
            } else if (response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
              this.setState({
                sessionExpiryModal: true
              })

            } else if (localStorage.getItem('sessionexpired') === true) {
              let sessionexpired = await localStorage.getItem('sessionexpired')
              if (sessionexpired === "true") {
                await this.setState({ sessionExpiryModal: true })
              }
            }
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  componentDidMount = async () => {
    this.searchsellerItems()
    this.getStatusOptions()
    await this.getDepartments()
    await this.getDataGroupSources()
    if (this.state.dataArray && this.state.dataArray.length > 0) {
      this.handleChange({ value: ["CA-N", "SEC", "State UP Data"] }, 'dataSource')
    }
    await this.setDefaultTeamOnInitialLoad()

  }
  setDefaultTeamOnInitialLoad = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      let departmentArray = user.secondaryDepartments ? user.secondaryDepartments : [], departmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      // Check to assign only teams that are  in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departmentIdArray = this.state.taskDepartmentItems.filter(obj => departmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)
      await this.setState({ department: departmentArray, departmentIdArray: departmentIdArray })
    }
  }
  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }
  getStatusOptions = (val) => {
    let filterCriteria = {}
    if (val) {
      filterCriteria['criteria'] = [{ key: 'status', value: val, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
      ];
    } else {
      filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true },
      { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
      ];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    let Statuses = [], defaultArray = []
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            // if (reason.status.startsWith("MATCHING-READY FOR MAPPING") || reason.status.startsWith("POST-FILING") || reason.status.startsWith("MAPPING")) {
            if (reason.status === config.highValuePropReport.defaultStatus) {
              defaultArray.push({ status: reason.status, tooltip: reason.statusDefinition })
            }
            Statuses.push({ label: reason.status, value: { status: reason.status, tooltip: reason.statusDefinition } })
          }
          await this.setState({ Statuses: Statuses, propertyStatus: defaultArray, defaultArray: defaultArray, notDefaultArray: [] })
          await this.getReasonOptions(defaultArray, "default")
        } else if (response && response["propertyStatusReasons"].length == 0) {

          await this.setState({ Statuses: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }
  getReasonOptions = (val, type) => {
    let filterCriteria = {}

    if (val) {
      var data = []
      for (var obj of val) {
        if (obj.status) {
          data.push(obj.status)
        }
      }
      filterCriteria['criteria'] = [{ key: 'status', value: data, type: 'in', add_Filter: true }];
    } else {
      filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
    }
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    let reasons = []
    return fetch('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}&dropdown=${val ? "reason" : "status"}`)
      .then(async (response) => {
        if (response && response["propertyStatusReasons"].length > 0) {
          let propertyStatusReasons = response["propertyStatusReasons"]
          for (let reason of propertyStatusReasons) {
            reasons.push({ label: reason.reason, value: { reason: reason.reason, tooltip: reason.statusReasonDefinition } })
          }
          await this.setState({ reasons: reasons, propertyReason: [] })
          if (type) {
            let data = [], statuswithReason = []
            for (var obj of reasons) {
              if (obj.label === config.highValuePropReport.defaultReason) {
                let reasonIndex = data.findIndex((item) => item.label === obj.value)
                if (reasonIndex === -1) {
                  data.push(obj.value)
                }
              }
            }
            for (let recorditem of propertyStatusReasons) {
              statuswithReason.push({ status: recorditem.status, value: { reason: recorditem.reason, tooltip: recorditem.statusReasonDefinition } })
            }
            await this.setState({ propertyReason: data, defaultReasons: data, statuswithReason: statuswithReason })
          }

        } else if (response && response["propertyStatusReasons"].length == 0) {
          await this.setState({ reasons: [], propertyReasonArray: [] })
        } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
          await this.setState({
            sessionWarning: true,

          })
        }
      }).catch((err) => {
        return err;
      })
  }

  handleChange = async (e, type) => {
    var ranges = this.state.ranges
    var index;
    if (type === "Seller") {
      await this.setState({
        selectedSeller: e.value,
      })
    } else if (type === "propertyValue") {
      await this.setState({
        propertyValue: e.value,
      })
    } else if (type === "dataSource") {

      let dataSourceArray = []
      await this.setState({
        dataSource: e.value ? e.value : e
      })
      for (let val of e.value) {
        let dataindex = this.state.dataSourceGroups.findIndex(obj => obj.label === val)
        if (dataindex !== -1) {
          let valueArr = this.state.dataSourceGroups[dataindex]
          dataSourceArray = [...dataSourceArray, ...valueArr.value]
          await this.setState({ dataSourceArrayNew: dataSourceArray })
        }
      }


    } else if (type === "Property Status") {
      var notDefaultArray = e.filter(function (item) {
        return !item.status.startsWith("MATCHING") && !item.status.startsWith("POST-FILING") && !item.status.startsWith("MAPPING")
      });
      let statusreason = []
      for (var obj of this.state.statuswithReason) {
        for (var item of e) {
          if (item.status === obj.status) {
            statusreason.push(obj.value)
          }
        }
      }
      await this.setState({
        propertyStatus: e,
        notDefaultArray: notDefaultArray,
        isShowNote: false,
        propertyReason: []
      })
      this.getReasonOptions(e)
    } else if (type === "Property Reason") {
      await this.setState({
        propertyReason: e,
      })
    } else if (type === "DealType") {
      if (this.props.type === "UP Opportunity – V2") {
        await this.setState({
          DealTypeV2: e
        })
      } else {
        await this.setState({
          DealType: e
        })
      }
    } else {
      await this.setState({
        selectedStates: e
      })
    }
    await this.setState({
      ranges: ranges
    })
  }
  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  searchsellerItems = async (event, criteria) => {
    let apiUrl
    if (event && event.query.length > 0) {
      apiUrl = `${apiCalls.propertysellers}?str=${event.query}`
    } else {
      apiUrl = `${apiCalls.propertysellers}`

    }
    return fetch('GET', apiUrl)
      .then(async (response) => {
        if (response) {
          let dropdownData = [], dropdownDataVal = [];
          if (response["sellers"] && response["sellers"].length && response["sellers"].length > 0) {
            dropdownDataVal = response["sellers"]
          } else if (response && response.errorMessage && (response.errorMessage === "Session expired please login again." || response.errorMessage === "Token not provided")) {
            this.setState({
              sessionExpiryModal: true
            });
          } else if (response && response['errorMessage'] === "Session is about to expire in 5 minutes.") {
            this.setState({
              SessionWarningModal: true
            })
          }
          if (dropdownDataVal && dropdownDataVal.length === 0) {
            this.setState({
              filteredSuggestions: [],
              noData: true
            });
          } else {
            dropdownData = dropdownDataVal;
          }
          await this.setState({
            filteredSuggestions: dropdownDataVal
          });
          if (criteria) {
            await this.setState({
              selectedSeller: dropdownData[0]
            })
            this.searchnameItems(criteria)
          }
        }
      }).catch((err) => {
        return err;
      });
  }
  //stay signed in Modal
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getKanbanCards}

      />
    )
  }

  handleChangeExcludeRoyalties = (type) => {
    this.setState({
      isExcludeRoyalties: !this.state.isExcludeRoyalties
    })
  }
  handleChangeExcludeCoOwners = (type) => {
    this.setState({
      excludeCoOwners: !this.state.excludeCoOwners
    })
  }
  handleChangePossiblyPaid = (type) => {
    this.setState({
      isexcludePossiblyPaid: !this.state.isexcludePossiblyPaid
    })
  }



  resetFilters = async () => {
    let filterCriteria = this.props.filterCriteria;
    filterCriteria["criteria"] = []
    this.getStatusOptions()
    await this.setState({
      selectedStates: [],
      DealType: [],
      unknownProp: false,
      DealTypeV2: ["BK", "CR"],
      selectedSeller: "",
      isGreen25: true,
      isGreen10_25: false,
      isGreen5_10: false,
      isGreen1_5: false,
      isShowNote: true,
      isGreen1: false,
      // dataSource: [],
      propertyStatus: this.state.defaultArray,
      propertyReason: this.state.defaultReasons,
      notDefaultArray: [],
      isGreenAll: false,
      isBlue25: true,
      isBlue10_25: false,
      isBlue5_10: false,
      isBlue1_5: false,
      isBlue1: false,
      isBlueAll: false,
      propertyNameScore: 90,
      propertyValue: 50000,
      excludeCoOwners: false,
      isexcludePossiblyPaid: false,
      isExcludeRoyalties: true,
      ranges: [">$25k"],
      ConfirmedOnSiteSince: null,
      PropAddedSince: null,
      onSitePropOnly: true
    })
    await this.handleChange({ value: ["CA-N", "SEC", "State UP Data"] }, 'dataSource')
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    let departmentArray = [], departmentIdArray = []
    // let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user && user.department && user.roleName && user.roleName.includes("CoreOps") && !user.defaultUser) {
      departmentArray = user.secondaryDepartments ? user.secondaryDepartments : []
      departmentIdArray = user.secondaryDepartmentIds ? user.secondaryDepartmentIds : []
      departmentArray.push(user.department)
      departmentIdArray.push(user.departmentId)
      //Check to select only existing teams in dropdown values
      departmentArray = this.state.taskDepartmentItems.filter(obj => departmentArray.includes(obj.label)).map(obj => obj.label)
      departmentIdArray = this.state.taskDepartmentItems.filter(obj => departmentIdArray.includes(obj.departmentId)).map(obj => obj.departmentId)

      // this.submit()
    }
    await this.setState({ department: departmentArray.length > 0 ? departmentArray : null, departmentIdArray: departmentIdArray.length > 0 ? departmentIdArray : null })

    let Obj = {
      ranges: this.state.ranges,
      fromSummaries: true
    }
    if (this.state.selectedSeller)
      Obj.sellerId = this.state.selectedSeller.EstateID
    if (this.state.selectedStates && this.state.selectedStates.length > 0) {
      Obj.states = this.state.selectedStates
    }
    if (this.state.DealType && this.state.DealType.length > 0) {
      Obj.DealType = this.state.DealType
    }
    if (this.state.DealTypeV2 && this.state.DealTypeV2.length > 0) {
      Obj.DealType = this.state.DealTypeV2
    }
    this.submit()

  }


  submit = () => {
    let highValueBody = {

    }
    let filterCriteria = this.props.filterCriteria
    let deals = []

    let companyIdIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyID")
    if (companyIdIndex !== -1) {
      filterCriteria["criteria"].splice(companyIdIndex, 1)
    }
    if (this.state.selectedSeller) {
      filterCriteria.criteria.push({ key: "CompanyID", value: this.state.selectedSeller.EstateID, type: "eq" })
    }
    let propertyValueIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value !== null && obj.value !== 0)
    if (propertyValueIndex !== -1) {
      filterCriteria["criteria"].splice(propertyValueIndex, 1)
    }
    if (this.state.propertyValue) {
      filterCriteria.criteria.push({ key: "PropertyMaxValueAmount", value: parseInt(this.state.propertyValue), type: "gte" })
    }
    let nullIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value === null)
    if (nullIndex !== -1) {
      filterCriteria["criteria"].splice(nullIndex, 1)

    }
    let zerothIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value === 0)
    if (zerothIndex !== -1) {
      filterCriteria["criteria"].splice(zerothIndex, 1)

    }
    if (this.state.unknownProp) {
      propertyValueIndex = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyMaxValueAmount" && obj.value !== null && obj.value !== 0)
      if (propertyValueIndex !== -1) {
        filterCriteria["criteria"].splice(propertyValueIndex, 1)
      }
      filterCriteria.criteria.push({ key: "PropertyMaxValueAmount", value: null, type: "nexists" }, { key: "PropertyMaxValueAmount", value: 0, type: "ne" })
    }
    highValueBody.propertyValue = this.state.unknownProp ? 0 : this.state.propertyValue

    if (this.state.propertyNameScore) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
      filterCriteria.criteria.push({ key: "NameMatchScore", value: parseInt(this.state.propertyNameScore), type: "gte" })
      highValueBody.propertyNameScore = this.state.propertyNameScore
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "NameMatchScore")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
    }

    let indindex = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsite")
    if (indindex === -1) {
      filterCriteria.criteria.push({ key: "confirmedOnsite", value: this.state.onSitePropOnly ? true : false, type: "eq" })
    } else {
      filterCriteria.criteria[indindex].value = this.state.onSitePropOnly ? true : false
    }

    if (this.state.PropAddedSince) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "opraAddedDate")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
      filterCriteria.criteria.push({ key: "opraAddedDate", value: dateFormats.formatDate(this.state.PropAddedSince, "MM-DD-yyyy"), type: "dategte" })
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "opraAddedDate")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
    }

    if (this.state.ConfirmedOnSiteSince) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
      filterCriteria.criteria.push({ key: "confirmedOnsiteDate", value: dateFormats.formatDate(this.state.ConfirmedOnSiteSince, "MM-DD-yyyy"), type: "dategte" })
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "confirmedOnsiteDate")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
    }
    if (this.state.department) {
      highValueBody.departMentIdArray = this.state.departmentIdArray
    }
    if (this.state.dataSourceArrayNew && this.state.dataSourceArrayNew.length > 0 && this.state.dataSource && this.state.dataSource.length > 0) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
      filterCriteria["criteria"].push({
        key: "PropertyDataSource",
        value: this.state.dataSourceArrayNew,
        type: "in",
        add_Filter: true
      })
      highValueBody.dataSourceArrayNew = this.state.dataSourceArrayNew
      highValueBody.dataSource = this.state.dataSource
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      highValueBody.dataSourceArrayNew = null
      highValueBody.dataSource = null
    }
    if (this.state.propertyStatus && this.state.propertyStatus.length > 0) {
      let data = []
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
      for (var obj of this.state.propertyStatus) {
        if (obj.status) {
          data.push(obj.status)
        }

      }
      filterCriteria.criteria.push({ key: "propertyStatus", value: data, type: "in" })
      highValueBody.propertyStatus = data
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
    }
    if (this.state.propertyReason && this.state.propertyReason.length > 0) {
      let data = []
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)
      }
      for (var obj of this.state.propertyReason) {
        if (obj.reason) {
          data.push(obj.reason)
        }
      }
      filterCriteria.criteria.push({ key: "propertyStatusReason", value: data, type: "in" })
      highValueBody.propertyReason = data
    } else {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")
      if (index !== -1) {
        filterCriteria["criteria"].splice(index, 1)

      }
    }

    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "sourceState")
    if (index !== -1) {
      filterCriteria["criteria"].splice(index, 1)

    }
    if (this.state.selectedStates && this.state.selectedStates.length > 0) {
      filterCriteria.criteria.push({ key: "sourceState", value: this.state.selectedStates, type: "in" })
    }
    filterCriteria.page = 1
    this.props.setDataCriteriaForOpportunity(filterCriteria, highValueBody, '', this.state.departmentIdArray)

  };

  render() {
    const { handleSubmit } = this.props;
    return (

      <div>
        <Col md={12} lg={12} style={{ border: "1px solid lightgrey", marginBottom: 10 }}>
          <Card className="py-2 ">
            <CardBody className="p-0">
              <form className="form" onSubmit={handleSubmit(this.submit)}>
                <div className='w-100 property_Summary'>
                  {
                    this.props.tabDescriptionArray && this.props.tabDescriptionArray.length > 0 ?
                      <div className="d-flex align-items-center" style={this.props.tabNo % 2 == 0 || this.props.tabNo == 0 ? { width: "100%", backgroundColor: "#f5eee3", marginBottom: "5px", height: "40px" } : { width: "100%", backgroundColor: "#e9f7f1", marginBottom: "5px", height: "40px" }}>
                        <h4 className='ml-2'><b>{this.props.tabType}: </b>
                          {this.props.tabDescriptionArray}</h4></div> :
                      null
                  }
                  <Row className="ml-0 highPropFilters_1" >
                    <div className='col-sm-2'>
                      <div className={"pb-2"} >
                        <label className='d-flex' style={{ marginBottom: 4 }}>
                          <span className='mr-auto'>{"Property Value >="}</span>
                          <span className={'ml-auto'}>
                            <FontAwesomeIcon icon={faTimes}
                              className={`pl-1 mr-2 clearIcon `}
                              color={this.state.propertyValue ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear company"
                              title="Clear Filter"
                              disable={this.state.propertyValue ? false : true}
                              onClick={() => this.setState({ propertyValue: "" })}
                            />
                          </span>
                        </label>
                        <div >
                          <InputText
                            value={this.state.propertyValue}
                            size={25}
                            keyfilter={"pint"}
                            disabled={this.state.unknownProp}
                            onChange={(e) => this.setState({ propertyValue: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <div className={"pb-2"} >
                        <label className='d-flex' style={{ marginBottom: 4 }}>
                          <span className='mr-auto'>{"Name Score>="}</span>
                          <span className={'ml-auto'}>
                            <FontAwesomeIcon icon={faTimes}
                              className={`pl-1 mr-2 clearIcon `}
                              color={this.state.propertyNameScore ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear company"
                              title="Clear Filter"
                              disable={this.state.propertyNameScore ? false : true}
                              onClick={() => this.setState({ propertyNameScore: "" })}
                            />
                          </span>
                        </label>
                        <div >
                          <InputText
                            value={this.state.propertyNameScore}
                            size={25}
                            onChange={(e) => this.setState({ propertyNameScore: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-2' >
                      <div className={"pb-2"} >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                          <span className='mr-auto'>Deal</span>
                          <span className={'ml-auto'}>
                            <FontAwesomeIcon icon={faTimes}
                              className={`pl-1 mr-2 clearIcon `}
                              color={this.state.selectedSeller ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear company"
                              title="Clear Filter"
                              disable={this.state.selectedSeller ? false : true}
                              onClick={() => this.setState({ selectedSeller: "" })}
                            />
                          </span>
                        </label>
                        <div >
                          <AutoComplete
                            value={this.state.selectedSeller && this.state.selectedSeller.EstateName ? `${this.state.selectedSeller.DealType} - ${this.state.selectedSeller.SellerNickName} (${this.state.selectedSeller && this.state.selectedSeller.DealYear ? this.state.selectedSeller.DealYear : ""})` : this.state.selectedSeller}
                            suggestions={this.state.filteredSuggestions}
                            completeMethod={this.searchsellerItems}
                            minLength={1}
                            itemTemplate={this.editorTemplateForDeal}
                            field={"EstateName"}
                            style={{ width: "105%" }}
                            dropdown={true}
                            onChange={(e) => this.handleChange(e, 'Seller')}
                            appendTo={document.body} />
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <div className="form__form-group  pl-0" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                          <span className='mr-auto'>Property Status</span>
                          <span className={'ml-auto'}>
                            <FontAwesomeIcon icon={faTimes}
                              className={`pl-1 mr-2 clearIcon `}
                              color={this.state.propertyStatus ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear company"
                              title="Clear Filter"
                              disable={this.state.propertyStatus ? false : true}
                              onClick={() => this.setState({ propertyStatus: "", propertyReason: "" })}
                            />
                          </span>
                        </label>
                        <div className="form__form-group-field">
                          <MultiSelect
                            name={"propertyStatus"}
                            component={MultiSelect}
                            onChange={(e) => this.handleChange(e.value, "Property Status", "in")}
                            type={"dropDown"}
                            filter={true}
                            value={this.state.propertyStatus}
                            style={{ width: "100%" }}
                            options={this.state.Statuses}
                            placeholder={""}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-1" id='department'>
                      <label className="mr-1 kanban_filter_label d-flex" id="department" style={{ marginTop: 3 }}>
                        <span className='mr-auto'>Team:</span>
                        <span className={'ml-auto'}>
                          <FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={this.state.department ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            id="clear company"
                            maxSelectedLabels={1}
                            title="Clear Filter"
                            disable={this.state.department ? false : true}
                            onClick={() => this.setState({ department: "", departmentIdArray: null })}
                          />
                        </span>
                      </label>
                      <MultiSelect
                        id="department"
                        className={"form__form-group-field "}
                        style={{ width: "100%", height: 32 }}
                        value={this.state.department}
                        filter={true}
                        maxSelectedLabels={1}
                        options={this.state.taskDepartmentItems}
                        onChange={(e) => this.onChangeDepartment(e, "department")}
                        placeholder=''
                      />
                    </div>
                    <div className='col-sm-2'>
                      <div className="form__form-group pl-0" >
                        <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                          <span className='mr-auto'>Data Source</span>
                          <span className={'ml-auto'}>
                            <FontAwesomeIcon icon={faTimes}
                              className={`pl-1 mr-2 clearIcon `}
                              color={this.state.dataSource ? 'red' : 'lightgrey'}
                              data-toggle="tool-tip"
                              id="clear company"
                              title="Clear Filter"
                              disable={this.state.dataSource ? false : true}
                              onClick={() => this.setState({ dataSource: "", dataSourceArrayNew: "" })}
                            />
                          </span>
                        </label>
                        <div className="form__form-group-field" data-toggle="tool-tip" title={this.state.dataSourceGroups ? "" : "No options available"}>
                          <MultiSelect
                            value={this.state.dataSource}
                            name={"dataSource"}
                            filter={true}
                            style={{ width: "100%" }}
                            appendTo={document.body}
                            maxSelectedLabels={1}
                            options={this.state.dataArray ? this.state.dataArray : []}
                            onChange={(e) => this.handleChange(e, "dataSource")}
                          />
                          <span>{this.state.isShownameIssueCodes ? "No options available" : null}</span>
                        </div>
                      </div></div>
                    <Button style={{ backgroundColor: "#4ea250", color: "white", marginTop: "25px", height: 30 }} type="submit">Go</Button>
                    <div className="mt-2" >
                      <span className=" " style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          style={{
                            width: 16,
                            height: 16,
                            margin: 5,
                            marginTop: 20,
                          }}
                          color="red"
                          onClick={() => this.resetFilters()}
                        />
                      </span>
                    </div>
                  </Row>
                  <Row className="ml-0" >
                    <div className='col-sm-2'>
                      <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label ml-0" style={{ fontSize: 14 }}>
                        {"Conf. Onsite Properties Only"}
                      </label>
                      <div className='mt-2'>
                        <Checkbox
                          label='onSitePropOnly'
                          id='onSitePropOnly'
                          type='checkbox'
                          name={'onSitePropOnly'}
                          className=""
                          checked={this.state.onSitePropOnly}
                          onChange={(e) => this.setState({ onSitePropOnly: this.state.onSitePropOnly === true ? false : true })}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor="cb3 col-sm-3 ml-0" className="p-checkbox-label ml-0" style={{ fontSize: 14 }}>
                        {"Unknown Properties Only"}
                      </label>
                      <div className='mt-2'>
                        <Checkbox
                          label='onSitePropOnly'
                          id='onSitePropOnly'
                          type='checkbox'
                          name={'onSitePropOnly'}
                          className=""
                          checked={this.state.unknownProp}
                          onChange={(e) => this.setState({ unknownProp: this.state.unknownProp === true ? false : true })}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <label className='statusReason d-flex '>
                        <span className='mr-auto'>Date Prop. Added Since</span>
                        <span className={'ml-auto'}>
                          <FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={this.state.PropAddedSince ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            id="clear company"
                            title="Clear Filter"
                            disable={this.state.PropAddedSince ? false : true}
                            onClick={() => this.setState({ PropAddedSince: "" })}
                          />
                        </span>
                      </label>
                      <div >
                        <Calendar
                          style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                          appendTo={document.body}
                          monthNavigator={true}
                          yearNavigator={true}
                          hourFormat="24"
                          yearRange="1940:2530"
                          name={"calendar1"}
                          placeholder={"Select Date"}
                          dateFormat="mm/dd/yy"
                          value={this.state.PropAddedSince}
                          onChange={(e) => this.setState({ PropAddedSince: e.value })}
                        >
                        </Calendar>
                      </div>
                    </div>
                    <div className="col-sm-2 form__form-group" >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                        <span className='mr-auto'>Property Reason</span>
                        <span className={'ml-auto'}>
                          <FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={this.state.propertyReason ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            id="clear company"
                            title="Clear Filter"
                            disable={this.state.propertyReason ? false : true}
                            onClick={() => this.setState({ propertyReason: "" })}
                          />
                        </span>
                      </label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          // id={item.id}
                          name={"propertyReason"}
                          component={MultiSelect}
                          onChange={(e) => this.handleChange(e.value, "Property Reason", "in")}
                          type={"dropDown"}
                          filter={true}
                          value={this.state.propertyReason}
                          style={{ width: "100%" }}
                          options={this.state.reasons}
                          placeholder={""}
                        />
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <label className='statusReason d-flex'>
                        <span className='mr-auto'>Confirmed On Site Since</span>
                        <span className={'ml-auto'}>
                          <FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={this.state.ConfirmedOnSiteSince ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            id="clear company"
                            title="Clear Filter"
                            disable={this.state.ConfirmedOnSiteSince ? false : true}
                            onClick={() => this.setState({ ConfirmedOnSiteSince: "" })}
                          />
                        </span>
                      </label>
                      <div >
                        <Calendar
                          style={{ zindex: 1004, width: "100%", lineHeight: 1.15, height: '25px' }}
                          appendTo={document.body}
                          monthNavigator={true}
                          yearNavigator={true}
                          hourFormat="24"
                          yearRange="1940:2530"
                          name={"calendar1"}
                          placeholder={"Select Date"}
                          dateFormat="mm/dd/yy"
                          value={this.state.ConfirmedOnSiteSince}
                          onChange={(e) => this.setState({ ConfirmedOnSiteSince: e.value })}
                        >
                        </Calendar>
                      </div>
                    </div>
                    <div className="col-sm-2 form__form-group" >
                      <label style={{ marginBottom: 4, width: "100%", display: "flex" }}>
                        <span className='mr-auto'>Source State</span>
                        <span className={'ml-auto'}>
                          <FontAwesomeIcon icon={faTimes}
                            className={`pl-1 mr-2 clearIcon `}
                            color={this.state.selectedStates && this.state.selectedStates.length > 0 ? 'red' : 'lightgrey'}
                            data-toggle="tool-tip"
                            id="clear company"
                            title="Clear Filter"
                            disable={this.state.selectedStates && this.state.selectedStates.length > 0 ? false : true}
                            onClick={() => this.setState({ selectedStates: "" })}
                          />
                        </span>
                      </label>
                      <div className="form__form-group-field">
                        <MultiSelect
                          name={"propertyReason"}
                          component={MultiSelect}
                          onChange={(e) => this.handleChange(e.value, "", "in")}
                          type={"dropDown"}
                          filter={true}
                          value={this.state.selectedStates}
                          style={{ width: "100%" }}
                          options={this.state.states}
                          placeholder={""}
                        />
                      </div>
                    </div>
                  </Row>
                  <Row style={this.state.isShowNote ? { display: "block" } : { display: "none" }} className="mt-2">
                    <span className="mx-4">Default filter: </span>
                    Showing High Value Properties for Property Status as ‘MATCHING-READY FOR MAPPING’ and Property Reason as ‘CNFRMD ONSITE’.
                  </Row>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col >
        {this.state.LoggotSuccess ? <Redirect to="/log_in" /> : null}
        {this.state.sessionWarning ? this.getSessionWraningModal() : null}
      </div>



    );
  }
}




// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
EvidenceSummary = reduxForm({
  form: "SummaryForm", // a unique identifier for this form
  enableReinitialize: true,
  // validate
})(EvidenceSummary);

// You have to connect() to any reducers that you wish to connect to yourself
EvidenceSummary = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  {
    load: loadAccount,
    setUserData: setUserAction
  } // bind account loading action creator
)(EvidenceSummary);

export default withTranslation('common')(EvidenceSummary);