// import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import CheckBox from '../../../shared/components/form/CheckBox';
import config from '../../../config/config';
import fetch from "../../../config/service"
import DefaultInput from '../../../shared/components/form/DefaultInput';
import ConfirmaionModel from './ConfirmationModal';
import { Dropdown } from 'primereact/dropdown';
import apiCalls from '../../../config/apiCalls';

const Container1 = styled.div`
  display: flex;
`
const Container = styled.div`
  border: 1px solid white;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDragging
            ? 'skyblue' : 'lightgrey'};
`
const Container5 = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;


  display: flex;
  flex-direction: column;
`

const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  min-height: 100px;
`

class DealShowHideColumnsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableFields: [],
            changedTableFields: '',
            selectTableFields: [],
            incomingPreferences: JSON.parse(JSON.stringify(this.props.tableFields)),
            notSelectedTableFields: [],
            openConfirmationModal: false,
            columns: {
                'column-1': {
                    id: 'column-1',
                    title: 'To do',
                    taskIds: ['task-1', 'task-2', 'task-3', 'task-4']
                },
                'column-2': {
                    id: 'column-2',
                    title: 'In progress',
                    taskIds: []
                },

            },
            columnOrder: ['column-1', 'column-2'],
            finalArr: [],
            permissions: {},
            dealViews: config.dealViews,
            selectedViewType: ""
        };
    }

    componentDidMount = async () => {
        // this.props.change("views", this.props.viewBy)
        let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"))
        let dealViews = []
        dealViews = config.dealViews
        let selectedViewBasedOnLoggedUser = this.props.selectedViewBasedOnLoggedUser
        await this.getScreenFieldsData(selectedViewBasedOnLoggedUser)
        this.setState({
            loggedRole: loginCredentials.roleName,
            selectedViewType: selectedViewBasedOnLoggedUser,
            dealViews: dealViews
        })

    }
    /**
     * 
     * @param {String} type 
     * closing of model for show hide columns
     */

    closeShowHideColumnsModalForDeal = async (type) => {
        await this.setState({
            isopenColumnModal: false
        })
        if (type === "close") {
            this.props.closeShowHideColumnsModalForDeal('close', null, this.state.incomingPreferences)
        }
    }


    /**
     * 
     * @returns opening the confirmation model for Showing message
     */
    getConfirmaionModel() {
        return (
            <ConfirmaionModel
                openConfirmationModal={this.state.isopenColumnModal}
                closeConfirmationModal={this.closeShowHideColumnsModalForDeal}
                shownText={"Are you sure you want to close before Saving?"}
                confirm={this.submit}
            />
        )
    }


    handleGetV2Order = (viewType, type) => {
        let apiUrl = `${apiCalls.defaultConfiguration}Names&view=${viewType}`
        return fetch('GET', apiUrl)
            .then(async (response) => {
                if (response) {
                    if (response && response.preferences) {
                        if (response && response.preferences) {
                            this.setState({ preferences: response.preferences.preferences })
                        }
                        this.getScreenFieldsData(viewType, type)
                    }
                    else if (response && response.errorMessage) {
                    }

                }
            }).catch((err) => {
                return err;
            });

    }




    /**
     * 
     * @param {String} viewType 
     * rearranging the data in order to show as columns
     */
    getScreenFieldsData = async (viewType, type) => {
        // if (viewType) {
        let columnOrder = this.state.columnOrder
        let originalTableFields = this.props.type === "Names V2" ?
            await this.props.preferencesGetCall(viewType ? viewType : "AllColumns", type ? this.state.preferences : []) :
            this.props.originalTableFields
        let tempTableFields = []
        let columns = this.state.columns;
        if (this.props.type === "Names V2") {
            let defaultPreferences = type ? this.state.preferences : this.props.defaultPreferences
            let colOrder = defaultPreferences;   // default from config
            if (viewType) {
                for (var obj of originalTableFields) {
                    let index = colOrder.findIndex(item => item.field === obj.field)
                    if (index !== -1) {
                        tempTableFields.push(obj)
                    }

                }
            } else {
                tempTableFields = colOrder
            }
        } else {
            let colOrder = this.props.tableFields;
            let tempTableFields = colOrder;
            let tableNotKnownFields = originalTableFields.filter(function (el) {
                let index = tempTableFields.findIndex(obj => obj.field === el.field)
                if (index == -1) {
                    return el
                }
            });
            if (tableNotKnownFields && tableNotKnownFields.length > 1) {
                tempTableFields = [...tempTableFields, ...tableNotKnownFields]
            }
            for (var column of tempTableFields) {
                let index = originalTableFields.findIndex(obj => obj.field === column.field)
                if (index !== -1) {
                    if (column.field === originalTableFields[index].field) {
                        column.header = originalTableFields[index].header
                    }
                }
            }
            if (tempTableFields) {
                let index = tempTableFields.findIndex(obj => obj.field === null)
                if (index !== -1) tempTableFields.splice(index, 1)
            }

            if (originalTableFields) {
                for (var column of tempTableFields) {
                    let isPresentInLocal = originalTableFields.find(item => item.field === column.field);
                    if (isPresentInLocal === undefined) {
                        let deleteIndex = tempTableFields.findIndex(obj => obj.field === column.field)
                        tempTableFields.splice(deleteIndex, 1)
                    }
                    tempTableFields = tempTableFields
                }
            }
        }
        if (tempTableFields.length > 15) {
            const half = (tempTableFields.length % 2 === 0) ? (tempTableFields.length / 2) : (Math.round(tempTableFields.length / 2));
            columns['column-1'].taskIds = tempTableFields.slice(0, half)
            columns['column-2'].taskIds = tempTableFields.slice(half)
            columnOrder = ['column-1', 'column-2']
        } else {
            columns['column-1'].taskIds = tempTableFields
            columnOrder = ['column-1']
        }
        this.setState({
            tableFields: JSON.parse(JSON.stringify(tempTableFields)),
            changedTableFields: tempTableFields,
            columns: columns,
            columnOrder: columnOrder
        })
    }

    /**
     * 
     * @param {Object} newState 
     * Dividing the array based on length
     */
    divideArray = async (newState) => {
        let tempFields = [...newState.columns['column-1'].taskIds, ...newState.columns['column-2'].taskIds]
        let columns = this.state.columns
        const half = Math.ceil(tempFields.length / 2);
        columns['column-1'].taskIds = tempFields.splice(0, half)
        columns['column-2'].taskIds = tempFields.splice(-half)
        await this.setState({
            columns: columns
        })

    }
    /**
     * 
     * @param {Array} result 
     * @returns On drag and drop of column fields arraning the order
     */
    onDragEnd = result => {
        const { destination, source } = result
        if (!destination) {
            return
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const start = this.state.columns[source.droppableId]
        const finish = this.state.columns[destination.droppableId]

        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds)
            let changedParam = start.taskIds[source.index]
            newTaskIds.splice(source.index, 1)
            newTaskIds.splice(destination.index, 0, changedParam)

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            }

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn
                }
            }

            this.setState(newState)
            return
        }

        // Moving from one list to another
        const startTaskIds = Array.from(start.taskIds)
        let ChangedParam = startTaskIds[source.index]
        startTaskIds.splice(source.index, 1)
        const newStart = {
            ...start,
            taskIds: startTaskIds
        }

        const finishTaskIds = Array.from(finish.taskIds)
        finishTaskIds.splice(destination.index, 0, ChangedParam)
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        }
        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        }

        this.setState(newState)
        this.divideArray(newState)

    }
    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     *  Onchange checkbox
     */
    onChange = async (event, column) => {
        let columns = this.state.columns
        if (event && event.target.name && columns[column] && columns[column].taskIds && columns[column].taskIds.length > 0) {
            columns[column].taskIds.map(col => {
                if (col.field === event.target.name) {
                    col.show = !col.show
                }
                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }
    /**
     * 
     * @param {Object} event 
     * @param {String} column 
     * @param {String} changedField 
     *  Onchange of width
     */
    onChangeWidth = async (event, column, changedField) => {
        let columns = this.state.columns
        if (event && columns[column] && columns[column].taskIds && columns[column].taskIds.length > 0) {
            columns[column].taskIds.map(col => {
                if (col.field === changedField) {
                    if (parseInt(event.target.value)) {
                        col.width = parseInt(event.target.value)
                    } else {
                        col.width = ""
                    }
                }

                return col
            })
        }
        this.setState({
            columns: columns,
        })
    }

    /**
     * closing of models
     */
    getCloseData = () => {
        if (this.state.selectedViewType !== "" || this.props.isShownnFieldType) {
            this.setState({
                isopenColumnModal: true
            })
        } else {
            this.closeShowHideColumnsModalForDeal("close")
        }
    }
    /**
     * 
     * @param {Array} values 
     * submit function on click of submit 
     */
    submit = async (values) => {
        let finalArr = this.state.finalArr
        this.state.columnOrder.map(columnId => {
            const column = this.state.columns[columnId].taskIds
            finalArr = [...finalArr, ...column]
            this.setState({
                finalArr: finalArr
            })
        })

        await this.props.closeShowHideColumnsModalForDeal('confirm', values, finalArr, this.state.selectedViewType)
    }

    selectViewType = async (e) => {
        await this.setState({ selectedViewType: e.value, tempTableFields: [], columnOrder: [] })
        this.handleGetV2Order(e.value, "selectedOrderToView")
    }

    render() {
        const { t, handleSubmit, viewType, isShownnFieldType } = this.props;
        return (
            <Modal isOpen={this.props.isOpenShowHideColumnsModal} fade={false}
                className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>
                <ModalHeader className="modal__header" style={{ padding: 16, justifyContent: "center" }}>
                    <button className="lnr lnr-cross modal__close-btn" type="button"
                        style={{ color: "black" }}
                        onClick={() => this.getCloseData()} />

                    <b>Manage Columns Order </b>
                </ModalHeader>
                <ModalBody className='deleteModalBody' style={isShownnFieldType ? { margin: "auto" } : {}}>
                    <form onSubmit={handleSubmit(this.submit)} >
                        <div >

                            <div className=" col-sm-6 pb-1" style={isShownnFieldType ? { display: "none" } : { display: "block" }}>
                                <label className="form__form-group-label">View By</label>
                                <div className=''>
                                    <Dropdown className='States'
                                        type={'dropDown'}
                                        name="views"
                                        value={this.state.selectedViewType}
                                        options={this.state.dealViews}
                                        onChange={(e) => this.selectViewType(e)}
                                        filter={true}
                                        placeholder='Select View'

                                    />
                                </div>
                            </div>
                            {this.state.selectedViewType || isShownnFieldType ?
                                <>
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Container1>
                                            {this.state.columnOrder.map(columnId => {
                                                const column = this.state.columns[columnId]

                                                const tasks = column.taskIds
                                                return (
                                                    <Container5>
                                                        <Droppable droppableId={column.id} type="TASK">
                                                            {(provided, snapshot) => (
                                                                <TaskList
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {tasks.map((task, index) => (

                                                                        // task.header && task.field ?
                                                                        <Draggable
                                                                            key={task.field}
                                                                            draggableId={task.field}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot) => (

                                                                                <Container
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    ref={provided.innerRef}
                                                                                    isDragging={snapshot.isDragging}
                                                                                ><div className="d-flex">
                                                                                        <Field
                                                                                            className='mr-0 my-0'
                                                                                            key={task.field}
                                                                                            name={task.field ? task.field : null}
                                                                                            component={CheckBox}
                                                                                            checked={viewType === 'grid' ? task.mobile : task.show}
                                                                                            value={viewType === 'grid' ? task.mobile : task.show}
                                                                                            onChange={(e) => this.onChange(e, columnId)}
                                                                                            label={task.header ? `${t(task.header)}` : "Selection"}
                                                                                        />
                                                                                        <span className="ml-auto" style={{ width: "21%" }}>
                                                                                            <input
                                                                                                className='ml-3'
                                                                                                type="text"
                                                                                                style={{ height: 32, width: "70%" }}
                                                                                                key={`${task.field}+width`}
                                                                                                name={task.field ? `${task.field}+width` : null}
                                                                                                component={DefaultInput}
                                                                                                defaultValue={task.width}
                                                                                                value={task.width}
                                                                                                onChange={(e) => this.onChangeWidth(e, columnId, task.field)}
                                                                                                label={task.header ? `${t(task.header)}--${t(task.width)}px` : "Selection"}
                                                                                            /></span>
                                                                                    </div>
                                                                                </Container>

                                                                            )}
                                                                        </Draggable>
                                                                        // : null
                                                                    )
                                                                    )}
                                                                    {provided.placeholder}
                                                                </TaskList>
                                                            )}
                                                        </Droppable>
                                                    </Container5>
                                                )
                                            })}
                                        </Container1>
                                    </DragDropContext>
                                    <div className='col-sm-12 text-center'>
                                        <Button
                                            color="primary"
                                            outline
                                            type="button"
                                            onClick={() => this.props.closeShowHideColumnsModalForDeal('close', null, this.state.incomingPreferences)}
                                            className='deleteModalBtn marginRight'
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='primary'
                                            outline
                                            type="submit"
                                            className='deleteModalBtn'
                                        >
                                            Confirm
                                        </Button>
                                    </div></>
                                : null}

                        </div>


                    </form>
                    {this.state.isopenColumnModal ?
                        this.getConfirmaionModel() : null
                    }
                </ModalBody>
            </Modal>
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DealShowHideColumnsModal = reduxForm({
    form: "DealShowHideColumnsModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(DealShowHideColumnsModal);

export default withTranslation('common')(DealShowHideColumnsModal);