import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import fetch from '../../../config/service'
import Loader from '../../App/Loader';

import configImages from '../../../config/configImages'

const bodyStyle = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 150,
    fontSize: 12,
    textAlign: "left",
    verticalAlign: "inherit"
}


const headerStyle = {
    width: 150,
    textAlign: "center",
}

// config file
class COTModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColumnOptionsData: [
                { field: 'businessNamesID', header: 'Business Names ID' },
                { field: 'businessName', header: 'Business Name' },
                { field: 'legacyConnectedName', header: 'legacy Connected Name' },
            ],
            isLoading: true,
            progress: 45,
            errorcolor: "",
            errorMessage: "",
            errorResponse: false
        };
        this.chainOfTitleHir = [];
    }

    componentDidMount = () => {

        let selectedName = this.props.selectedItem
        let body = {
            "DealId": selectedName.CompanyID.toString(),
            "relevanceType": "CHAIN OF TITLE",
            "DocumentType": "MAPPED NAME CHAIN OF TITLE",
            "Body": [
                {
                    "SubCategory": "SLR CONNCTN EVID",
                    "DocumentScope": [
                        "Deal",
                        "Mapped Name"
                    ],
                    "relevanceType": "CHAIN OF TITLE",
                    "DocumentType": "MAPPED NAME CHAIN OF TITLE,"
                }
            ],
            // "MappedNameID": "21248550"
        }
        const url = `getCOTNamesHierarchy?DealID=${selectedName.CompanyID}&BusinessNameID=${selectedName.BusinessNamesID}`;
        fetch("POST", url)
            .then(async (response) => {
                if (response && response.outResponse && response.outResponse.details) {
                    this.chainOfTitleHir = response.outResponse.details;
                    body.MappedNameID = this.chainOfTitleHir.map((elem) => elem.businessNamesID).join(',');
                    // this.setState({ cotData: response.outResponse.details })
                    console.log("body", body)
                    fetch("POST", url2, body)
                        .then(async (response) => {
                            if (response && response.outResponse && response.outResponse.details) {
                                const filteredResult = response.outResponse.details.filter((e) => e.sharePointDetails && e.sharePointDetails.length > 0);
                                this.formatChainOfTitleData(filteredResult);
                                // this.setState({ cotData: response.outResponse.details })
                            }
                        }).catch((err) => {
                            return err;
                        });

                } else if (response && response.errorMessage) {
                    this.setState({
                        isLoading:false,
                        errorcolor: "red",
                        errorMessage: response.errorMessage,
                        errorResponse: true
                    })
                }
            }).catch((err) => {
                return err;
            });


        let url2 = `getDocuments?SpData=true`
        // body.MappedNameID = "21248550"


    }

    formatChainOfTitleData = (fileData) => {
        let cotData = [];
        let disableCOTAdd = false;
        this.chainOfTitleHir.forEach((hir, i) => {
            const files = fileData.filter((elem) => (elem.mappedNameID.toString() === hir.businessNamesID.toString() && elem.sharePointDetails && elem.sharePointDetails.length > 0));
            if (files.length > 0) {
                const fileNamesArr = files.map((file) => file.sharePointDetails && file.sharePointDetails.length > 0 ? file.fileName : null);
                if (fileNamesArr.length === 0) {
                    disableCOTAdd = true;
                }
                files.forEach((file, j) => {
                    console.log(file)
                    cotData.push({
                        ...file,
                        ...hir,
                        fileName: fileNamesArr[j],
                        year: file.eventDateYear,
                        description: file.documentRelevanceNotes,
                        hideHir: (j > 0 ? true : false),
                        rowSpanCount: files.length,
                        colorCode: i,
                        docId: file.id,
                        docNumber: file.documentNumber
                    });
                    // console.log(cotData)


                })

            } else {
                disableCOTAdd = true;
                cotData.push({ ...hir, colorCode: i });
            }
        });


        this.setState({ chainOfTitleData: cotData, isLoading: false, cotHirCount: this.chainOfTitleHir.length, showChainOfTitle: true, disableCOTAdd: disableCOTAdd, disableLoadButton: true });


    }

    getBGColor = (num) => {
        let color = null
        switch (num) {
            case 1:
                color = '#bac0c2';
                break;
            case 2:
                color = '#a7c7d1';
                break;
            case 3:
                color = '#e6dbc8';
                break;
            case 4:
                color = '#edc9b9';
                break;
            case 5:
                color = '#e0f0c9';
                break;
            case 6:
                color = '#fcf7bb';
                break;
            case 7:
                color = '#ffeaea';
                break;
            case 8:
                color = '#decede';
                break;
            case 9:
                color = '#e7fff8';
                break;
            case 10:
                color = '#eef1f8';
                break;
            case 11:
                color = '#faf8e8';
                break;
            case 12:
                color = '#ffefda';
                break;


            default:
                color = null;
                break;
        }
        return color;
    }


    redirectToUpload = async (rowData, type) => {
        let url = `/sellersEdit/documentUpload/${rowData.dealId}?dealId=${rowData.dealId}&mappedNameId=${rowData.mappedNameID}&relSellerNameId=${rowData.businessNameID}&stateCode=${rowData.docState}&stateClaimId=${rowData.docStateClaimID}&OPPClaimID=${rowData.docOPPClaimID}&rowData=${encodeURIComponent(JSON.stringify(rowData))}`;
        if (type === 'edit') {
            url = `${url}&type=${type}`
        }
        window.open(url, '_blank', 'noopener=true');
    }

    redirectToPDFEditorbySelectedID = (rowData, index, document_id) => {
        let id;
        if ((index || index === 0) && rowData && rowData.docId && rowData.docId[index]) {
            id = rowData.docId[index];
        } else if (document_id) {
            id = document_id;
        }
        if (id) {//        
            fetch('GET', `getDocumentById?Id=${id}`).then(async (res) => {
                let data = res && res.respCode && res.outResponse && res.outResponse.details ? res.outResponse.details : null;
                if (data) {
                    localStorage.setItem('pdfDocDetails', JSON.stringify(data));
                    let url = `/pdfEditor?details=loadDoc`;
                    window.open(url, '_blank', 'noopener=true');
                }
            })
        }
    }


    redirectToPDFEditor = (col) => {
        return <div className="cursor-pointer text-info" onClick={() => this.redirectToPDFEditorbySelectedID(col, '', col._id)} data-toggle="tooltip" data-placement="left" title={col.fileName}>
            {col.isFileCommonForMultiSubCategories ? <span className="multiRepFile">{col.fileName}</span> : <span>{col.fileName}</span>}
        </div>
    }


    render() {
        const { chainOfTitleData } = this.state
        return (
            <div>
                <Modal isOpen={this.props.isOpenCOTModal} fade={false} className='modal-dialog-centered modal-dialog--primary m-auto redirection_prop' style={{ maxWidth: 2000 }}>
                    <ModalBody className='' style={{ height: 500, overflow: "auto" }}>
                        <Loader loader={this.state.isLoading} progress={this.state.progress} className="progress-loader" />
                        <div style={{ marginBottom: 40 }}><h4 style={{ fontWeight: "bold" }}> </h4>
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "#354f6e", right: 40 }}
                                onClick={() => this.props.closeCOTModal()} />
                        </div>
                        <p className={`${this.state.errorcolor === "red" ? "errorbgColor errorTextColor" : this.state.errorcolor === "green" ? "successbgColor successTextColor" : "bg-white"} topbar__center topBarImageAlignment px-3`} style={{ display: "block" }} >
                            {this.state.errorResponse ? this.state.errorMessage : ""}
                        </p>
                        <div className='d-flex flex-column'>
                            <table class="table table-bordered">
                                <thead style={{ backgroundColor: "lightgrey" }}>
                                    <tr>
                                        <th style={{ width: '5%' }}><b>{'Connection Year'}</b></th>
                                        <th style={{ width: '22%' }}><b>Name</b></th>
                                        <th style={{ width: '20%' }}><b>Connected Name</b></th>
                                        <th style={{ width: '20%' }}><b>Connection Type</b></th>
                                        <th style={{ width: '15%' }}><b>Name Type</b></th>
                                        <th style={{ width: '15%' }}><b>Name Code</b></th>
                                        <th style={{ width: '15%' }}><b>Legacy Name Code</b></th>
                                        <th style={{ width: '15%' }}><b>Phase</b></th>
                                        <th style={{ width: '10%' }}><b>Domicile</b></th>
                                        <th style={{ width: '22%' }}><b>CorpStatus At APA</b></th>
                                        <th style={{ width: '50%' }}><b>Evidence</b></th>
                                        <th style={{ width: '20%' }}><b>Actions</b></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {(chainOfTitleData && chainOfTitleData.length > 0) &&
                                        <>
                                            {chainOfTitleData.map((elem, i) => {
                                                return <tr>
                                                    <td >{elem.year}</td>
                                                    <td style={{ backgroundColor: `${this.getBGColor(elem.colorCode)}` }} >{elem.businessName}</td>
                                                    <td style={{ backgroundColor: `${this.state.cotHirCount === elem.colorCode + 1 ? null : this.getBGColor(elem.colorCode + 1)}` }}>{elem.legacyConnectedName}</td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td > </td>
                                                    <td >{elem.fileName ? this.redirectToPDFEditor(elem)
                                                        : 'No document available'}
                                                    </td>

                                                    <td >
                                                        <div className='d-flex'>
                                                            <img
                                                                src={configImages.editIcon}
                                                                alt=""
                                                                className="mt-0"
                                                                style={{ width: 22, height: 22 }}
                                                                onClick={(e) => this.redirectToUpload(elem, 'edit')}
                                                                data-toggle="tool-tip"
                                                                title={"Click to Add New Document"}
                                                            />
                                                            <img
                                                                src={configImages.addIcon}
                                                                alt=""
                                                                className="names_icon"
                                                                // style={(imgData.iconName === "deleteIcon" || imgData.iconName === "duplicateIcon") && notSelectedRec ? { opacity: 0.5 } : { opacity: 1 }}
                                                                onClick={(e) => this.redirectToUpload(elem)}
                                                                data-toggle="tool-tip"
                                                                title={"Click to Add New Document"}
                                                            />
                                                        </div> </td>
                                                </tr>
                                            })}
                                        </>
                                    }

                                </tbody>
                            </table>
                        </div>

                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default COTModal;
